<template>
  <div class="section-help-center" :class="{ 'grey-background': isGreyBackground }">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionHelpSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.applicationSectionHelpTitle"></div>
    </div>

    <div class="partner-card-container">
      <div class="partner-card">
        <div class="title">{{ getWordings.applicationSectionHelpFAQTitle }}</div>
        <div class="desc">
          {{ getWordings.applicationSectionHelpFAQDescription }}
        </div>
        <LinkButton
          class="button"
          :wordingKey="'applicationSectionHelpFAQButton'"
          :hrefLink="hrefLinkFAQ"
          :openNewTab="true"
          :isShowIcon="false"
        />
      </div>
      <div class="partner-card">
        <div class="title">{{ getWordings.applicationSectionHelpContactTitle }}</div>
        <div class="desc">
          {{ getWordings.applicationSectionHelpContactDescription }}
        </div>
        <LinkButton
          class="button"
          :wordingKey="'applicationSectionHelpContactButton'"
          :hrefLink="routes.contactUs.url"
          :isShowIcon="false"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionHelpCenter extends Vue {
  @Prop({ type: Boolean, default: false }) private isGreyBackground!: string;
  @Prop({ type: String, required: false }) private hrefLinkFAQ!: string;
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.grey-background {
  background-color: $gs8;
}
.section-help-center {
  @include layoutPadding;
  background: $gs8;
  padding-top: 100px;
  padding-bottom: 87px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .partner-card-container {
    @include flex(space-between, center);
    margin-top: 60px;
    margin-bottom: 60px;
    @include mq(l) {
      margin-top: 40px;
      margin-bottom: 0px;
      row-gap: 20px;
    }

    .partner-card {
      width: calc(50% - 10px);
      border: 1px solid $gs7;
      box-sizing: border-box;
      border-radius: $radius15;
      padding: 60px;
      background-color: $white;
      @include mq(l) {
        width: 100%;
        padding: 40px 20px 20px;
      }

      .title {
        @include titleFz(xs);
        @include fw(heavy);
        line-height: 30px;
        @include mq(m) {
          @include fz(m);
        }
      }
      .desc {
        @include fz(m);
        text-align: center;
        margin-top: 30px;
        margin-bottom: 28px;
        height: 48px;
        @include mq(m) {
          @include fz(s);
          height: auto;
          margin: 5px 0 20px;
        }
      }
      .button {
        @include flex(center, center);
      }
    }
  }
}
</style>
