import { Module } from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { TextState } from "./types";
import { RootState } from "../types";

export const state: TextState = {
  wordingsData: {}
};

const namespaced = true;

export const text: Module<TextState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
