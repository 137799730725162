<template>
  <div class="markdown" id="markdown">
    <div class="tag-wrapper">
      <span class="tag">{{ tag }}</span>
    </div>
    <div class="news-detail-title">{{ title }}</div>
    <div class="news-detail-date">{{ formattedDate }}</div>
    <img class="img-cover" :src="image" />
    <div v-html="this.markdownString"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import playVideoIcon from "@/assets/icons/play_video.svg";
import playVideoHoverIcon from "@/assets/icons/play_video_hover.svg";

@Component
export default class Markdown extends Vue {
  /// [resources]
  @Prop({ type: String, required: true, default: "" }) markdownString!: string;
  @Prop({ type: String, required: true, default: "" }) title!: string;
  @Prop({ type: String, required: true, default: "" }) formattedDate!: string;
  @Prop({ type: String, required: true, default: "" }) image!: string;
  @Prop({ type: String, required: true, default: "" }) tag!: string;
  playVideo = playVideoIcon;
  playVideoHover = playVideoHoverIcon;

  updated() {
    const markdownEl: any = document.getElementById("markdown");
    const listTagP: any = markdownEl.getElementsByTagName("p");
    Promise.all(
      [...listTagP].map((p: any) => {
        if (p.firstChild && p.firstChild.tagName === "IMG" && p.childElementCount > 1) {
          p.id = "caption";
          p.className = "img-description";
          const d = document.createElement("div");
          d.innerHTML = p.lastElementChild.innerHTML;
          p.lastElementChild.parentNode.replaceChild(d, p.lastElementChild);
          d.className = "img-description";
        }
      })
    );

    const listTagHr: any = markdownEl.getElementsByTagName("hr");
    Promise.all(
      [...listTagHr].map((hr: any) => {
        hr.previousElementSibling.className = "session";
      })
    );

    const listTagVideo: any = markdownEl.getElementsByTagName("video");
    Promise.all(
      [...listTagVideo].map((video: any) => {
        const isFirefox = typeof InstallTrigger !== "undefined";
        const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
        const img = document.createElement("img");
        img.src = playVideoIcon;
        const div = document.createElement("div");
        div.className = "videos";
        const parent = video.parentNode;
        parent.replaceChild(div, video);
        div.append(video);
        div.append(img);
        video.addEventListener("click", function() {
          if (isFirefox === true) {
            video.play();
            video.controls = true;
            img.style.display = "none";
          }
          if (screen.width < 1100) {
            img.style.display = "none";
            video.play();
            video.controls = true;
          }
          const isPaused = isChrome ? video.paused : !video.paused;
          if (isPaused) {
            img.style.visibility = "hidden";
          } else {
            img.style.visibility = "visible";
          }
        });
        video.onmouseover = function() {
          if (screen.width >= 1100) {
            if (!isFirefox) {
              video.controls = true;
            }
            img.src = playVideoHoverIcon;
          }
        };
        video.onmouseleave = function() {
          if (screen.width >= 1100) {
            if (!isFirefox) {
              video.controls = false;
            }
            img.src = playVideoIcon;
          }
        };
      })
    );
  }
}
</script>

<style lang="scss">
.tag-wrapper {
  @include size(fit-content, 30px);
  @include fz(s);
  @extend %centerContent;
  @include fw(medium);
  border: 1px solid $gs2;
  border-radius: 13px;
  position: relative;
  padding: 0 20px;
}

.news-detail-title {
  @include titleFz(m);
  @include fw(heavy);
  margin-top: 5px;
  text-align: left;
}

.news-detail-date {
  @include fz(s);
  @include fw(medium);
  color: $gs3;
  margin-top: 10px;
  text-align: left;
  margin-bottom: 50px;

  @include mq(m) {
    margin-top: 0;
    margin-bottom: 30px;
  }
}
.markdown {
  text-align: left;

  // HEADER
  h1 {
    font-size: 45px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 25px;
  }
  h5 {
    font-size: 18px;
  }
  h6 {
    font-size: 14px;
  }
  .img-description {
    text-align: center;
    font-size: 12px;
    margin-top: 20px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  hr {
    color: $gs2;
  }

  .session {
    margin-bottom: 10px;
  }

  .videos {
    position: relative;
    video {
      width: 80%;
      margin-left: 10%;
      margin-right: 10%;
      border-radius: 5px;
    }
    img {
      position: absolute;
      pointer-events: none;
      border-radius: 0px;
      top: calc(50% - 40px);
      left: calc(50% - 40px);
      display: block;
      margin-left: 0;
      margin-right: 0;
      @include mq(l) {
        object-fit: cover;
        width: auto;
      }
      // @include mq(m) {
      //   top: 25%;
      //   left: 37%;
      // }
    }
    video:hover {
      cursor: pointer;
    }
  }

  #caption {
    :nth-child(2) {
      line-height: 30px;
      font-size: 12px;
      max-width: 80%;
      text-align: center;
      margin: auto;
      margin-top: 20px;
      @include mq(m) {
        margin-top: 5px;
        font-size: 10px;
      }
    }
  }

  iframe {
    margin-left: 10%;
    margin-right: 10%;
    width: 80%;
    height: 400px;
    border-radius: 5px;
    @include mq(m) {
      height: 200px;
    }
  }

  //ORDERED LIST
  ol {
    list-style: none;
    counter-reset: item;
    li {
      counter-increment: item;
      display: flex;
      ul {
        padding-inline-start: 24px;
      }
    }
    li:before {
      font-size: 14px;
      font-weight: bold;
      margin-right: 10px;
      content: counter(item) ".";
      display: inline-block;
      color: $primary1;
    }
  }

  ul {
    list-style: none;
    li {
      display: flex;
    }
    li:before {
      content: " ";
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      margin: 8px 10px 2px 0;
      border-radius: 50%;
      display: inline-block;
      background-color: $primary1;
    }
  }

  // LINK
  a {
    width: 100%;
    word-break: break-all;
    color: $primary1;
    text-decoration: none;
    border-bottom: 2px solid $secondary2;
  }

  // IMAGE
  img {
    border-radius: 5px;
    max-width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @include mq(l) {
      object-fit: cover;
      width: 100%;
    }
  }

  // CODE
  code {
    padding: 5px 15px 5px;
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  // TABLE
  table {
    border: 1.5px solid $gs2;
    border-radius: 5px;
    border-spacing: 0;
    th {
      font-size: 14px;
      padding: 15px 20px;
      border-bottom: 4px solid $primary1;
    }
    td {
      padding: 15px 10px 15px;
      font-size: 12px;
    }
  }

  // QUOTE
  blockquote {
    border-left: 5px solid $gs2;
    margin: 30px 0 0 30px;
    padding: 20px 50px 50px;
    p {
      margin: 0;
    }
  }

  @include mq(m) {
    h1 {
      font-size: 30px;
    }
    h2 {
      font-size: 25px;
    }
    h3 {
      font-size: 20px;
    }
    h4 {
      font-size: 16px;
    }
    h5 {
      font-size: 14px;
    }
    h6 {
      font-size: 12px;
    }

    p {
      font-size: 12px;
    }

    .img-description {
      text-align: center;
      font-size: 10px;
      margin-top: 5px;
    }

    table {
      th {
        font-size: 12px;
      }
    }

    li {
      font-size: 12px;
    }

    blockquote {
      margin: 13px 0 0 13px;
      padding: 10px 30px 10px;
    }
  }
}
</style>
