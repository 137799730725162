<template>
  <div class="section-xid-can">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.devSectionCanDoSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.devSectionCanDoTitle }}</div>
    </div>
    <div class="slogan">
      <div class="text" v-html="getWordings.devSectionCanDoMessage"></div>
    </div>
    <div class="mission_content" v-html="getWordings.devSectionCanDoDesctiption"></div>
    <div class="xid-can-list">
      <XidCanDoCardView
        v-for="idx in 5"
        :key="idx"
        :title="getWordings[`devSectionCanDoF${idx}Title`]"
        :description="getWordings[`devSectionCanDoF${idx}Description`]"
      />
      <div>
        <img class="xid-business" :src="require('@/assets/images/business_cando.svg')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import XidCanDoCardView from "@/components/parts/XidCanDoCardView.vue";

@Component({
  components: {
    XidCanDoCardView
  },
  inject: ["routes"]
})
export default class SectionXidCanDo extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-xid-can {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  .check-content {
    display: flex;
    font-weight: bold;
    @include mq(l) {
      width: 100%;
    }
    .content {
      display: flex;
      float: left;
      text-align: left;
      @include fz(l);
      @include mq(l) {
        @include fz(s);
        width: 100%;
      }
      line-height: 30px;
    }
    .check-mark {
      margin-right: 10px;
    }
  }

  .xid-can-list {
    @include flex(space-between);
    width: 100%;
    background-color: $gs1;
    border: 1px solid $gs7;
    border-radius: 15px;
    padding: 60px;
    row-gap: 40px;
    margin-top: 60px;
    @include mq(l) {
      padding: 40px 20px 40px;
      margin-top: 40px;
      width: -webkit-fill-available;
    }
    @include mq(m) {
      @include flex(center);
    }
    .xid-business {
      width: 327px;
      @include mq(m) {
        width: 100%;
      }
    }
  }
  .link-button {
    margin-top: 60px;
  }

  .mission_title,
  .mission_content {
    color: $gs4;
  }

  .mission_content {
    text-align: justify;
    max-width: 850px;
  }

  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;

    .mission_content {
      text-align: left;
    }

    .separator-line {
      @include size(0px, 3px);
      margin-top: 10px;

      &.animating {
        width: 100px;
      }
    }
  }
}
</style>
