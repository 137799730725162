<template>
  <div class="about">
    <BannersView id="about" :timestamp="timestamp" />
    <SectionMission />
    <SectionOurValues />
    <SectionVision id="" />
    <SectionTeamMember id="team" />
    <SectionMedia />
    <SectionAssociate id="associate" />
    <SectionCompanyProfile id="company" />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Watch, Prop } from "vue-property-decorator";
import BannersView from "@/components/parts/AboutBannersView.vue";
import SectionMission from "@/components/parts/SectionMission.vue";
import SectionOurValues from "@/components/parts/SectionOurValues.vue";
import SectionVision from "@/components/parts/SectionVision.vue";
import SectionTeamMember from "@/components/parts/SectionTeamMember.vue";
import SectionAssociate from "@/components/parts/SectionAssociate.vue";
import SectionCompanyProfile from "@/components/parts/SectionCompanyProfile.vue";
import SectionMedia from "@/components/parts/SectionMedia.vue";
import routes from "@/router/routes";
import { Action, Getter } from "vuex-class";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

@Component({
  components: {
    BannersView,
    SectionMission,
    SectionOurValues,
    SectionVision,
    SectionTeamMember,
    SectionAssociate,
    SectionCompanyProfile,
    SectionMedia
  },
  metaInfo: routes.about.meta
})
export default class About extends Vue {
  @Prop({ type: String }) private timestamp!: string;
  @Action("scroll/setAboutScrollData") setAboutScrollData!: Function;
  @Getter("scroll/getAboutScroll") getAboutScroll: any;

  mounted() {
    if (this.getAboutScroll) {
      VueScrollTo.scrollTo("#" + this.getAboutScroll);
      this.setAboutScrollData("");
    }
  }
  @Watch("getAboutScroll")
  autoScroll() {
    if (this.getAboutScroll) {
      VueScrollTo.scrollTo("#" + this.getAboutScroll);
    }
    this.setAboutScrollData("");
  }
}
</script>
