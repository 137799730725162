<template>
  <div class="media-card">
    <div class="image-wrapper">
      <img class="media-card-img" :alt="title" :src="image" />
    </div>
    <div class="content-card">
      <div class="content">
        <div>
          <div class="card-view-description" v-html="formattedDescription"></div>
          <div class="card-view-title">{{ title }}</div>
          <LinkButton
            class="link-button link"
            :wordingKey="'homeSectionMediaPostButton'"
            :isLinkButton="true"
            :linkUnderline="false"
            :isShowIcon="true"
            :hrefLink="url"
            :openNewTab="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import Tag from "@/components/controls/Tag.vue";
import { dateReplaceHyphen } from "@/lib/helpers/datetime";

@Component({
  name: "MediaCardView",
  components: {
    LinkButton,
    Tag
  },
  inject: ["routes"]
})
export default class MediaCardView extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Array }) tagArr!: Array<string>;
  @Prop({ type: String }) image!: string;
  @Prop({ type: String }) date!: string;
  @Prop({ type: String }) url!: string;

  get formattedDescription(): string {
    return dateReplaceHyphen(this.date);
  }
}
</script>

<style lang="scss" scoped>
.media-card {
  @include flex(flex-start, center);
  border: 1px solid $gs7;
  box-sizing: border-box;
  border-radius: $radius15;
  background-color: $white;
  height: 150px;
  width: 850px;
  margin-bottom: 30px;
  @include mq(xl) {
    width: 100%;
  }
  @include mq(m) {
    // @include flex(center, center);
    border: 1px solid $gs7;
    margin-bottom: 20px;
    border-radius: 10px;
    height: 285px;
    width: 100%;
  }

  .image-wrapper {
    @include size(300px, 147.9px);
    @include mq(m) {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .media-card-img {
    @include size(100%);
    object-fit: cover;
    border-top-left-radius: $radius15;
    border-bottom-left-radius: $radius15;
    @include mq(m) {
      margin: auto;
      border-radius: unset;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .content-card {
    box-sizing: border-box;
    @include mq(l) {
      max-width: calc(100% - 300px);
    }
    @include mq(m) {
      max-width: calc(100% - 40px);
    }
  }

  .content {
    padding: 0px 50px;
    height: 100%;
    text-align: left;
    display: grid;
    align-content: space-between;
    @include mq(l) {
      padding: 20px 20px;
    }
    @include mq(m) {
      width: 100%;
    }
  }

  .card-view-title {
    @include fz(l);
    @include fw(medium);
    height: 56px;
    margin: 5px 0 13px;
    max-width: 440px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    max-lines: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    @include mq(l) {
      @include fz(m);
    }
    @include mq(m) {
      height: 41px;
      max-width: 100%;
      width: 100%;
    }
  }

  .card-view-description {
    @include fz(xs);
    @include fw(normal);
    line-height: 15px;
    max-lines: 1;
    // color: $gs3;
  }

  .link-button {
    height: 20px;
    ::v-deep .link {
      height: 20px;
    }
    @include mq(l) {
      width: fit-content;
      height: 17px;
    }
  }
}
</style>
