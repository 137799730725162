<template>
  <div class="section-resource">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.governmentSectionResourceSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.governmentSectionResourceTitle"></div>
    </div>

    <div class="xid-description">
      {{ getWordings.governmentSectionResourceDescription }}
    </div>

    <div
      class="resource-card"
      v-for="(resource, idx) in resources"
      :key="idx"
      :class="{
        'last-child': idx == resources.length - 1
      }"
    >
      <div class="title" @click="openResourceCard(idx)">
        {{ getWordings[resourcesTitle[idx]] }}
        <span class="icon">
          <img
            :class="{ 'expand-icon': resourceOpenFlag[idx] }"
            :src="require('@/assets/icons/icon-pointer.svg')"
          />
        </span>
      </div>
      <transition name="fade">
        <div class="desc" v-if="resourceOpenFlag[idx]">
          <div class="check-content" v-for="(item, i) in resource" :key="i">
            <a class="content" :href="item.link" target="_blank">
              {{ item.title }}
            </a>
            <a class="arrow" :href="item.link" target="_blank">
              <img :src="require('@/assets/icons/nav-right-colored.svg')" />
            </a>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Getter } from "vuex-class";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {}
})
export default class SectionResource extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  resources: any = {};
  resourcesTitle: any = {};
  resourceOpenFlag: any = { case: false, report: false, docs: false };

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getResources(contentRepository, "case");
    this.getResources(contentRepository, "report");
    this.getResources(contentRepository, "docs");
  }

  async getResources(contentRepository: any, type: string) {
    if (!this.resources[type]) {
      this.resources[type] = [];
    }
    if (!this.resourcesTitle[type]) {
      this.resourcesTitle[type] =
        "governmentSectionResourceCategory" + type.charAt(0).toUpperCase() + type.slice(1);
    }
    this.resources[type] = (await contentRepository.getResourcesListByType(type)).map(
      (item: any): any => {
        return {
          title: item.attributes.title,
          link: item.attributes.link
        };
      }
    );
  }

  openResourceCard(index: any) {
    this.resourceOpenFlag[index] = !this.resourceOpenFlag[index];
  }
}
</script>

<style lang="scss" scoped>
.section-resource {
  @include layoutPadding;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .xid-description {
    margin: auto;
    max-width: 800px;
    margin-bottom: 60px;
    margin-top: 60px;
    @include mq(l) {
      margin-bottom: 0px;
      margin-top: 40px;
    }
  }

  .resource-card {
    margin-bottom: 60px;
    text-align: left;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    @include mq(l) {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    .title {
      @include flex(space-between, center);
      @include titleFz(xs);
      @include fw(heavy);
      line-height: 29px;
      padding-bottom: 10px;
      @include mq(l) {
        @include fz(l);
        @include fw(bold);
        line-height: 23px;
        padding-bottom: 5px;
      }
      cursor: pointer;
      border-bottom: 2px solid #000000;
    }

    .icon {
      @include flex(center);
      @include size(17px);
      > img {
        transition: transform 0.2s ease-out;
      }
    }

    .expand-icon {
      transform: rotate(90deg);
    }

    .fade-enter-active {
      transition: opacity 0.3s;
    }
    .fade-enter,
    .fade-leave-to {
      opacity: 0;
    }

    .desc {
      @include fz(m);
      width: 100%;
      @include mq(l) {
        margin-top: 0px;
        max-width: unset;
      }
    }
    .check-content {
      @include flex(space-between, center);
      box-sizing: border-box;
      width: 100%;
      padding: 0 20px;
      border-bottom: 1px solid $gs7;
      @include mq(l) {
        padding: 0 10px;
      }
      .content {
        @include flex(flex-start, center);
        @include fz(l);
        color: $black;
        text-align: left;
        height: 60px;
        width: calc(100% - 60px);
        @include mq(l) {
          @include fz(s);
          width: calc(100% - 46px);
        }
      }
    }
  }

  .last-child {
    margin-bottom: 0;
  }
}
</style>
