<template>
  <div
    class="section-clients-voice"
    :class="{ 'section-clients-voice-gray-background': isGrayBackground }"
  >
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.governmentSectionVoiceSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.governmentSectionVoiceTitle"></div>
    </div>
    <Splide class="voice-group" :options="options" v-if="feedbacks.length > 0">
      <SplideSlide v-for="(item, idx) in feedbacks" :key="idx">
        <VoiceCardView
          class="voice-item"
          :isWhiteCard="isGrayBackground"
          :img="item.image"
          :text="item.comment"
          :signature1="item.signature1"
          :signature2="item.signature2"
        />
      </SplideSlide>
    </Splide>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import VoiceCardView from "@/components/parts/VoiceCardView.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Getter } from "vuex-class";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    LinkButton,
    VoiceCardView,
    Splide,
    SplideSlide
  },
  inject: ["routes"]
})
export default class SectionClientVoice extends Vue {
  @Prop({ type: Boolean, default: false }) private isGrayBackground!: boolean;
  @Getter("text/getWordings") getWordings!: any;
  feedbacks = [];
  options = {
    type: "loop",
    perPage: 1,
    perMove: 1,
    rewind: true,
    gap: "1rem",
    autoplay: true,
    interval: 5000,
    pauseOnHover: false,
    arrows: false
  };

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getFeedbacks(contentRepository);
  }

  async getFeedbacks(contentRepository: any) {
    this.feedbacks = [];
    this.feedbacks = (await contentRepository.getFeedbacksListByType("government")).map(
      (item: any): any => {
        const cover = item.attributes.cover.data.attributes;
        return {
          signature1: item.attributes.organization_name,
          signature2: item.attributes.asignee_name,
          comment: item.attributes.comment,
          image: cover.url
        };
      }
    );
  }
}
</script>

<style lang="scss" scoped>
.section-clients-voice {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 170px;
  background: $white;

  .voice-group {
    width: 100%;
    margin-top: 60px;
    @include mq(l) {
      height: auto;
    }
    @include mq(m) {
      margin-top: 50px;
    }

    .voice-item {
      min-height: 350px;
      width: 100%;
      margin-left: 10px;
      @include mq(l) {
        height: auto;
      }

      @include mq(m) {
        // margin: 0;
        margin-top: 10px;
        margin-left: 0px;
      }
    }
  }

  @include mq(m) {
    @include fz(xl);
    padding-top: 40px;
    padding-bottom: 78px;
    // padding: 50px 0;
  }
}

::v-deep .voice-group {
  .splide__pagination {
    bottom: -60px !important;
    @include mq(l) {
      bottom: -33px !important;
    }
  }

  .splide__slide {
    @include flex(center, flex-start);
  }

  .splide__pagination__page {
    margin: 0px 10px !important;
    @include size(10px !important, 10px !important);
  }

  .splide__pagination__page.is-active {
    background: $primary1 !important;
    transform: scale(1) !important;
  }
}

.section-clients-voice-gray-background {
  background-color: $gs8;
}
</style>
