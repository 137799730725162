<template>
  <div class="section-why-smartpost">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.smartpostSectionWhySubtitle }}
      </div>
      <div class="section-header-text">
        {{ getWordings.smartpostSectionWhyTitle }}
      </div>
    </div>
    <div class="section-body">
      <img :src="require('@/assets/images/why_smartpost_img.svg')" />
      <div class="right-side">
        <div class="why-plain-title">
          {{ getWordings.smartpostSectionWhyPainTitle }}
        </div>
        <div
          class="why-plain-description"
          v-html="getWordings.smartpostSectionWhyPainDescription"
        ></div>
        <div class="why-solution-title">
          {{ getWordings.smartpostSectionWhySolutionTitle }}
        </div>
        <div
          class="why-solution-description"
          v-html="getWordings.smartpostSectionWhySolutionDescription"
        ></div>
        <div class="why-plain-title">
          {{ getWordings.smartpostSectionWhySolutionSubTitle }}
        </div>
        <div class="check-content" v-for="idx in 5" :key="idx">
          <span class="content">
            <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
            {{ getWordings[`smartpostSectionWhySolutionF${idx}`] }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionWhytSmartPOST extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-why-smartpost {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .section-body {
    @include flex(space-between, flex-start);
    width: 100%;
    margin-top: 60px;
    @include mq(l) {
      @include flex(center, flex-start);
      margin-top: 40px;
      > img {
        width: 100%;
      }
    }
    .right-side {
      width: 50.8333%;
      max-width: 610px;
      text-align: left;
      @include mq(l) {
        width: 100%;
        max-width: unset;
        margin-top: 40px;
      }

      .why-plain-title {
        @include titleFz(xs);
        @include fw(bold);
        margin-bottom: 10px;
        @include mq(l) {
          @include fz(m);
          margin-bottom: 20px;
        }
      }
      .why-plain-description {
        @include fz(l);
        @include fw(normal);
        @include mq(l) {
          @include fz(m);
        }
      }
      .why-solution-title {
        @include titleFz(xs);
        @include fw(bold);
        color: $primary1;
        margin: 40px 0 10px;
        @include mq(l) {
          @include fz(m);
          margin: 40px 0 20px;
        }
      }
      .why-solution-description {
        @include fz(l);
        @include fw(normal);
        margin-bottom: 40px;
        @include mq(l) {
          @include fz(m);
        }
      }
    }
  }

  .check-content {
    display: flex;
    margin-top: 20px;
    @include mq(l) {
      margin-top: 10px;
    }
    font-weight: bold;
    .content {
      display: flex;
      float: left;
      text-align: left;
      @include fz(l);
      @include mq(l) {
        @include fz(s);
        width: 100%;
      }
      line-height: 30px;
    }
    .check-mark {
      margin-right: 10px;
    }
  }

  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
