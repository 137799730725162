<template>
  <div>
    <div class="footer-top">
      <div class="title">{{ $t("footer_top_title") }}</div>
      <img class="xid-logo" :src="require('@/assets/logos/xid-logo-white.svg')" alt="xid-logo" />
      <div class="menu-footer">
        <div class="column">
          <div class="column-value company-info" v-html="companyName"></div>
          <div class="column-value company-info addrees" v-html="companyAddress"></div>
        </div>
        <div class="column">
          <div @click="onClick(routes.application.url, 'app')" class="column-value">
            {{ $t("footer_app") }}
          </div>
          <div @click="onClick(routes.dev.url, 'dev')" class="column-value">
            {{ $t("footer_api") }}
          </div>
          <div @click="onClick(routes.smartpost.url, 'smartpost')" class="column-value">
            {{ $t("footer_smartpost") }}
          </div>
        </div>
        <div class="column">
          <div @click="onClick(routes.gov.url, 'gov')" class="column-value">
            {{ $t("footer_gov") }}
          </div>
          <div @click="onClick(routes.partner.url, 'partnership')" class="column-value">
            {{ $t("footer_partner") }}
          </div>
          <div @click="onClick(routes.about.url, 'about')" class="column-value">
            {{ $t("footer_about") }}
          </div>
          <div @click="onClick(routes.news.url, 'news')" class="column-value">
            {{ $t("footer_news") }}
          </div>
        </div>
        <div class="column">
          <div class="column-value" @click="toLink('https://public.xid.inc/documents/privacy.pdf')">
            {{ $t("footer_recruit_column_value_1") }}
          </div>
          <div @click="toLink('https://recruit.xid.inc/')" class="column-value">
            {{ $t("footer_recruit_column_value_2") }}
          </div>
          <div @click="onClick(routes.contactUs.url, 'contact-us')" class="column-value">
            {{ $t("footer_recruit_column_value_3") }}
          </div>
        </div>
        <div class="column">
          <img
            class="logo-wrapper"
            @click="toLink('https://www.facebook.com/xID.jp')"
            :src="require('@/assets/icons/facebook.png')"
          />
          <img
            class="logo-wrapper"
            @click="toLink('https://twitter.com/xID_inc')"
            :src="require('@/assets/icons/x.svg')"
          />
        </div>
      </div>
    </div>
    <div class="footer page-footer">
      <div class="copyright">
        <span>{{ $t("footer_copyright_1") }}</span>
        <span class="highlight">{{ $t("footer_copyright_2") }}</span>
        <span>{{ $t("footer_copyright_3") }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  inject: ["routes"]
})
export default class PageFooter extends Vue {
  @Action("scroll/setAboutScrollData") setAboutScrollData!: Function;
  onClick(link: string, scrollTo: string) {
    this.$router.push(link);
    this.setAboutScrollData(scrollTo);
  }
  toLink(link: string) {
    window.open(link, "_blank");
  }

  companyName = "";
  companyAddress = "";

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getCompanyProfiles(contentRepository);
  }

  getCompanyProfiles(contentRepository: any): Promise<void> {
    return contentRepository.getCompanyProfilesList().then((companyProfiles: any) => {
      companyProfiles.map((item: any): any => {
        if (item.id == 1) {
          this.companyName = item.attributes.value.replace(/\n/g, "</br>");
        }
        if (item.id == 4) {
          this.companyAddress = item.attributes.value.replace(/\n/g, "</br>");
        }
      });
    });
  }
}
</script>

<style lang="scss" scoped>
.page-footer {
  @include size(100%, 80px);
  @include flex(center, center);
  @include layoutPadding();
  border-top: 1px solid $gs2;

  .copyright {
    color: $black;
    letter-spacing: 0.15em;

    .highlight {
      color: $primary1;
    }
  }

  @include mq(m) {
    @include flex(space-between, center);
    @include fz(xxs);
    height: 40px;
  }
}

.footer-top {
  letter-spacing: 0.15em;
  background-color: black;
  @include layoutPadding();
  padding-top: 60px;
  padding-bottom: 60px;
  color: $white;

  .xid-logo {
    margin: auto;
    margin-top: 10px;
    @include mq(m) {
      // max-height: 20px;
    }
  }

  .menu-footer {
    @include flex(space-between, flex-start);
    @include fz(m);
    @include fw(bold);
    padding-top: 60px;
    margin-top: 60px;
    border-top: 1px solid #666666;
    text-align: left;

    .column-value {
      height: 20px;
      line-height: 20px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .company-info {
      cursor: unset;
    }

    .addrees {
      height: 40px;
    }

    @include mq(m) {
      margin-top: 40px;
      padding-top: 40px;

      .column {
        margin-bottom: 40px;
        min-width: 148px;
      }
      .column-value {
        @include fz(s);
      }
    }
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 0px;
  }
}

.logo-wrapper {
  width: 30px;
  height: 30px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>
