<template>
  <ValidationProvider
    class="validate-textfield"
    v-slot="{ errors }"
    :rules="required ? 'required' : ''"
  >
    <div class="dropdown">
      <div
        class="dropdown-selected-item"
        :class="{ error: errors.length > 0 }"
        @click="showSelection = !showSelection"
      >
        <span class="text" :class="{ placeholder: !selectedItem }">
          {{ selectedItem || placeholder }}
        </span>
        <span class="icon">
          <img :src="downIcon" />
        </span>
      </div>
      <div class="dropdown-selection-area" v-show="showSelection">
        <div
          class="dropdown-item"
          :class="{ selected: item === selectedItem }"
          v-for="(item, idx) in items"
          :key="idx"
          @click.stop="selectItem(item)"
        >
          {{ item }}
        </div>
      </div>

      <input type="text" :value="selectedItem" v-show="false" />
      <div class="error-area" v-if="errors.length > 0 && !showSelection">
        <slot :errorString="errors ? getMessage(errors[0]) : ''"></slot>
      </div>
    </div>
  </ValidationProvider>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import downIcon from "@/assets/icons/down-gray.svg";

@Component({
  name: "Dropdown",
  components: {
    ValidationProvider
  }
})
/**
 * @vuese
 * @group Atomic/Organisms
 * dropdown
 */
export default class Dropdown extends Vue {
  // [Prop]
  @Prop({ type: Array, default: [], required: true }) readonly items!: string[];
  @Prop({ type: String, default: "" }) readonly placeholder!: string[];
  @Prop() message!: string | Record<string, string>;
  @Prop({ type: Boolean, default: false }) required!: boolean;
  @Model("selectedChanged", { type: String, default: "" }) selectedItem!: string;

  // [Data]
  downIcon: string = downIcon;
  showSelection = false;

  // [Life cycle]
  mounted() {
    const outsideClickEvent = (e: Event) => {
      if (!e.target) return;
      if (!this.$el.contains(e.target as Node) && this.showSelection) {
        this.showSelection = false;
      }
    };

    document.addEventListener("click", outsideClickEvent);
  }

  // [Methods]
  selectItem(item: string) {
    this.showSelection = false;
    this.$emit("selectedChanged", item);
  }

  getMessage(errorName: string) {
    if (typeof this.message === "string") {
      return this.message;
    }
    if (typeof this.message === "object" && this.message[errorName]) {
      return (this.message as Record<string, string>)[errorName];
    }
    return errorName;
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  cursor: pointer;

  .dropdown-selected-item {
    @include size(100%, 100%);
    background-color: $white;
    padding: 0px 0px 5px;
    border-bottom: 1px solid $gs2;
    box-sizing: border-box;

    @include flex(space-between, center);

    &.error {
      border-color: $secondary3;
    }

    .text {
      flex: 1 1 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;

      &.placeholder {
        color: rgba($color: $gs4, $alpha: 0.5);
      }
    }
  }

  .dropdown-selection-area {
    width: 100%;
    top: 100%;
    position: absolute;
    overflow: hidden;
    background-color: $white;
    border: {
      left: 1px solid $gs2;
      right: 1px solid $gs2;
      bottom: 1px solid $gs2;
    }
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
    z-index: 10;

    .dropdown-item {
      @include mq(m) {
        height: 40px;
      }
    }
  }

  .dropdown-item {
    @include flex(flex-start, center);
    @include size(100%, 60px);
    padding: 0px 20px;
    color: $primary1;
    cursor: pointer;

    &:hover,
    &.selected {
      background-color: $primary1;
      color: $white;
    }
  }

  .error-area {
    @include size(100%, 100%);
    top: calc(100% + 5px);
    position: absolute;
    z-index: 8;
    max-height: calc(min(100%, 50px));
    opacity: 1;
    overflow: hidden;
  }
}
</style>
