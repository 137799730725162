<template>
  <div>
    <transition name="fade">
      <div class="banner-container" v-if="!isHideBanner">
        <div class="banner">
          <span>{{ getWordings.newsDownloadPDFBanner }}</span>
          <ActionButton
            class="btn-pdf-banner"
            :wordingKey="'newsDownloadPDFButton'"
            :isLinkDownload="true"
            @onClick="downloadPDF"
          />
        </div>
      </div>
    </transition>
    <div class="news-detail">
      <div class="back-button" v-on:click="toBack">
        <LinkButton
          :isLinkButton="true"
          :isNavLeft="true"
          :isShowIcon="true"
          :wordingKey="'newsBackToListButton'"
        />
      </div>
      <div id="detail-content">
        <Markdown
          :markdownString="content"
          :title="title"
          :image="image"
          :formattedDate="formattedDate"
          :tag="tagNames[tags]"
        />
      </div>
      <div class="button-wrapper" v-scroll="hideBanner">
        <ActionButton
          class="btn-pdf"
          :wordingKey="'newsDownloadPDFButton'"
          :isLinkDownload="true"
          @onClick="downloadPDF"
        />
      </div>
      <SectionNewsFooter :prevPost="prevPost" :nextPost="nextPost" />
    </div>
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import SectionNewsFooter from "@/components/parts/SectionNewsFooter.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import Markdown from "@/components/controls/Markdown.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
import showdown from "showdown";
import html2pdf from "html2pdf.js";
import Tag from "@/components/controls/Tag.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import { dateReplaceHyphen } from "@/lib/helpers/datetime";
import { Getter } from "vuex-class";

/* eslint-disabled */
@Component({
  components: {
    SectionNewsFooter,
    Markdown,
    ActionButton,
    Tag,
    LinkButton
  },
  inject: ["routes"]
})
/* eslint-disabled */
export default class NewsDetail extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  @Prop({ type: String, required: true }) id!: string;
  @Prop({ type: Boolean, required: false, default: false }) previousPage!: boolean;
  isHideBanner = false;

  @Watch("id")
  idChanged() {
    this.getPost();
    this.getPrevNextPost();
  }

  currentPost: any[] = [];
  content = "";
  converter = new showdown.Converter({
    tables: true,
    strikethrough: true,
    underline: true,
    openLinksInNewWindow: true,
    splitAdjacentBlockquotes: true,
    simpleLineBreaks: true
  });
  title = "";
  date = "";
  tags = "";
  image = "";
  description = "";
  keywords = "";

  prevPost: any = "";
  nextPost: any = "";
  tagNames: any = {
    news: "ニュース",
    "press-release": "プレスリリース",
    media: "メディア掲載",
    event: "イベント情報",
    information: "インフォメーション（その他の情報）"
  };

  get formattedDate() {
    return dateReplaceHyphen(this.date);
  }

  created() {
    this.getPost();
    this.getPrevNextPost();
  }

  updated() {
    this.setDataImg();
  }

  toBack() {
    if (!this.previousPage) {
      this.$router.push("/news");
    } else {
      window.history.back();
    }
  }

  async getPost(): Promise<void> {
    const contentRepository = RepositoryFactory.create("contents");
    this.currentPost = await contentRepository.getPostsByKey(this.id);
    const coverImage = this.currentPost[0].attributes.cover.data
      ? this.currentPost[0].attributes.cover.data.attributes.url
      : "https://public.xid.inc/assets/news_default_705af5bb55.svg";
    this.content = this.converter.makeHtml(this.currentPost[0].attributes.body);
    this.title = this.currentPost[0].attributes.title;
    this.date = this.currentPost[0].attributes.date;
    this.image = coverImage;
    this.tags = this.currentPost[0].attributes.tags;
  }

  async getPrevNextPost(): Promise<void> {
    let prevId = parseInt(this.id);
    let nextId = parseInt(this.id);
    let previous: any = [];
    let next: any = [];
    const contentRepository = RepositoryFactory.create("contents");
    do {
      prevId--;
      previous = await contentRepository.getPostsByKey(prevId);
    } while (previous.length < 1 && prevId > parseInt(this.id) - 10);
    do {
      nextId++;
      next = await contentRepository.getPostsByKey(nextId);
    } while (next.length < 1 && nextId < parseInt(this.id) + 10);
    if (previous.length > 0) {
      this.prevPost = previous[0];
    } else {
      this.prevPost = "";
    }
    if (next.length > 0) {
      this.nextPost = next[0];
    } else {
      this.nextPost = "";
    }
  }

  hideBanner(event: Event, el: any) {
    const rect = el.getBoundingClientRect();
    const inView =
      rect.width > 0 &&
      rect.height > 0 &&
      rect.top >= 0 &&
      rect.bottom - 100 <= (window.innerHeight || document.documentElement.clientHeight);
    if (inView) {
      this.isHideBanner = true;
    } else {
      this.isHideBanner = false;
    }
  }

  /**
   * Click download PDF
   */
  async downloadPDF(onDone: Function) {
    let el: any = document.getElementById("detail-content");
    el = el.innerHTML;
    el = await this.customHTML(el);
    const opt = {
      margin: 0.6,
      filename: this.title,
      image: { type: "jpeg", quality: 0.95 },
      html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css"], avoid: "img" }
    };

    html2pdf()
      .set(opt)
      .from(el)
      .toContainer()
      .save();
    onDone();
  }

  /**
   * Custom html: font size, text, border
   */
  async customHTML(el: string): Promise<string> {
    el = await this.changeVideo(el);
    return new Promise<string>(resolve => {
      el = "<div style='font-size: 12px; padding-bottom: 60px;'> " + el + " </div>";
      el = el.split("<a").join("<a style='text-decoration: underline; border: 0;'");
      el = el.split("<h1").join("<h1 style='font-size: 30px;'");
      el = el.split("<h2").join("<h2 style='font-size: 28px;'");
      el = el.split("<h3").join("<h3 style='font-size: 22px;'");
      el = el.split("<h4").join("<h3 style='font-size: 18px;'");
      el = el.split("<h5").join("<h3 style='font-size: 13px;'");
      el = el.split('class="tag"').join('class="tag" style="position: absolute; bottom: 15px;"');
      resolve(el);
    });
  }

  /**
   * Change string html of video to url <a>
   */
  changeVideo(el: any) {
    return new Promise<string>(resolve => {
      let videoYoutube: string =
        el.indexOf("<iframe") > 0
          ? el.substring(el.indexOf("<iframe"), el.indexOf("</iframe>") + 9)
          : "";

      let strIndex = el.indexOf('<div class="videos">');
      let videoLocal: string =
        strIndex > 0 ? el.substring(strIndex, el.indexOf("</div>", strIndex) + 6) : "";
      let isbreakLoopYoutube = 0;
      while (videoYoutube.length > 0) {
        const url = videoYoutube.substring(
          videoYoutube.indexOf("src=") + 5,
          videoYoutube.indexOf("frameborder=") - 2
        );
        el = el.replace(
          videoYoutube,
          "<p><a target='_blank' href='" + url + "'>" + url + "</a></p>"
        );
        videoYoutube =
          el.indexOf("<iframe") > 0
            ? el.substring(el.indexOf("<iframe"), el.indexOf("</iframe>") + 9)
            : "";
        if (isbreakLoopYoutube > 100) {
          break;
        }
        isbreakLoopYoutube++;
      }
      let isbreakLoopLocal = 0;
      while (videoLocal.length > 0) {
        const url = videoLocal.substring(
          videoLocal.indexOf("src=") + 5,
          videoLocal.indexOf("poster=") - 2
        );
        el = el.replace(videoLocal, "<p><a target='_blank' href='" + url + "'>" + url + "</a></p>");
        strIndex = el.indexOf('<div class="videos">');
        videoLocal = strIndex > 0 ? el.substring(strIndex, el.indexOf("</div>", strIndex) + 6) : "";
        if (isbreakLoopLocal > 100) {
          break;
        }
        isbreakLoopLocal++;
      }
      resolve(el);
    });
  }

  /**
   * Get el for content
   */
  setDataImg(): Promise<string> {
    return new Promise<string>(resolve => {
      const element: any = document.getElementById("detail-content");
      if (element) {
        const getImge: any = element.getElementsByTagName("img");
        Promise.all(
          [...getImge].map((img: any) => {
            let src: any = img.src;
            if (
              (src.includes("https://") || src.includes("http://")) &&
              !src.includes("/img/play_video")
            ) {
              src = src + "?timestamp=" + Date.now();
              this.getBase64Image(src).then((data: any) => {
                img.src = data;
              });
            }
          })
        ).then(() => {
          resolve(element);
        });
      }
    });
  }

  /**
   * change image url to base64
   */
  getBase64Image(imgUrl: string): Promise<string> {
    return new Promise<string>(resolve => {
      const img = new Image();
      // img.setAttribute("crossOrigin", "anonymous");
      img.crossOrigin = "anonymous";
      img.onload = () => {
        const canvas: any = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx: any = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        // dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        resolve(dataURL);
      };
      img.src = imgUrl;
    });
  }
}

// [directive]
Vue.directive("scroll", {
  inserted: (el, binding) => {
    const f = (evt: any) => {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});
</script>

<style lang="scss" scoped>
.news-detail {
  @include layoutPadding();
  flex-direction: column;
  #detail-content {
    max-width: 900px;
    margin: auto;
    font-size: 16px;
    margin-top: 50px;
  }
  .button-wrapper {
    margin: 100px 0;
    @include flex(center, center);
  }

  img {
    object-fit: cover;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;

    @include mq(xl) {
      object-fit: cover;
      width: 100%;
    }

    @include mq(m) {
      margin-bottom: 30px;
    }
  }

  @include mq(m) {
    .button-wrapper {
      margin: 50px 0;
    }
  }

  .back-button {
    margin-top: 30px;
    max-width: 140px;
  }
}

.banner-container {
  @include layoutPadding();
  width: 100%;
  position: fixed;
  height: 100px;
  bottom: 0px;
  box-sizing: border-box;
  border-top: 1px solid $gs2;
  z-index: 1;
  background-color: $white;

  @include mq(m) {
    @include fz(xxs);
    height: 50px;
  }
}

.banner {
  @include flex(space-between, center);
  width: 100%;
  max-width: 850px;
  height: 100%;
  margin: auto;
  > span {
    @include flex(flex-start, center);
    flex: 1 1 0;
    margin-right: 10px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

<style lang="scss">
.btn-pdf-banner {
  @include mq(m) {
    .link-button {
      @include fz(xxs);
    }

    .button {
      height: 30px;
    }
  }
}
.img-cover {
  max-width: 700px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  @include mq(l) {
    object-fit: cover;
    width: 100%;
  }
}
</style>
