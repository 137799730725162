<template>
  <div class="section-features">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionFeaturesSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.applicationSectionFeaturesTitle }}</div>
    </div>
    <div class="intro">
      {{ getWordings.applicationSectionFeaturesDescription }}
    </div>
    <div class="feature-list">
      <FeatureCardView
        v-for="idx in 3"
        :key="idx"
        :image="require(`@/assets/images/feature_img${idx}.svg`)"
        :title="getWordings[`applicationSectionFeatures${idx}Title`]"
        :description="getWordings[`applicationSectionFeatures${idx}Description`]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";
import FeatureCardView from "@/components/parts/FeatureCardView.vue";

@Component({
  components: {
    LinkButton,
    FeatureCardView
  },
  inject: ["routes"]
})
export default class SectionFeatures extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-features {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background-color: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .intro {
    @include fz(l);
    color: $gs4;
    padding: 60px 200px;
    text-align: center;
    @include mq(l) {
      padding: 0;
      margin: 20px 0 40px;
    }
  }

  .feature-list {
    width: 100%;
    @include flex(space-between, center);
    @include mq(xl) {
      @include flex(center, center);
      row-gap: 40px;
      column-gap: 40px;
    }
    @include mq(l) {
      @include flex(center, center);
      row-gap: 40px;
    }
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
