<template>
  <div class="home">
    <SectionIntro />
    <SectionMission />
    <SectionSolution />
    <SectionPartner />
    <SectionGovCollaboration />
    <SectionNews />
    <SectionMedia :isGrayBackground="true" />
    <SectionContactUs />
  </div>
</template>

<script>
// @ is an alias to /src
import SectionIntro from "@/components/parts/SectionIntro.vue";
import SectionMission from "@/components/parts/SectionMission.vue";
import SectionSolution from "@/components/parts/SectionSolution.vue";
import SectionPartner from "@/components/parts/SectionPartner.vue";
import SectionGovCollaboration from "@/components/parts/SectionGovCollaboration.vue";
import SectionNews from "@/components/parts/SectionNews.vue";
import SectionMedia from "@/components/parts/SectionMedia.vue";
import SectionContactUs from "@/components/parts/SectionContactUs.vue";
import routes from "@/router/routes";

export default {
  name: "Home",
  components: {
    SectionIntro,
    SectionMission,
    SectionSolution,
    SectionPartner,
    SectionGovCollaboration,
    SectionNews,
    SectionMedia,
    SectionContactUs
  },
  metaInfo: routes.home.meta
};
</script>
