import Vue from "vue";
import VueMeta from "vue-meta";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import News from "../views/News.vue";
import Contact from "../views/Contact.vue";
import NewsDetail from "../views/NewsDetail.vue";
import Partner from "../views/Partner.vue";
import SmartPOST from "../views/SmartPOST.vue";
import Gov from "../views/Gov.vue";
import Dev from "../views/Dev.vue";
import Application from "../views/Application.vue";

import Routes from "./routes";

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: Routes.home.url
  },
  {
    path: Routes.home.url,
    name: Routes.home.name,
    component: Home
  },
  {
    path: Routes.about.url,
    name: Routes.about.name,
    component: About,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  },
  {
    path: Routes.news.url,
    name: Routes.news.name,
    component: News
  },
  {
    path: Routes.news.detail.url,
    name: Routes.news.detail.name,
    component: NewsDetail,
    props: true
  },
  {
    path: Routes.contactUs.url,
    name: Routes.contactUs.name,
    component: Contact
  },
  {
    path: Routes.partner.url,
    name: Routes.partner.name,
    component: Partner,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  },
  {
    path: Routes.smartpost.url,
    name: Routes.smartpost.name,
    component: SmartPOST,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  },
  {
    path: Routes.gov.url,
    name: Routes.gov.name,
    component: Gov,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  },
  {
    path: Routes.dev.url,
    name: Routes.dev.name,
    component: Dev,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  },
  {
    path: Routes.application.url,
    name: Routes.application.name,
    component: Application,
    props: route => ({
      timestamp: route.params["timestamp"]
    })
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
