<template>
  <button class="nav-button link-button button" type="submit">
    {{ text }}
    <img :src="whiteRightIcon" />
  </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import whiteRightIcon from "@/assets/icons/nav-right-white.svg";

@Component
export default class SubmitButton extends Vue {
  /// [Prop]
  /// Button's text
  @Prop({ type: String, required: true }) private text!: string;

  /// [resources]
  whiteRightIcon = whiteRightIcon;

  /// [Data]

  /// [Local Methods]
}
</script>

<style lang="scss" scoped>
.nav-button:not(:disabled):hover {
  img {
    animation: arrow 0.75s infinite ease-out forwards;
  }
}

img {
  position: relative;
  margin-left: 20px;
}

@keyframes arrow {
  from {
    left: 0;
  }
  to {
    left: 10px;
  }
}
</style>
