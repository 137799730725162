<template>
  <div class="smartpost">
    <SmartPOSTBannersView id="smartpost" :timestamp="timestamp" />
    <SectionWhatSmartPOST />
    <SectionWhySmartPOST />
    <SectionSmartPOSTLearnMore />
    <SectionHelpCenter :hrefLinkFAQ="'https://help.xid.inc/smartpost'" />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Prop, Vue } from "vue-property-decorator";
import SmartPOSTBannersView from "@/components/parts/SmartPOSTBannersView.vue";
import SectionWhatSmartPOST from "@/components/parts/SectionWhatSmartPOST.vue";
import SectionWhySmartPOST from "@/components/parts/SectionWhySmartPOST.vue";
import SectionHelpCenter from "@/components/parts/SectionHelpCenter.vue";
import SectionSmartPOSTLearnMore from "@/components/parts/SectionSmartPOSTLearnMore.vue";
import routes from "@/router/routes";
@Component({
  name: "SmartPOST",
  components: {
    SmartPOSTBannersView,
    SectionWhatSmartPOST,
    SectionWhySmartPOST,
    SectionSmartPOSTLearnMore,
    SectionHelpCenter
  },
  metaInfo: routes.smartpost.meta
})
export default class SmartPOST extends Vue {
  @Prop({ type: String }) private timestamp!: string;
}
</script>
