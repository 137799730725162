<template>
  <div class="section-whatxid">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionXIDSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.applicationSectionXIDTitle }}</div>
    </div>
    <div class="slogan">
      <div class="text" v-html="getWordings.applicationSectionXIDMessage"></div>
    </div>
    <div class="mission_content" v-html="getWordings.applicationSectionXIDDescription"></div>
    <LinkButton
      class="link-button"
      :wordingKey="'applicationSectionXIDButton'"
      :hrefLink="'https://help.xid.inc/ab18212dc0f947fc88653ea90e96905d'"
      :openNewTab="true"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionWhatXID extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-whatxid {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .link-button {
    margin-top: 60px;
    @include mq(m) {
      margin-top: 20px;
    }
  }

  .mission_title,
  .mission_content {
    color: $gs4;
  }

  .mission_content {
    text-align: justify;
    max-width: 850px;
  }

  @include mq(m) {
    padding-top: 50px;
    padding-bottom: 50px;

    .mission_content {
      text-align: left;
    }

    .separator-line {
      @include size(0px, 3px);
      margin-top: 10px;

      &.animating {
        width: 100px;
      }
    }
  }
}
</style>
