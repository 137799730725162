<template>
  <div class="section-use-case">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.governmentSectionUseCaseSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.governmentSectionUseCaseTitle }}</div>
      <div class="use-description" v-html="getWordings.governmentSectionUseCaseDescription"></div>
      <div class="solution solution-1">
        <img
          class="solution-img solution-img-1"
          :src="require('@/assets/images/future_government.svg')"
        />
        <div class="solution-card solution-card-1">
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC1 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC2 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC3 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC4 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC5 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC6 }}
            </span>
          </div>
          <div class="check-content">
            <span class="content">
              <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
              {{ getWordings.governmentSectionUseCaseTOC7 }}
            </span>
          </div>
          <p
            class="solution-content"
            v-html="getWordings.governmentSectionUseCaseDocDescription"
          ></p>
          <LinkButton
            class="link-button"
            :wordingKey="'btn_download_now'"
            :hrefLink="downloadDocsURL"
            :isShowIcon="false"
            :openNewTab="true"
            :useLocalWording="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionUseCase extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  downloadDocsURL = process.env.VUE_APP_DOCS_FOR_GOV_DOWNLOAD_URL as string;
}
</script>

<style lang="scss" scoped>
.section-use-case {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  .use-description {
    margin: auto;
    max-width: 800px;
    margin-top: 60px;
    @include mq(l) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  .description {
    margin: auto;
    max-width: 800px;
    margin-top: 30px;
    margin-bottom: 120px;
    @include mq(l) {
      margin-bottom: 0px;
    }
  }
  .solution {
    @include flex(space-between, center);
    position: relative;
    width: 100%;
    height: 600px;
    @include mq(l) {
      height: auto;
    }
  }
  .check-content {
    display: flex;
    margin-bottom: 20px;
    @include mq(l) {
      margin-bottom: 10px;
    }
    font-weight: bold;
    .content {
      display: flex;
      float: left;
      text-align: left;
      @include fz(l);
      @include mq(l) {
        @include fz(s);
        width: 100%;
      }
      line-height: 30px;
    }
    .check-mark {
      margin-right: 10px;
    }
  }

  .solution-1 {
    margin-top: 60px;
    @include mq(l) {
      margin-top: 40px;
    }
  }
  .solution-img-1 {
    box-shadow: 0px 0px 10px 0px $boxShadow15;
    margin-right: 100px;
    left: 0;
    @include mq(l) {
      margin: 10px 20px;
    }
  }

  .solution-img {
    position: absolute;
    z-index: 1;
    @include mq(l) {
      width: 100%;
      position: relative;
      margin-bottom: -30px;
    }
    @include mq(m) {
      width: calc(100% - 40px);
    }
  }

  .solution-card-1 {
    right: 0;
    padding: 50px 100px 50px 200px;
  }

  .solution-card {
    position: absolute;
    width: 650px;
    border: 1px solid $gs7;
    border-radius: $radius15;
    background-color: white;
    @include mq(l) {
      @include size(100%);
      position: relative;
      padding: 20px;
      padding-top: 40px;
      width: 100%;
    }
  }

  .solution-card-title {
    @include titleFz(xs);
    @include fw(medium);
    text-align: left;
    line-height: 30px;
  }

  .solution-content {
    float: left;
    text-align: left;
    @include fz(l);
    line-height: 30px;
    margin: 10px 0 30px;
    @include mq(l) {
      margin-top: 10px;
      margin-bottom: 20px;
      @include fz(s);
    }
  }

  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
</style>
