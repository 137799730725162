<template>
  <div class="banners-view">
    <div class="left-banners">
      <div class="title" v-html="getWordings.partnerBannerTitle"></div>
      <div class="description" v-html="getWordings.partnerBannerDescription"></div>
    </div>
    <div class="right-banners">
      <img :src="partnerAnimation + '?r=' + timestamp" />
      <div class="image-background"></div>
    </div>
    <div class="partner-triangle-mobile">
      <img :src="require('@/assets/images/triangle-home-mob.svg')" />
    </div>
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Vue, Component, Prop } from "vue-property-decorator";
import partnerAnimation from "@/assets/animation/partners.svg";
import { Getter } from "vuex-class";

@Component({
  name: "PartnerBannersView",
  components: {}
})
export default class PartnerBannersView extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  @Prop({ type: String }) private timestamp!: string;
  partnerAnimation: string = partnerAnimation;
}
</script>

<style lang="scss" scoped>
.banners-view {
  @include layoutPadding();
  @include flex(left, center);
  flex-wrap: nowrap;
  position: relative;
  background-color: $gs8;
  padding-right: 0;
  @include mq(l) {
    padding-top: 40px;
    @include flex(center, center);
  }
  .partner-triangle-mobile {
    display: none;
    img {
      width: 100vw;
    }
    @include mq(l) {
      display: block;
      position: absolute;
      bottom: 0;
    }
  }
  .left-banners {
    text-align: left;
    max-width: 600px;
  }

  .right-banners {
    position: relative;
    width: 100%;
    > img {
      position: absolute;
      z-index: 1;
      top: 44px;
      left: 150px;
      @include mq(l) {
        display: inline-block;
        width: 75%;
        position: relative;
        top: 4px;
        left: unset;
        margin-top: 20px;
      }
    }
  }

  .image {
    @include size(500px, 500px);
    background-color: $gs6;
    @include mq(l) {
      margin-top: 40px;
    }
    @include mq(m) {
      @include size(315px);
    }
  }

  .image-background {
    @include size(100%, 500px);
    background-color: $orbit-color;
    // background-image: linear-gradient(#8133ce, #4533ce);
    position: relative;
    margin-left: 340.5px;
    @include mq(l) {
      display: none;
    }
  }
  .image-background:before {
    content: "";
    @include size(353.54px);
    border-bottom-left-radius: 15px;
    background-color: $orbit-color;
    // background-image: linear-gradient(135deg, #8133ce, #4533ce);
    transform: rotate(45deg);
    position: absolute;
    left: -178px;
    top: 73px;
  }

  .title {
    @include titleFz(xl);
    @include fw(heavy);
    line-height: 60px;
    margin-bottom: 20px;
    @include mq(m) {
      @include titleFz(xs);
      text-align: center;
    }
  }

  .description {
    @include titleFz(xs);
    line-height: 40px;
    @include mq(m) {
      @include fz(m);
      text-align: center;
    }
    // @include fw(heavy);
  }
}
</style>
