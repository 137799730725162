<template>
  <div class="section-truth-companies" :class="{ 'gray-background': isGrayBackground }">
    <!-- Title -->
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionMediaSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionMediaTitle }}</div>
    </div>
    <div class="section-content">
      <div class="section-banner">
        <div class="banner-top">
          <img class="banner-image-top" :src="require('@/assets/logos/xid-logo.svg')" />
          <div class="banner-text" v-html="'信用コストの低い</br>デジタル社会を、実現する'"></div>
        </div>
        <div class="banner-bottom">
          <img :src="require('@/assets/images/mindigi.svg')" />
        </div>
      </div>
      <div class="list-items">
        <CardView
          class="media-card"
          v-for="(item, idx) in mindigi"
          :key="idx"
          :title="item.title"
          :image="item.image"
          :date="item.date"
          :url="item.url"
        />
        <div class="button-wrapper">
          <LinkButton
            class="link-button"
            :wordingKey="'homeSectionMediaButton'"
            :isShowIcon="false"
            :hrefLink="'https://blog.xid.inc/'"
            :openNewTab="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Getter } from "vuex-class";
import CardView from "@/components/parts/MediaCardView.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

@Component({
  components: {
    Splide,
    SplideSlide,
    CardView,
    LinkButton
  }
})
export default class SectionMedia extends Vue {
  @Prop({ type: Boolean, default: false }) private isGrayBackground!: boolean;
  /// [Getter]
  @Getter("text/getWordings") getWordings!: any;
  /// Mock up of truth company list image
  /// [data]
  mediaRelationsLists = [];
  assetsDomain = process.env.VUE_APP_ASSETS_URL as string;

  options = {
    type: "loop",
    perPage: 5,
    perMove: 1,
    rewind: true,
    autoplay: true,
    interval: 1500,
    pauseOnHover: false,
    arrows: false
  };
  mindigi = [];

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getListMinDigi(contentRepository);

    const width = window.innerWidth;
    if (width <= 667) {
      this.options.perPage = 3;
    } else {
      this.options.perPage = 5;
    }
  }

  async getListMinDigi(contentRepository: any): Promise<void> {
    this.mindigi = (await contentRepository.getMinDigiPaging("1", "3")).map((item: any): any => {
      return {
        url: item.attributes.url,
        image: item.attributes.cover_url,
        title: item.attributes.title,
        date: item.attributes.date
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.gray-background {
  background: $gs8;
}
.section-truth-companies {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section-content {
    @include flex(center, center);
    margin-top: 60px;
    @include mq(m) {
      margin-top: 40px;
    }
  }

  .section-banner {
    width: 300px;
    margin-right: 50px;
    border-radius: 16px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    overflow: hidden;
    @include mq(xl) {
      display: none;
    }
  }

  .banner-text {
    @include fz(l);
    @include fw(medium);
    color: #000;
    height: 56px;
    margin-top: 20.8px;
    padding-bottom: 135px;
  }

  .banner-top {
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .banner-image-top {
      margin: auto;
      padding-top: 80px;
    }
  }

  .banner-bottom {
    background-image: linear-gradient(#fff, $primary2);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .media-card {
    ::v-deep .link-button {
      > img {
        margin-left: 5px;
      }
    }
  }

  .button-wrapper {
    ::v-deep .link-button {
      padding: 0;
      width: 150px;
      min-width: 150px;
      @include mq(m) {
        width: 100%;
      }
    }
  }
}
</style>
