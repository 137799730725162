var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section-news-footer"},[(_vm.prevPost)?_c('router-link',{staticClass:"previous-news",attrs:{"to":{
      name: _vm.routes.news.detail.name,
      params: { id: _vm.prevPost.id.toString() }
    }}},[_c('div',[_c('LinkButton',{attrs:{"isShowIcon":true,"isLinkButton":true,"isNavLeft":true,"linkUnderline":false,"wordingKey":'newsPreviousButton'}}),_c('img',{staticClass:"news-images",attrs:{"src":_vm.prevPost && _vm.prevPost.cover
            ? _vm.assetsDomain + _vm.prevPost.cover.hash + _vm.prevPost.cover.ext
            : 'https://public.xid.inc/assets/news_default_705af5bb55.svg'}}),_c('div',{staticClass:"bold-text"},[_vm._v(_vm._s(_vm.prevPost ? _vm.prevPost.attributes.title : ""))])],1)]):_c('div',{staticClass:"previous-news"}),(_vm.nextPost)?_c('router-link',{staticClass:"next-news",attrs:{"to":{
      name: _vm.routes.news.detail.name,
      params: { id: _vm.nextPost.id.toString() }
    }}},[_c('div',[_c('LinkButton',{attrs:{"isShowIcon":true,"isLinkButton":true,"linkUnderline":false,"wordingKey":'newsNextButton'}}),_c('img',{staticClass:"news-images",attrs:{"src":_vm.nextPost && _vm.nextPost.cover
            ? _vm.assetsDomain + _vm.nextPost.cover.hash + _vm.nextPost.cover.ext
            : 'https://public.xid.inc/assets/news_default_705af5bb55.svg'}}),_c('div',{staticClass:"bold-text"},[_vm._v(_vm._s(_vm.nextPost ? _vm.nextPost.attributes.title : ""))])],1)]):_c('div',{staticClass:"next-news"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }