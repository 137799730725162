<template>
  <a :href="hrefLink" :target="target">
    <div
      class="nav-button link-button"
      :class="{
        link: isLinkButton,
        button: !isLinkButton,
        white: isWhiteButton,
        'link-button-underline': linkUnderline,
        'left-nav': isNavLeft
      }"
    >
      {{ isShowText ? (useLocalWording ? $t(wordingKey) : getWordings[wordingKey]) : "" }}
      <img v-if="isShowIcon" :src="icon" />
    </div>
  </a>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import whiteRightIcon from "@/assets/icons/nav-right-white.svg";
import coloredRightIcon from "@/assets/icons/nav-right-colored.svg";
import coloredLeftIcon from "@/assets/icons/nav-left-colored.svg";
import { Getter } from "vuex-class";

@Component
export default class LinkButton extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  /// [Prop]
  /// Button's text
  @Prop({ type: String, required: true }) private wordingKey!: string;
  /// Redirect link when clicked
  @Prop({ type: String, required: false }) private hrefLink!: string;
  @Prop({ type: Boolean, default: false }) private openNewTab!: boolean;
  /// Flag check whether the button is link button
  @Prop({ type: Boolean, required: false, default: false }) private isLinkButton!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private isWhiteButton!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private isNavLeft!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) private linkUnderline!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private isShowIcon!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) private isShowText!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private useLocalWording!: boolean;

  /// [computed]
  get icon() {
    return this.isNavLeft ? coloredLeftIcon : this.isLinkButton ? coloredRightIcon : whiteRightIcon;
  }

  get target() {
    return this.openNewTab ? "_blank" : "";
  }

  /// [Data]

  /// [Event handler]
  // clickHandler() {
  //   this.$emit("beforHandler");
  //   this.$router.push(this.hrefLink);
  // }

  /// [Local Methods]
}
</script>

<style lang="scss" scoped>
.nav-button:hover {
  &:not(.link) {
    img {
      animation: arrow 0.75s infinite ease-out forwards;
    }
  }
  &.link {
    &:not(.left-nav) {
      img {
        animation: link-arrow 0.75s infinite ease-out forwards;
      }
    }
    &.left-nav {
      img {
        animation: arrow 0.75s infinite ease-in forwards reverse;
      }
    }
  }
}

img {
  position: relative;
  margin-left: 10px;
}

.left-nav {
  flex-direction: row-reverse;
  img {
    margin-right: 10px;
    margin-left: 0px;
  }
}

@keyframes arrow {
  from {
    left: 0;
  }
  to {
    left: 10px;
  }
}

@keyframes link-arrow {
  from {
    left: -10px;
  }
  to {
    left: 0;
  }
}
</style>
