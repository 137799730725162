<template>
  <div class="application">
    <BannersView id="app" :timestamp="timestamp" />
    <SectionWhatXID />
    <SectionFeatures />
    <SectionOtherFeatures />
    <!-- <SectionPrivacy /> -->
    <SectionLocalGov :timestamp="timestamp" />
    <SectionServices />
    <SectionHelpCenter :isGreyBackground="true" :hrefLinkFAQ="'https://help.xid.inc/xid'" />
    <SectionDownloadXID />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Prop, Vue } from "vue-property-decorator";
import BannersView from "@/components/parts/ApplicationBannersView.vue";
import SectionWhatXID from "@/components/parts/SectionWhatXID.vue";
import SectionFeatures from "@/components/parts/SectionFeatures.vue";
import SectionOtherFeatures from "@/components/parts/SectionOtherFeatures.vue";
import SectionPrivacy from "@/components/parts/SectionPrivacy.vue";
import SectionLocalGov from "@/components/parts/SectionLocalGov.vue";
import SectionServices from "@/components/parts/SectionServices.vue";
import SectionHelpCenter from "@/components/parts/SectionHelpCenter.vue";
import SectionDownloadXID from "@/components/parts/SectionDownloadXID.vue";

import routes from "@/router/routes";
@Component({
  name: "Application",
  components: {
    BannersView,
    SectionWhatXID,
    SectionFeatures,
    SectionOtherFeatures,
    SectionPrivacy,
    SectionLocalGov,
    SectionServices,
    SectionHelpCenter,
    SectionDownloadXID
  },
  metaInfo: routes.application.meta
})
export default class Application extends Vue {
  @Prop({ type: String }) private timestamp!: string;
}
</script>
