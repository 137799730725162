<template>
  <div class="partner-view">
    <div class="wrapper">
      <img :src="image" />
    </div>
    <div class="name">{{ name }}</div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class PartnerView extends Vue {
  @Prop({ type: String, required: true }) name!: string;
  @Prop({ type: String, default: "" }) image!: string;
}
</script>

<style lang="scss" scoped>
.partner-view {
  @include flex(flex-start, center);
  flex-direction: column;
  box-sizing: border-box;
  flex-wrap: nowrap;

  .wrapper {
    @include flex(center, center);
    width: 100%;
    height: 100%;
    min-height: 150px;
    max-height: 150px;
    border: 1px solid $gs2;
    border-radius: $radius15;
    box-sizing: border-box;
    background: $white;
    overflow: hidden;

    > img {
      max-width: 100%;
      max-height: 100%;
      padding: 0 20px;
      object-fit: scale-down;
      box-sizing: border-box;
    }
  }
  .name {
    @include fz(l);
    @include fw(bold);
    color: $black;
    max-height: 54px;
    line-height: 23px;
    margin-top: 10px;
  }

  @include mq(m) {
    .wrapper {
      @include flex(center, center);
      min-height: 70px;
      margin-bottom: 5px;
      border-radius: 10px;

      > img {
        padding: 5px;
      }
    }

    .name {
      @include fz(xs);
      margin-top: 0;
    }
  }
}
</style>
