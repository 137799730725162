import { GetterTree } from "vuex";
import { ScrollState } from "./types";
import { RootState } from "../types";
const getters: GetterTree<ScrollState, RootState> = {
  getAboutScroll(state: ScrollState) {
    return state.aboutScrollData;
  }
};

export default getters;
