<template>
  <div class="banners-view">
    <div class="left-banners">
      <div class="title" v-html="getWordings.applicationBannerTitle"></div>
      <div class="description" v-html="getWordings.applicationBannerDescription"></div>
      <div class="button-wrapper">
        <img
          @click="openURL('https://apps.apple.com/jp/app/xid/id1495147544')"
          :src="require('@/assets/icons/app-store.svg')"
        />
        <img
          @click="openURL('https://play.google.com/store/apps/details?id=me.x.id')"
          class="download-btn"
          :src="require('@/assets/icons/google-play.svg')"
        />
      </div>
    </div>
    <div class="right-banners">
      <img :src="applicationAnimation + '?r=' + timestamp" />
      <div class="image-background"></div>
    </div>
    <div class="app-triangle-mobile">
      <img :src="require('@/assets/images/triangle-app.svg')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import applicationAnimation from "@/assets/animation/app.svg";
import { Getter } from "vuex-class";

@Component({
  name: "ApplicationBannersView",
  components: {}
})
// @ is an alias to /src
export default class ApplicationBannersView extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  @Prop({ type: String }) private timestamp!: string;
  applicationAnimation: string = applicationAnimation;

  openURL(url: string) {
    window.open(url, "_blank");
  }
}
</script>

<style lang="scss" scoped>
.banners-view {
  @include layoutPadding();
  @include flex(left, center);
  flex-wrap: nowrap;
  position: relative;
  background-color: $gs8;
  padding-right: 0;
  @include mq(l) {
    padding-top: 40px;
    @include flex(center, center);
  }
  .app-triangle-mobile {
    display: none;
    img {
      width: 100vw;
    }
    @include mq(l) {
      display: block;
      position: absolute;
      bottom: 0;
    }
  }
  .left-banners {
    text-align: left;
    max-width: 600px;
  }

  .right-banners {
    position: relative;
    width: 100%;
    > img {
      position: absolute;
      z-index: 1;
      top: 50px;
      left: 200px;
      @include mq(l) {
        display: inline-block;
        width: 75%;
        position: relative;
        top: 14px;
        left: unset;
        margin-top: 40px;
      }
      @include mq(m) {
        top: 7px;
      }
    }
  }

  .image {
    // margin-left: 340.7px;
    @include size(500px, 500px);
    background-color: $gs6;
    @include mq(l) {
      margin-top: 40px;
    }
    @include mq(m) {
      @include size(315px);
    }
  }

  .image-background {
    @include size(100%, 500px);
    background-image: linear-gradient(#7ae3b3, #33ceaa);
    position: relative;
    margin-left: 340.5px;
    @include mq(l) {
      display: none;
    }
  }
  .image-background:before {
    content: "";
    @include size(353.54px);
    border-bottom-left-radius: 15px;
    background-image: linear-gradient(135deg, #7ae3b3, #33ceaa);
    transform: rotate(45deg);
    position: absolute;
    left: -178px;
    top: 73px;
  }

  .title {
    @include titleFz(xl);
    @include fw(heavy);
    line-height: 60px;
    margin-bottom: 20px;
    @include mq(m) {
      @include titleFz(xs);
      text-align: center;
    }
  }

  .description {
    @include titleFz(xs);
    line-height: 40px;
    // @include fw(heavy);
    @include mq(m) {
      @include fz(m);
      text-align: center;
    }
  }
  .button-wrapper {
    margin-top: 60px;
    @include flex(left, center);
    @include mq(m) {
      @include flex(center, center);
      flex-wrap: nowrap;
      margin-top: 40px;
    }

    > img {
      cursor: pointer;
    }
  }
  .download-btn {
    margin-left: 40.2px;
    .nav-button {
      background: white !important;
    }
  }
}
</style>
