<template>
  <div class="contact" id="contact-us">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.contactPageSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.contactPageTitle }}</div>
    </div>
    <SectionContact />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Vue } from "vue-property-decorator";
import SectionContact from "@/components/parts/SectionContact.vue";
import { Getter } from "vuex-class";
import routes from "@/router/routes";

@Component({
  components: {
    SectionContact
  },
  metaInfo: routes.contactUs.meta
})
export default class Contact extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.contact {
  @include layoutPadding();
  @extend %centerContent;
  padding-top: 60px;
  flex-direction: column;

  .contact-header {
    @include size(100%, auto);
    @extend %centerContent;
    position: relative;
    flex-direction: column;
    margin: 100px 0 0px;
  }

  @include mq(m) {
    padding-top: 40px;
    .contact-header {
      @include size(100%, auto);
      // @include layoutPadding();
      @extend %centerContent;
      position: relative;
      flex-direction: column;
      margin: 50px 0 50px;
    }
  }
}
</style>
