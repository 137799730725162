<template>
  <div class="section-company-profile">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.aboutSectionCompanyProfileSubtitle }}
      </div>
      <div class="section-header-text">
        {{ getWordings.aboutSectionCompanyProfileTitle }}
      </div>
    </div>
    <div class="content-wrapper">
      <iframe
        class="map-bound"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1620.6244032119619!2d139.7525340564507!3d35.67087518019666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188bed6836bfdd%3A0x331447933c92080b!2z5pel5q-U6LC344OR44O844Kv44OV44Ot44Oz44OI!5e0!3m2!1sja!2sjp!4v1678150600678!5m2!1sja!2sjp"
        frameborder="0"
        style="border:0;"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
      ></iframe>
      <div class="profile-info">
        <div class="profile-row" v-for="(item, idx) in items" :key="idx">
          <div class="profile-label" v-html="item.title"></div>
          <div class="profile-value" v-html="item.value"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component
export default class SectionCompanyProfile extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  items = [];

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getCompanyProfiles(contentRepository);
  }

  getCompanyProfiles(contentRepository: any): Promise<void> {
    return contentRepository.getCompanyProfilesList().then((companyProfiles: any) => {
      this.items = companyProfiles.map((item: any): any => {
        return {
          title: item.attributes.key,
          value: item.attributes.value.replace(/\n/g, "</br>")
        };
      });
    });
  }
}
</script>

<style lang="scss" scoped>
.section-company-profile {
  @include flex(center, center);
  @include size(100%, auto);
  @include layoutPadding();
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .content-wrapper {
    @include flex(center, center);
    width: 100%;
    height: 600px;
    flex-direction: row;
    flex-wrap: nowrap;
    // border: 1px solid $gs2;
    border-radius: $radius;
    margin-top: 60px;
    position: relative;

    .map-bound {
      position: absolute;
      left: 0;
      // min-width: 500px;
      max-width: 500px;
      max-height: 500px;
      width: 500px;
      height: 500px;
      border: 1px solid $gs2 !important;
      border-radius: 15px;
      z-index: 2;
    }
  }

  .profile-info {
    position: absolute;
    right: 0;
    @include flex(flex-start, center);
    flex-direction: column;
    flex: 1 1 auto;
    flex-wrap: nowrap;
    box-sizing: border-box;
    padding: 50px 100px 108px 200px;
    border: 1px solid $gs7;
    border-radius: 15px;
    z-index: 1;
    background: $gs1;
  }

  .profile-row {
    // @include flex(flex-start, flex-start);
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    // width: 100%;
    width: 500px;

    .profile-label {
      @include fw(heavy);
      @include size(100px, auto);
      text-align: left;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .profile-value {
      text-align: left;
      line-height: 24px;
      color: $gs4;
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  @include mq(l) {
    .profile-info {
      padding: 0px 20px;
      right: unset;
      bottom: 0;
    }
  }

  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;

    .content-wrapper {
      flex-direction: column;
      height: auto;
      margin-top: 30px;

      .map-bound {
        position: relative;
        left: unset;
        top: 0;
        @include size(calc(100% - 40px), calc(100vw - 100px));
        margin-bottom: -30px;
      }
    }

    .profile-info {
      position: relative;
      width: 100%;
      padding: 40px 20px 20px;

      .profile-label {
        @include size(80px, auto);
        line-height: 20px;
        min-width: 80px;
        margin-bottom: 5px;
      }

      .profile-value {
        min-height: 20px;
        line-height: 20px;
      }

      .profile-row {
        width: 100%;
        padding: 0;

        &:first-child > .profile-value {
          // @include fz(m);
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        &:last-child {
          // margin-top: 20px;
        }
      }
    }
  }
}
</style>
