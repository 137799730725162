<template>
  <div class="section-associate">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.aboutSectionAssociateSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.aboutSectionAssociateTitle }}</div>
    </div>

    <div class="items-wrapper">
      <PartnerView
        class="part-item"
        v-for="(item, idx) in associations"
        :key="idx"
        :image="item.image"
        :name="item.name"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PartnerView from "@/components/parts/PartnerView.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    PartnerView
  }
})
export default class SectionAssociate extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  associations = [];
  assetsDomain = process.env.VUE_APP_ASSETS_URL as string;

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getAssociations(contentRepository);
  }

  async getAssociations(contentRepository: any): Promise<void> {
    this.associations = (await contentRepository.getAssociationsList()).map((item: any): any => {
      const cover = item.attributes.cover.data.attributes;
      return {
        name: item.attributes.name,
        image: cover.url
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.section-associate {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 60px;

  .items-wrapper {
    @include flex(center, center);
    column-gap: 20px;
    margin-top: 60px;
  }

  .part-item {
    width: 224px;
    height: 214px;
    margin-bottom: 40px;
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;

    .items-wrapper {
      @include flex(space-between, center);
      column-gap: 10px;
      margin-top: 40px;
    }

    .part-item {
      width: 98px;
      height: 70px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
    }
  }
}
</style>
