<template>
  <div class="section-local-gov" :class="{ 'grey-background': isInSpPage }">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionLGSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.applicationSectionLGTitle }}</div>
    </div>
    <div class="gov-number" v-if="!isInSpPage">
      <div>
        <p class="ratio__unit number">
          <!-- <number
            v-scroll="increaseStatTitle"
            :from="0"
            :to="statTitle2"
            :duration="1.5"
            :delay="0"
            easing="Power0.easeNone"
          /> -->
          <span class="unit">{{ getWordings.applicationSectionLGCityNumber }}</span>
        </p>
        <div class="desc">{{ getWordings.applicationSectionLGCity }}</div>
      </div>
    </div>
    <div class="items-wrapper">
      <PartnerView
        class="part-item"
        v-for="(item, idx) in governments"
        :key="idx"
        :image="item.image"
        :name="item.name"
      />
    </div>
    <LinkButton
      class="link-button"
      :wordingKey="'applicationSectionLGButton'"
      :hrefLink="routes.gov.url"
    />
  </div>
</template>

<script lang="ts">
// import Vue from "vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PartnerView from "@/components/parts/PartnerView.vue";
import CheckBox from "@/components/controls/CheckBox.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import VueNumber from "vue-number-animation";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
Vue.use(VueNumber);

@Component({
  name: "SectionLocalGov",
  components: {
    PartnerView,
    CheckBox,
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionLocalGov extends Vue {
  @Prop({ type: String }) private timestamp!: string;
  @Prop({ type: Boolean, default: false }) private isInSpPage!: string;
  @Getter("text/getWordings") getWordings!: any;
  governments = [];
  statTitle1 = 0;
  statTitle2 = 0;
  increase = true;

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getGovernments(contentRepository);
  }

  async getGovernments(contentRepository: any) {
    this.governments = (await contentRepository.getGovernmentsList()).map((item: any): any => {
      const cover = item.attributes.cover.data.attributes;
      return {
        name: item.attributes.prefecture + " " + item.attributes.city,
        image: cover.url
      };
    });
  }

  @Watch("timestamp")
  restartStatAnimation() {
    this.statTitle1 = 0;
    this.statTitle2 = 0;
    this.increase = true;
  }

  increaseStatTitle(event: Event, el: any) {
    if (this.increase) {
      const rect = el.getBoundingClientRect();
      const inView =
        rect.width > 0 &&
        rect.height > 0 &&
        rect.top >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight);
      if (inView) {
        this.statTitle1 = 3;
        this.statTitle2 = 32;
        this.increase = false;
      }
    }
  }
}
// [directive]
Vue.directive("scroll", {
  inserted: (el, binding) => {
    const f = (evt: any) => {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});
</script>

<style lang="scss" scoped>
.grey-background {
  background-color: $gs8;
}
.section-local-gov {
  @include size(100%, auto);
  @include layoutPadding();
  background-color: $gs8;
  padding-top: 100px;
  padding-bottom: 100px;

  .items-wrapper {
    @include flex(center, center);
    column-gap: 19.9px;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .part-item {
    width: 154.3px;
    height: 100px;
    margin-bottom: 90.5px;
  }

  ::v-deep .part-item {
    .name {
      @include fz(m);
      line-height: 20px;
    }
    .wrapper {
      min-height: 100px;
    }
  }

  .link-button {
    margin: auto;
  }

  .button-wrapper {
    margin: 0 auto 0;
    .action-button {
      @include flex(center, center);
      margin: auto;
      width: 100%;
    }
  }

  .gov-number {
    @include flex(center, center);
    margin-top: 60px;
    text-align: center;
    @include mq(l) {
      margin-top: 40px;
    }
    .number {
      @include titleFz(xl);
      @include fw(heavy);
      color: $primary1;
      line-height: 58px;
      width: 400px;
      @include mq(l) {
        width: 157px;
        line-height: 29px;
        @include titleFz(xs);
      }
    }
    .desc {
      @include titleFz(xs);
      @include fw(medium);
      color: $primary1;
      line-height: 29px;
      margin-top: 10px;
      @include mq(l) {
        margin-top: 5px;
        line-height: 17px;
        @include fz(s);
      }
    }
  }

  .check-box {
    margin-right: 60px;
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;

    .group-title {
      @include fz(m);
      @include fw(medium);
      color: $gs3;

      &.group-1 {
        margin-top: 50px;
      }
      &.group-2 {
        margin-top: 20px;
      }
    }

    .items-wrapper {
      @include flex(space-between, center);
      column-gap: 10px;
      margin-top: 40px;
      margin-bottom: 0;

      &:empty {
        margin-bottom: 30px;
      }
    }

    .part-item {
      width: calc(33.33% - 10px);
      height: 70px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 55px;
    }

    ::v-deep .part-item {
      .name {
        @include fz(xs);
        line-height: 20px;
      }
      .wrapper {
        min-height: 70px;
      }
    }
  }
}
</style>
