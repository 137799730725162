import { GetterTree } from "vuex";
import { TextState } from "./types";
import { RootState } from "../types";
const getters: GetterTree<TextState, RootState> = {
  getWordings(state: TextState) {
    return state.wordingsData;
  }
};

export default getters;
