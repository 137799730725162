<template>
  <div class="action-button">
    <button class="link-button button" :disabled="disabled" @click="clickHandler">
      {{ getWordings[wordingKey] }}
      <img v-if="showIcon" :src="icon" />
    </button>
    <div class="link-button button disabled loading" v-if="isLoading">
      <div class="dot1"></div>
      <div class="dot2"></div>
      <div class="dot3"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import whiteDownIcon from "@/assets/icons/nav-down-white.svg";
import whiteRightIconDownload from "@/assets/icons/nav-right-download.svg";
import { Getter } from "vuex-class";

@Component
export default class ActionButton extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  /// [Prop]
  /// Button's text
  @Prop({ type: String, required: true }) private wordingKey!: string;
  @Prop({ type: Boolean, default: false }) private disabled!: boolean;
  @Prop({ type: Boolean, default: true }) private showIcon!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private isLinkDownload!: boolean;

  /// [Data]
  isLoading = false;

  /// [computed]
  get icon() {
    return this.isLinkDownload ? whiteRightIconDownload : whiteDownIcon;
  }

  /// [Event handler]
  clickHandler() {
    this.isLoading = true;
    this.$emit("onClick", this.done);
  }

  done() {
    this.isLoading = false;
  }

  /// [Local Methods]
}
</script>

<style lang="scss" scoped>
.action-button {
  position: relative;

  .link-button {
    min-width: 200px;
    @include mq(l) {
      min-width: unset;
    }
  }

  img {
    margin-left: 20px;
  }

  .loading {
    position: absolute;
    box-sizing: border-box;
    top: 0;
    // left: 0;
    // width: 100%;
    // height: 100%;
    @include mq(l) {
      width: 100%;
      height: 100%;
    }

    > div {
      @include size(10px);
      border-radius: $radius;
      background: $white;
      margin: 5px;
      transform: translateY(5px);
      animation-name: example;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;

      &.dot2 {
        animation-delay: 0.2s;
      }

      &.dot3 {
        animation-delay: 0.4s;
      }
    }
  }

  @include mq(m) {
    .loading {
      > div {
        @include size(7px);
      }
    }
  }

  @keyframes example {
    0% {
      transform: translateY(5px);
    }
    45% {
      transform: translateY(-5px);
    }
    90% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(5px);
    }
  }
}
</style>
