import { Module } from "vuex";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { ScrollState } from "./types";
import { RootState } from "../types";

export const state: ScrollState = {
  aboutScrollData: ""
};

const namespaced = true;

export const scroll: Module<ScrollState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
