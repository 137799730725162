<template>
  <div class="section-download-xid">
    <div class="section-header">
      <div
        class="section-header-text"
        v-html="getWordings.applicationSectionDownloadxIDTitle"
      ></div>
    </div>
    <div class="button-wrapper">
      <img
        @click="openURL('https://apps.apple.com/jp/app/xid/id1495147544')"
        class="download-btn"
        :src="require('@/assets/icons/app-store.svg')"
      />
      <img
        @click="openURL('https://play.google.com/store/apps/details?id=me.x.id')"
        class="download-btn"
        :src="require('@/assets/icons/google-play.svg')"
      />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component({
  inject: ["routes"]
})
export default class SectionHelpCenter extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  openURL(url: string) {
    window.open(url, "_blank");
  }
}
</script>

<style lang="scss" scoped>
.section-download-xid {
  @include layoutPadding;
  margin: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  // background-color: $gs8;
  @include mq(m) {
    padding-bottom: 40px;
    padding-top: 40px;
  }

  .section-header-text {
    @include titleFz(m);
    @include fw(medium);
    width: 100%;
    max-width: 800px;
    margin-top: 0;
    @include mq(m) {
      @include fz(l);
    }
  }

  ::v-deep(.section-header-text) {
    margin-top: 0;
  }

  .download-img {
    margin: auto;
    @include mq(m) {
      max-height: 150px;
    }
  }

  .button-wrapper {
    margin-top: 60px;
    @include flex(center, center);
    @include mq(l) {
      flex-wrap: nowrap;
      margin-top: 20px;
      row-gap: 20px;
    }
  }
  .download-btn {
    // margin-left: 40.2px;
    cursor: pointer;
    &:last-child {
      margin-left: 40.2px;
    }
    .nav-button {
      background: white !important;
    }
  }
}
</style>
