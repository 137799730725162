<template>
  <div class="other-feature-card">
    <img class="img" :src="image" />
    <div class="other-feature-card-content">
      <div class="title">{{ title }}</div>
      <div class="desc">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class OtherFeatureCardView extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "" }) private description!: string;
  @Prop({ type: String, default: "" }) private image!: string;
}
</script>

<style lang="scss" scoped>
.other-feature-card {
  text-align: center;
  @include mq(m) {
    @include flex(space-between, flex-start);
    column-gap: 10px;
    text-align: left;
  }
  .img {
    @include size(40px);
    border-radius: 10px;
    padding: 20px;
    border: 1px solid $gs7;
    margin: auto;
    background-color: $white;
    @include mq(m) {
      @include size(30px);
      padding: 5px;
      margin: 0;
    }
  }

  .other-feature-card-content {
    @include mq(l) {
      max-width: 235px;
    }
  }

  .title {
    @include titleFz(xs);
    @include fw(bold);
    line-height: 29px;
    margin: 10px 0 20px;
    @include mq(m) {
      @include fz(m);
      line-height: 20px;
      margin: 0 0 5px;
    }
  }

  .desc {
    @include fz(m);
    line-height: 29px;
    max-width: 340px;
    @include mq(m) {
      @include fz(s);
      line-height: 17px;
    }
  }
}
</style>
