<template>
  <div class="section-our-values">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.aboutSectionValuesSubtitle }}
      </div>
      <div class="section-header-text">
        {{ getWordings.aboutSectionValuesTitle }}
      </div>
    </div>
    <div class="our-values-content">
      <div class="our-values-item" v-for="(item, idx) in ourValues" :key="idx">
        <div class="title">{{ item.title }}</div>
        <div class="message">
          {{ item.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  name: "SectionOurValues",
  inject: ["routes"]
})
export default class SectionOurValues extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  ourValues = [];
  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getValues(contentRepository);
  }

  async getValues(contentRepository: any) {
    this.ourValues = (await contentRepository.getValuesList()).map((item: any): any => {
      return {
        title: item.attributes.title,
        description: item.attributes.description
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.section-our-values {
  @include size(100%, auto);
  @include layoutPadding();
  background: $gs8;
  padding-top: 100px;
  padding-bottom: 100px;

  .our-values-content {
    border-radius: 15px;
    border: 1px solid $gs7;
    background-color: $white;
    padding: 100px;
    margin-top: 60px;
    @include mq(l) {
      display: block;
      padding: 40px 20px;
    }
  }

  .our-values-item {
    height: 100px;
    @include flex(flex-start, flex-start);
    border-bottom: 1px solid $gs7;
    margin-bottom: 50px;
    @include mq(l) {
      display: block;
      height: auto;
      margin-bottom: 20px;
    }

    &:last-child {
      border-bottom: 0px solid $gs7;
      margin-bottom: 0px;
      height: auto;
    }

    .title {
      @include fz(xxl);
      @include fw(bold);
      width: 35%;
      text-align: left;
      color: $primary1;
      @include mq(l) {
        text-align: center;
        width: 100%;
        @include fz(m);
      }
    }

    .message {
      width: 65%;
      text-align: left;
      @include mq(l) {
        @include fz(s);
        margin: 20px 0 20px;
        width: 100%;
      }
      @include mq(m) {
        margin-top: 5px;
      }
    }
  }
}
</style>
