<template>
  <div class="feature-card">
    <img class="img" :src="image" />
    <div class="card-content">
      <div class="title" v-html="title"></div>
      <div class="desc">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class FeatureCardView extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "" }) private description!: string;
  @Prop({ type: String, default: "" }) private image!: string;
}
</script>

<style lang="scss" scoped>
.feature-card {
  height: 670px;
  width: 387px;
  position: relative;
  text-align: center;
  @include flex(center, center);
  @include mq(m) {
    height: auto;
    width: 100%;
  }

  .img {
    position: absolute;
    top: 0;
    z-index: 2;
    @include mq(m) {
      position: relative;
      // @include size(225px, 293.48px);
      border-radius: 20px;
    }
  }

  .card-content {
    position: absolute;
    z-index: 1;
    border: 1px solid $gs7;
    background-color: $white;
    border-radius: 15px;
    bottom: 0;
    padding: 90px 30px 73px;
    height: 100%;
    max-height: 265px;
    @include mq(m) {
      position: relative;
      box-sizing: border-box;
      height: auto;
      margin-top: -20px;
      max-height: unset;
      width: 100%;
      padding: 60px 20px 40px;
    }

    .title {
      @include titleFz(xs);
      @include fw(bold);
      line-height: 29px;
      height: 59px;
      margin-bottom: 20px;
      @include mq(m) {
        @include fz(m);
        line-height: 20px;
        margin-bottom: 5px;
        height: fit-content;
      }
    }

    .desc {
      @include fz(m);
      line-height: 27px;
      max-width: 327px;
      @include mq(m) {
        max-width: unset;
        @include fz(s);
      }
    }
  }
}
</style>
