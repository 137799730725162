import { MutationTree } from "vuex";
import setDefault from "./defaultState";
import { ScrollState } from "./types";

const mutations: MutationTree<ScrollState> = {
  setDefault(state: ScrollState) {
    Object.assign(state, setDefault());
  },

  setAboutScrollData(state: ScrollState, aboutScrollData) {
    state.aboutScrollData = aboutScrollData;
  }
};

export default mutations;
