<template>
  <div class="member-card">
    <img class="circle-img" :src="img" />
    <div class="member-card-content">
      <div class="name">{{ name }}</div>
      <div class="position">{{ position }}</div>
      <div class="detail-btn" @click="onClickDetail">
        <div>{{ getWordings.aboutSectionTeamMemberButton }}</div>
        <img class="button-icon" :src="require('@/assets/icons/nav-right-white.svg')" />
      </div>
    </div>
    <ProfilePopup
      :img="img"
      :name="name"
      :position="position"
      :detail="detail"
      :isDispplay="showPopup"
      @onHide="onClickDetail"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import ProfilePopup from "@/components/parts/ProfilePopup.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton,
    ProfilePopup
  },
  inject: ["routes"]
})
export default class MemberCardView extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  @Prop({ type: String, default: "" }) private img!: string;
  @Prop({ type: String, default: "" }) private name!: string;
  @Prop({ type: String, default: "" }) private position!: string;
  @Prop({ type: String, default: "" }) private detail!: string;
  showPopup = false;
  onClickDetail() {
    this.showPopup = !this.showPopup;
    this.$emit("onClickDetail");
  }
}
</script>

<style lang="scss" scoped>
.member-card {
  @include flex(center, center);
  height: auto;
  width: 285px;
  @include mq(m) {
    width: 152.5px;
  }

  .circle-img {
    @include size(150px, 150px);
    border-radius: 50%;
    margin-bottom: -75px;
    z-index: 1;
    object-fit: cover;
    box-sizing: border-box;
    @include mq(m) {
      margin-bottom: -50px;
      @include size(100px, 100px);
    }
  }

  .member-card-content {
    width: 100%;
    background-color: $white;
    padding-top: 95px;
    border: 1px solid $gs7;
    border-radius: 15px;
    text-align: center;
    @include mq(m) {
      position: relative;
      // margin-top: 50px;
      border-radius: 10px;
      padding-top: 70px;
    }

    .name {
      @include titleFz(xs);
      @include fw(medium);
      height: 29px;
      line-height: 29px;
      @include mq(m) {
        @include fz(m);
        height: 20px;
        line-height: 20px;
      }
    }

    .position {
      @include fz(m);
      @include fw(medium);
      margin: 6px 0 20px;
      height: 20px;
      line-height: 20px;
      color: $gs5;
      @include mq(m) {
        @include fz(s);
        height: 40px;
        line-height: 17px;
        margin: 5px 5px 20px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .detail-btn {
      @include flex(center, center);
      @include size(calc(100% + 2px), 44px);
      margin-left: -1px;
      margin-bottom: -1px;
      cursor: pointer;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      background-color: $primary1;
      :first-child {
        margin-right: 10px;
        @include titleFz(xxs);
        @include fw(bold);
        line-height: 24px;
        color: $white;
      }
      @include mq(m) {
        @include size(calc(100% + 2px), 25px);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        :first-child {
          @include fz(xs);
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
