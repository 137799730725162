<template>
  <div class="section-why-xid">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.governmentSectionWhyXIDSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.governmentSectionWhyXIDTitle"></div>
    </div>

    <div class="xid-description">
      {{ getWordings.governmentSectionWhyXIDDescription }}
    </div>

    <div class="partner-card-container">
      <div class="partner-card">
        <div class="title" v-html="getWordings.governmentSectionWhyXIDR1Title"></div>
        <div class="desc">
          {{ getWordings.governmentSectionWhyXIDR1Description }}
        </div>
      </div>
      <div class="partner-card">
        <div class="title" v-html="getWordings.governmentSectionWhyXIDR2Title"></div>
        <div class="desc">
          {{ getWordings.governmentSectionWhyXIDR2Description }}
        </div>
      </div>
    </div>
    <div class="partner-card-container">
      <div class="partner-card">
        <div class="title" v-html="getWordings.governmentSectionWhyXIDR3Title"></div>
        <div class="desc">
          {{ getWordings.governmentSectionWhyXIDR3Description }}
        </div>
      </div>
      <div class="partner-card">
        <div class="title" v-html="getWordings.governmentSectionWhyXIDR4Title"></div>
        <div class="desc">
          {{ getWordings.governmentSectionWhyXIDR4Description }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Getter } from "vuex-class";

@Component({
  components: {}
})
export default class SectionWhyXid extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-why-xid {
  @include layoutPadding;
  padding-top: 100px;
  padding-bottom: 50px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .xid-description {
    margin: auto;
    max-width: 800px;
    margin-bottom: 60px;
    margin-top: 60px;
    @include mq(l) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  .partner-card-container {
    @include flex(space-between, center);
    margin-bottom: 50px;
    @include mq(l) {
      margin-bottom: 0px;
    }

    .partner-card {
      border: 1px solid $gs7;
      border-radius: $radius15;
      padding: 60px 60px 99px;
      background: $gs1;
      box-sizing: border-box;
      height: 350px;
      width: calc(50% - 25px);
      @include mq(l) {
        margin-top: 20px;
        height: unset;
        padding: 40px 20px 40px;
        width: 100%;
      }
      .title {
        @include titleFz(xs);
        @include fw(heavy);
        @include mq(l) {
          @include fz(m);
        }
      }
      .desc {
        @include fz(m);
        max-width: 455px;
        margin-top: 30px;
        height: 132px;
        @include mq(l) {
          margin-top: 5px;
          height: unset;
          max-width: unset;
          @include fz(s);
        }
      }
    }
  }

  .industry-partner {
    border-radius: $radius15;
    box-shadow: 0px 0px 20px 0px $boxShadow;
    padding: 60px 60px 30px;

    .title {
      @include titleFz(s);
      @include fw(heavy);
      height: 35x;
      line-height: 35px;
    }
    .desc {
      @include fz(m);
      margin-top: 30px;
      margin-bottom: 60px;
    }
  }

  .industry-partner-list {
    @include flex(space-between, center);
  }

  .industry-partner-item {
    margin-bottom: 30px;
  }
}
</style>
