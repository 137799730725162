<template>
  <div class="section-team-member">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.aboutSectionTeamMemberSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.aboutSectionTeamMemberTitle }}</div>
    </div>
    <div class="items-wrapper">
      <MemberCardView
        class="part-item"
        v-for="(item, idx) in members"
        :key="idx"
        :img="item.image"
        :name="item.name"
        :position="item.role"
        :detail="item.description"
        @onClickDetail="onClickDetail"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import MemberCardView from "@/components/parts/MemberCardView.vue";
import ProfilePopup from "@/components/parts/ProfilePopup.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    MemberCardView,
    ProfilePopup,
    ActionButton
  }
})
export default class SectionTeamMember extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  members = [];
  showPopup = false;

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getMembers(contentRepository);
  }

  onClickDetail() {
    this.showPopup = !this.showPopup;
  }

  async getMembers(contentRepository: any): Promise<void> {
    this.members = (await contentRepository.getMembersList()).map((item: any): any => {
      const cover = item.attributes.cover.data.attributes;
      return {
        name: item.attributes.name,
        image: cover.url,
        role: item.attributes.role,
        description: item.attributes.description.replace(/\n/g, "</br>")
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.section-team-member {
  @include size(100%, auto);
  @include layoutPadding();
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  background: $gs8;
  @include mq(m) {
    padding-top: 40px;
  }

  .items-wrapper {
    @include flex(center, center);
    column-gap: 20px;
    row-gap: 60px;
    margin-top: 60px;
    @include mq(m) {
      @include flex(space-between, center);
      column-gap: 10px;
      row-gap: 20px;
      margin-top: 40px;
    }
  }

  .button-wrapper {
    margin-top: 60px;
    @include mq(m) {
      margin-top: 20px;
    }
    .action-button {
      @include flex(center, center);
      margin: auto;
      width: 100%;
    }
  }
}
</style>
