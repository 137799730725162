<template>
  <div class="news-card-view">
    <img class="news-card-img" :alt="title" :src="image" />
    <div class="content-card">
      <div class="tags" v-if="tag">
        <Tag class="tag" :tagName="tag" />
      </div>
      <div class="content" @click="goToDetail(id)">
        <img v-if="icon" :src="icon" />
        <div>
          <div class="card-view-title">{{ title }}</div>
          <div class="card-view-description" v-html="formattedDescription"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Tag from "@/components/controls/Tag.vue";
import Routes from "@/router/routes";
import { dateReplaceHyphen } from "@/lib/helpers/datetime";

@Component({
  components: {
    Tag
  },
  inject: ["routes"]
})
export default class NewsCardView extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String }) tag!: string;
  @Prop({ type: String }) image!: string;
  @Prop({ type: String }) icon!: string;
  @Prop({ type: String }) description!: string;
  @Prop({ type: Number, default: -1 }) id!: number;
  @Prop({ type: Number, default: -1 }) index!: number;

  get formattedDescription(): string {
    return dateReplaceHyphen(this.description);
  }

  goToDetail(id: string) {
    this.$router.push({
      name: Routes.news.detail.name,
      params: { id: `${id}` }
    });
  }
}
</script>

<style lang="scss" scoped>
.news-card-view {
  @include flex(center, center);
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid $gs7;
  @include mq(m) {
    // display: block;
    padding-bottom: 20px;
  }

  .news-card-img {
    @include size(300px, 150px);
    border: 1px solid $gs2;
    border-radius: $radius15;
    margin-right: 100px;
    object-fit: cover;
    @include mq(l) {
      @include size(315px, 157.5px);
      margin-bottom: 20.5px;
      margin-right: unset;
    }
    @include mq(m) {
      // width: 100%;
    }
  }

  .content-card {
    cursor: pointer;
    width: 700px;
    @include mq(l) {
      width: 100%;
    }
  }

  .content {
    @include flex(flex-start, flex-start);
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 1 1 auto;
    position: relative;

    > img {
      height: 30px;
      margin-right: 10px;
    }

    > div {
      @include flex(flex-start, flex-start);
      flex-direction: row;
      flex: 1;
    }
  }

  .card-view-title {
    @include fz(xxl);
    @include fw(medium);
    margin: 20px 0;
    text-align: left;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    max-lines: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.5em;
    width: 100%;
    @include mq(m) {
      @include fz(m);
      margin: 10px 0;
    }
  }

  .card-view-description {
    @include fz(m);
    @include fw(medium);
    max-lines: 1;
    color: $gs3;
    @include mq(m) {
      @include fz(xs);
    }
  }

  .tags {
    @include flex(flex-start, center);
    width: 100%;
  }
  .tag {
    background-color: $gs8;
    border: 1px solid $gs7;
    margin-right: 10px;
  }
}
</style>
