export default {
  home: {
    name: "Home",
    url: "/home",
    meta: {
      title: "xID株式会社（クロスアイディ）│ マイナンバーカードを、スマートに。",
      meta: [
        {
          name: "description",
          content:
            "xID（クロスアイディ）は、マイナンバーカード・デジタルIDを活用した自治体や企業の課題解決・新規事業創出を総合的に支援するGovtechスタートアップです。"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID（クロスアイディ）│ マイナンバーカードを、スマートに。"
        },
        {
          property: "og:description",
          content:
            "「信用コストの低いデジタル社会を実現する」。xID（クロスアイディ）は、マイナンバーカード・デジタルIDを活用した自治体や企業の課題解決・新規事業創出を総合的に支援するGovtechスタートアップです。"
        },
        {
          property: "og:url",
          content: "https://xid.inc/home"
        }
      ]
    }
  },
  about: {
    name: "About",
    url: "/about",
    meta: {
      title: "xID(クロスアイディ)｜会社概要",
      meta: [
        {
          name: "description",
          content:
            "会社名, xID株式会社. 代表者, 日下 光. 設立, 2012年5月28日. 所在地, 東京都千代田区永田町2–14-3 東急不動産赤坂ビル6階. 資本金, 6,320万円(2020年7月末時点)."
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜会社概要"
        },
        {
          property: "og:description",
          content: "xID株式会社の会社概要ページです。弊社のミッション・メンバーを紹介します。"
        },
        {
          property: "og:url",
          content: "https://xid.inc/about"
        }
      ]
    }
  },
  products: {
    name: "Products",
    url: "/products",
    meta: {
      title: "xID(クロスアイディ)｜製品",
      meta: [
        {
          name: "description",
          content:
            "xID株式会社は、マイナンバーカードを活用して誰でも無料で作成できるデジタルIDアプリのxID、電子契約や本人確認を簡単に実装できるxID API、一生無料・完全無料の電子契約サービスe-signを提供するGovTech企業です。"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜製品"
        },
        {
          property: "og:description",
          content: "xID株式会社のプロダクトページです。弊社が提供しているプロダクトを紹介します。"
        },
        {
          property: "og:url",
          content: "https://xid.inc/products"
        }
      ]
    }
  },
  news: {
    name: "News",
    url: "/news",
    meta: {
      title: "xID(クロスアイディ)｜ニュース",
      meta: [
        {
          name: "description",
          content:
            "プレスリリースや各種取り組みについてのお知らせなど、xIDの最新情報をお届けしています。"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜ニュース"
        },
        {
          property: "og:description",
          content: "xID株式会社のお知らせページです。弊社の最新情報を紹介します。"
        },
        {
          property: "og:url",
          content: "https://xid.inc/news"
        }
      ]
    },

    detail: {
      name: "NewsDetail",
      url: "/news/:id",
      meta: {
        title: "",
        metaTags: []
      }
    }
  },
  contactUs: {
    name: "ContactUs",
    url: "/contact-us",
    meta: {
      title: "xID(クロスアイディ)｜お問い合わせ",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  },

  partner: {
    name: "Partner",
    url: "/partner",
    meta: {
      title: "Partner｜パートナー",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  },

  smartpost: {
    name: "SmartPOST",
    url: "/smartpost",
    meta: {
      title: "SmartPOST",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  },

  gov: {
    name: "Government",
    url: "/government",
    meta: {
      title: "Government | 政府機関・自治体向け",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  },

  application: {
    name: "Application",
    url: "/application",
    meta: {
      title: "Application | xIDアプリ",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  },
  dev: {
    name: "Development",
    url: "/dev",
    meta: {
      title: "Development | xID API",
      meta: [
        {
          name: "description",
          content:
            "xIDに関するご意見やご質問、API連携のご相談等、xID株式会社へのお問い合わせはこちら"
        },
        {
          name: "keywords",
          content: "デジタル身分証,デジタルID,マイナンバーカード,エストニア,公的個人認証,電子署名"
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:image",
          content: "https://public.xid.inc/og.png"
        },
        {
          property: "og:title",
          content: "xID(クロスアイディ)｜お問い合わせ"
        },
        {
          property: "og:description",
          content: "xIDの製品、またはxID株式会社に関するお問い合わせはこちら"
        },
        {
          property: "og:url",
          content: "https://xid.inc/contact-us"
        }
      ]
    }
  }
};
