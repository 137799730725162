<template>
  <div class="banners-view">
    <div class="left-banners">
      <div class="title" v-html="getWordings.smartpostBannerTitle"></div>
      <div class="description" v-html="getWordings.smartpostBannerDescription"></div>
      <div class="button-wrapper">
        <LinkButton
          class="link-button"
          :wordingKey="'devBannerContactButton'"
          :hrefLink="contactUsDomain"
          :isShowIcon="false"
        />
        <LinkButton
          :isWhiteButton="true"
          class="link-button download-btn"
          :wordingKey="'devBannerDownloadButton'"
          :hrefLink="downloadDocsURL"
          :isShowIcon="false"
          :openNewTab="true"
        />
      </div>
    </div>
    <div class="right-banners">
      <img :src="smartpostAnimation + '?r=' + timestamp" />
      <div class="image-background"></div>
    </div>
    <div class="smartpost-triangle-mobile">
      <img :src="require('@/assets/images/triangle-smartpost.svg')" />
    </div>
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import LinkButton from "@/components/controls/LinkButton.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import smartpostAnimation from "@/assets/animation/smartpost.svg";
import { Getter } from "vuex-class";

@Component({
  name: "SmartPOSTBannersView",
  components: {
    LinkButton
  }
})
export default class SmartPOSTBannersView extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  @Prop({ type: String }) private timestamp!: string;
  smartpostAnimation: string = smartpostAnimation;
  contactUsDomain = process.env.VUE_APP_XID_CONTACT_URL as string;
  downloadDocsURL = process.env.VUE_APP_XID_DOCS_DOWNLOAD_URL as string;
}
</script>

<style lang="scss" scoped>
.banners-view {
  @include layoutPadding();
  @include flex(left, center);
  flex-wrap: nowrap;
  position: relative;
  padding-right: 0;
  background-color: $gs8;
  @include mq(l) {
    @include flex(center, center);
  }
  .smartpost-triangle-mobile {
    display: none;
    img {
      width: 100vw;
    }
    @include mq(l) {
      display: block;
      position: absolute;
      bottom: 0;
    }
  }

  .left-banners {
    text-align: left;
    max-width: 600px;
    width: 100%;
    @include mq(l) {
      text-align: center;
      width: 100%;
      max-width: unset;
    }
  }

  .right-banners {
    position: relative;
    width: 100%;
    > img {
      position: absolute;
      z-index: 1;
      top: 50px;
      left: 150px;
      @include mq(l) {
        display: inline-block;
        width: 75%;
        position: relative;
        top: 4px;
        left: unset;
        margin-top: 20px;
      }
    }
  }

  .image {
    @include size(500px, 500px);
    background-color: $gs6;
    @include mq(l) {
      width: 100%;
      height: 250px;
    }
  }

  .image-background {
    @include size(100%, 500px);
    background-image: linear-gradient($sp-color2, $sp-color1);
    position: relative;
    margin-left: 340.5px;
    @include mq(l) {
      display: none;
    }
  }
  .image-background:before {
    content: "";
    @include size(353.54px);
    border-bottom-left-radius: 15px;
    background-image: linear-gradient(135deg, $sp-color2, $sp-color1);
    transform: rotate(45deg);
    position: absolute;
    left: -178px;
    top: 73px;
  }

  .title {
    @include titleFz(xl);
    @include mq(l) {
      margin-top: 40px;
      text-align: center;
      @include titleFz(xs);
    }
    @include fw(heavy);
    line-height: 60px;
    margin-bottom: 20px;
  }

  .description {
    @include titleFz(xs);
    @include mq(l) {
      @include fz(m);
    }
    line-height: 40px;
    // @include fw(heavy);
  }

  .button-wrapper {
    margin-top: 60px;
    @include flex(left, center);
    @include mq(l) {
      width: 100%;
      margin-top: 20px;
      display: inline-table;
    }
  }
  .download-btn {
    margin-left: 30px;
    @include mq(l) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
}
</style>
