<template>
  <div class="section-contactus">
    <div class="footer-title" v-html="getWordings.homeSectionContactUsTitle"></div>
    <LinkButton
      class="link-button"
      :wordingKey="'homeSectionContactUsButton'"
      :hrefLink="routes.contactUs.url"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionContactUs extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-contactus {
  @include size(100%, auto);
  @include titleFz(m);
  @include fw(medium);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  background: $white;

  .footer-title {
    width: 100%;
    max-width: 800px;
    margin-bottom: 50px;
  }

  .button-wrapper {
    @include flex(center, center);
    @include mq(m) {
      width: 100%;
    }
  }

  @include mq(m) {
    @include fz(l);
    padding-top: 40px;
    padding-bottom: 40px;

    .footer-title {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
</style>
