<template>
  <validationProvider class="validate-textfield" v-slot="{ errors }" :rules="rules">
    <div class="wrapper">
      <div class="input-area" :class="{ error: errors.length > 0 }">
        <input
          v-if="!isMultiline"
          :type="type"
          :placeholder="placeholder"
          v-model="inputValue"
          @input="valueChanged"
        />
        <textarea v-else :placeholder="placeholder" v-model="inputValue" @input="valueChanged" />
      </div>
      <div class="error-area" v-if="errors.length > 0">
        <slot :errorString="errors ? getMessage(errors[0]) : ''"></slot>
      </div>
    </div>
  </validationProvider>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model, Watch, Emit } from "vue-property-decorator";
import { ValidationProvider as validationProvider } from "vee-validate";

@Component({
  name: "ValidationTextField",
  components: {
    validationProvider
  }
})
export default class ValidationTextField extends Vue {
  // [Model]
  @Model("input", { type: String }) value!: string;

  // [Prop]
  @Prop({ type: Boolean, default: false }) isMultiline!: boolean;
  @Prop({ type: String, default: "text" }) type!: string;
  @Prop() message!: string | Record<string, string>;
  @Prop({ type: String, default: "" }) placeholder!: string;
  @Prop({ default: "" }) rules!: any;

  // [Watch]
  @Watch("value")
  input() {
    this.inputValue = this.value;
  }

  // [Data]
  inputValue = "";
  // isErrorDisplay = false;

  // [Methods]
  @Emit("input")
  valueChanged() {
    return this.inputValue;
  }

  getMessage(errorName: string) {
    if (typeof this.message === "string") {
      return this.message;
    }
    if (typeof this.message === "object" && this.message[errorName]) {
      return (this.message as Record<string, string>)[errorName];
    }
    return errorName;
  }

  // [Life cycle]
}
</script>
<style lang="scss" scoped>
.validate-textfield {
  .wrapper {
    height: 100%;
    width: 100%;
    position: relative;

    .input-area {
      height: 100%;
      width: 100%;
      z-index: -1;
      border-bottom: 1px solid $gs2;
      @include flex(flex-start, center);

      &.error {
        border-bottom: 1px solid $secondary3;
      }
    }
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0px 0px 5px;

    &::-webkit-input-placeholder {
      /* Edge */
      color: rgba($color: $gs4, $alpha: 0.5);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba($color: $gs4, $alpha: 0.5);
    }

    &::placeholder {
      color: rgba($color: $gs4, $alpha: 0.5);
    }
  }

  textarea {
    height: 100%;
    width: 100%;
    padding: 10px 0;
    resize: none;
    box-sizing: border-box;

    &::-webkit-input-placeholder {
      /* Edge */
      color: rgba($color: $gs4, $alpha: 0.5);
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba($color: $gs4, $alpha: 0.5);
    }

    &::placeholder {
      color: rgba($color: $gs4, $alpha: 0.5);
    }
  }

  .error-area {
    width: 100%;
    height: 100%;
    top: calc(100% + 5px);
    position: absolute;
    z-index: 9;
    max-height: calc(min(100%, 50px));
    opacity: 1;
    overflow: hidden;
  }

  // .error-enter-active, .error-leave-active {
  //   transition: all .2s ease-out;
  //   max-height: calc(min(100%, 50px));
  //   opacity: 1;
  //   overflow: hidden;
  // }
  // .error-enter, .error-leave-to /* .fade-leave-active below version 2.1.8 */ {
  //   max-height: 5px;
  //   opacity: 0;
  // }
}
</style>
