<template>
  <div class="about">
    <PartnerBannersView id="partnership" :timestamp="timestamp" />
    <SectionWhyPartnership />
    <SectionOurPartner id="ourpartner" />
    <SectionClientVoice />
    <SectionBecomePartner />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Watch, Prop } from "vue-property-decorator";
import PartnerBannersView from "@/components/parts/PartnerBannersView.vue";
import SectionWhyPartnership from "@/components/parts/SectionWhyPartnership.vue";
import SectionOurPartner from "@/components/parts/SectionOurPartner.vue";
import SectionClientVoice from "@/components/parts/SectionClientVoice.vue";
import SectionBecomePartner from "@/components/parts/SectionBecomePartner.vue";
import routes from "@/router/routes";
import { Action, Getter } from "vuex-class";
import Vue from "vue";
import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

@Component({
  components: {
    PartnerBannersView,
    SectionWhyPartnership,
    SectionOurPartner,
    SectionClientVoice,
    SectionBecomePartner
  },
  metaInfo: routes.partner.meta
})
export default class Partner extends Vue {
  @Prop({ type: String }) private timestamp!: string;
  @Action("scroll/setAboutScrollData") setAboutScrollData!: Function;
  @Getter("scroll/getAboutScroll") getAboutScroll: any;

  mounted() {
    if (this.getAboutScroll) {
      VueScrollTo.scrollTo("#" + this.getAboutScroll);
      this.setAboutScrollData("");
    }
  }
  @Watch("getAboutScroll")
  autoScroll() {
    if (this.getAboutScroll) {
      VueScrollTo.scrollTo("#" + this.getAboutScroll);
    }
    this.setAboutScrollData("");
  }
}
</script>
