<template>
  <div class="section-services">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionServiceSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.applicationSectionServiceTitle }}</div>
    </div>
    <div class="items-wrapper">
      <ServiceCardView
        class="part-item"
        v-for="(item, idx) in services"
        :key="idx"
        :title="item.name"
        :name="item.description"
        :img="item.image"
        :disable="item.link == ''"
        @onClickDetail="openURL(item.link)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ServiceCardView from "@/components/parts/ServiceCardView.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
// import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    ServiceCardView
  }
})
export default class SectionServices extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  services = [];

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getServices(contentRepository);
  }

  async getServices(contentRepository: any) {
    this.services = (await contentRepository.getServicesList()).map((item: any): any => {
      const cover = item.attributes.cover ? item.attributes.cover.data.attributes : "";
      return {
        name: item.attributes.name,
        description: item.attributes.description,
        link: item.attributes.link,
        image: cover.url
      };
    });
  }

  openURL(url: string) {
    window.open(url, "_blank");
  }
}
</script>

<style lang="scss" scoped>
.section-services {
  @include size(100%, auto);
  @include layoutPadding();
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
  // background-color: $gs8;
  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .items-wrapper {
    @include flex(space-between, center);
    column-gap: 20px;
    row-gap: 40px;
    margin-top: 60px;
    @include mq(l) {
      margin-top: 20px;
      row-gap: 20px;
      column-gap: 10px;
    }
  }

  ::v-deep .part-item {
    @include mq(m) {
      height: auto;
    }
  }
}
</style>
