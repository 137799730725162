import { MutationTree } from "vuex";
import setDefault from "./defaultState";
import { TextState } from "./types";

const mutations: MutationTree<TextState> = {
  setDefault(state: TextState) {
    Object.assign(state, setDefault());
  },

  setWordingsData(state: TextState, wordingsData: any) {
    state.wordingsData = wordingsData;
  }
};

export default mutations;
