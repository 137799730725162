import { render, staticRenderFns } from "./SectionClientGovVoice.vue?vue&type=template&id=720fdb61&scoped=true"
import script from "./SectionClientGovVoice.vue?vue&type=script&lang=ts"
export * from "./SectionClientGovVoice.vue?vue&type=script&lang=ts"
import style0 from "./SectionClientGovVoice.vue?vue&type=style&index=0&id=720fdb61&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "720fdb61",
  null
  
)

export default component.exports