<template>
  <div class="xid-can-card">
    <div class="check-content">
      <span class="content">
        <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
        {{ title }}
      </span>
    </div>
    <div class="desc">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class XidCanDoCardView extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "" }) private description!: string;
}
</script>

<style lang="scss" scoped>
.xid-can-card {
  text-align: center;
  @include mq(m) {
    width: 100%;
  }
  .check-content {
    display: flex;
    font-weight: bold;
    @include mq(l) {
      width: 100%;
    }
    .content {
      @include flex(left);
      @include fz(l);
      @include mq(l) {
        @include fz(s);
        width: 100%;
      }
      line-height: 30px;
    }
    .check-mark {
      margin-right: 10px;
    }
  }
  .img {
    margin: auto;
  }
  .title {
    @include titleFz(xs);
    @include fw(medium);
    line-height: 29px;
    margin: 20px 0 30px;
  }

  .desc {
    text-align: left;
    @include fz(m);
    line-height: 29px;
    max-width: 327px;
    @include mq(l) {
      @include fz(s);
      margin-top: 10px;
    }
    @include mq(m) {
      max-width: 100%;
      width: 100%;
      margin-top: 5px;
    }
  }
}
</style>
