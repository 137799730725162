import Repository from "./Repository";

// string url
const resourceWordings = "api/inc-xid-wordings";
const resourceCollaborationFields = "api/inc-xid-collaboration-fields";
const resourcePartners = "api/inc-xid-partners";
const resourceGovernments = "api/inc-xid-governments";
const resourcePosts = "api/inc-xid-posts";
const resourceMinDigi = "api/inc-xid-mindigi-posts";
const resourceValues = "api/inc-xid-values";
const resourceMembers = "api/inc-xid-members";
const resourceServices = "api/inc-xid-services";
const resourceConnections = "api/inc-xid-connections";
const resourceFeedbacks = "api/inc-xid-feedbacks";
const resourceResources = "api/inc-xid-resources";
const resourceAssociations = "api/inc-xid-associations";
const resourceCompanyProfiles = "api/inc-xid-company-profiles";

const ContentRepository = () => ({
  // WORDINGS
  getWordingsList: async () => {
    const response = await Repository.get(`${resourceWordings}?pagination[pageSize]=300`);
    return response.data.data;
  },

  //COLLABORATION FIELDS
  getCollaborationFieldsList: async () => {
    const response = await Repository.get(`${resourceCollaborationFields}`);
    return response.data.data;
  },

  // PARTNERS
  getPartnersList: async () => {
    const response = await Repository.get(`${resourcePartners}?populate=*&sort[0]=order:asc`);
    const data = response.data.data;
    data.sort((a: any, b: any) => a.attributes.ruby.localeCompare(b.attributes.ruby), "ja");
    return data;
  },

  // GOVERNMENTS
  getGovernmentsList: async () => {
    const response = await Repository.get(`${resourceGovernments}?populate=*`);
    return response.data.data;
  },

  // POSTS
  getPostsList: async () => {
    const response = await Repository.get(`${resourcePosts}?populate=*`);
    return response.data.data;
  },
  getPostsByKey: async (id: string) => {
    const url = `${resourcePosts}/?filters[id][$eq]=${id}&populate=*`;
    if (process.env.NODE_ENV === "development") {
      const response = await Repository.get(url + `&publicationState=preview`);
      return response.data.data;
    } else {
      const response = await Repository.get(url);
      return response.data.data;
    }
  },
  getPostsWithPaging: async (page: string, pageSize: string, tagFilter: string) => {
    let url = `${resourcePosts}?pagination[page]=${page}`;
    url += `&pagination[pageSize]=${pageSize}`;
    url += tagFilter ? `&filters[tags][$eq]=${tagFilter}` : "";
    url += `&sort[0]=date:desc`;
    url += `&populate=*`;
    if (process.env.NODE_ENV === "development") {
      const response = await Repository.get(url + `&publicationState=preview`);
      return response.data.data;
    } else {
      const response = await Repository.get(url);
      return response.data.data;
    }
  },
  getPostsWithOffset: async (start: string, limit: string, tagFilter: string) => {
    let url = `${resourcePosts}?pagination[start]=${start}`;
    url += `&pagination[limit]=${limit}`;
    url += tagFilter ? `&filters[tags][$eq]=${tagFilter}` : "";
    url += `&sort[0]=date:desc`;
    url += `&populate=*`;
    if (process.env.NODE_ENV === "development") {
      const response = await Repository.get(url + `&publicationState=preview`);
      return response.data.data;
    } else {
      const response = await Repository.get(url);
      return response.data.data;
    }
  },
  //countNews: async () => {
  //  const { data } = await Repository.get(`${resourceNews}/${resourceCount}`);
  //  return data;
  //},

  //MINDIGI
  getMinDigiPaging: async (page: string, pageSize: string) => {
    let url = `${resourceMinDigi}?pagination[page]=${page}`;
    url += `&pagination[pageSize]=${pageSize}`;
    url += `&sort[0]=date:desc`;
    if (process.env.NODE_ENV === "development") {
      const response = await Repository.get(url + `&publicationState=preview`);
      return response.data.data;
    } else {
      const response = await Repository.get(url);
      return response.data.data;
    }
  },

  // VALUES
  getValuesList: async () => {
    const response = await Repository.get(`${resourceValues}?sort[0]=id:asc`);
    return response.data.data;
  },

  // MEMBERS
  getMembersList: async () => {
    const response = await Repository.get(`${resourceMembers}?populate=*&sort[0]=order:asc`);
    return response.data.data;
  },

  // ASSOCIATIONS
  getAssociationsList: async () => {
    const response = await Repository.get(`${resourceAssociations}?populate=*&sort[0]=order:asc`);
    return response.data.data;
  },

  // SERVICES
  getServicesList: async () => {
    const response = await Repository.get(`${resourceServices}?populate=*&sort[0]=order:asc`);
    return response.data.data;
  },

  // CONNECTIONS
  getConnectionsList: async () => {
    const response = await Repository.get(`${resourceConnections}?sort[0]=order:asc&populate=*`);
    return response.data.data;
  },

  // FEEDBACKS
  getFeedbacksListByType: async (type: string) => {
    let url = `${resourceFeedbacks}?sort[0]=order:asc`;
    url += `&filters[type][$eq]=${type}`;
    url += `&populate=*`;
    const response = await Repository.get(url);
    return response.data.data;
  },

  // RESOURCES
  getResourcesListByType: async (type: string) => {
    let url = `${resourceResources}`;
    url += `?filters[type][$eq]=${type}`;
    url += `&populate=*`;
    const response = await Repository.get(url);
    return response.data.data;
  },

  // COMPANY PROFILES
  getCompanyProfilesList: async () => {
    const response = await Repository.get(`${resourceCompanyProfiles}`);
    return response.data.data;
  }
  //getCompanyProfilesByKey: async (id: string) => {
  //  const { data } = await Repository.get(`${resourceCompanyProfiles}/${id}`);
  //  return data;
  //},
  //getCompanyProfilesByquery: async (payload: any) => {
  //  const { data } = await Repository.get(`${resourceCompanyProfiles}`, payload);
  //  return data;
  //},
  //countCompanyProfiles: async () => {
  //  const { data } = await Repository.get(`${resourceCompanyProfiles}/${resourceCount}`);
  //  return data;
  //},
  //
  //getText: async () => {
  //  const { data } = await Repository.get(`${resourceText}`);
  //  return data;
  //}
});

export default ContentRepository;
