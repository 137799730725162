var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":_vm.hrefLink,"target":_vm.target}},[_c('div',{staticClass:"nav-button link-button",class:{
      link: _vm.isLinkButton,
      button: !_vm.isLinkButton,
      white: _vm.isWhiteButton,
      'link-button-underline': _vm.linkUnderline,
      'left-nav': _vm.isNavLeft
    }},[_vm._v(" "+_vm._s(_vm.isShowText ? (_vm.useLocalWording ? _vm.$t(_vm.wordingKey) : _vm.getWordings[_vm.wordingKey]) : "")+" "),(_vm.isShowIcon)?_c('img',{attrs:{"src":_vm.icon}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }