import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";
import { ValidationRule } from "vee-validate/dist/types/types";

// Install rules
extend("required", {
  message: () => "required",
  computesRequired: true,
  validate: required.validate,
  params: required.params
} as ValidationRule);

extend("email", {
  message: () => "format_error",
  computesRequired: true,
  validate: email.validate,
  params: email.params
} as ValidationRule);

extend("regex", {
  message: () => "format_error",
  params: regex.params,
  validate: regex.validate
} as ValidationRule);

// URL validation rule
extend("url", {
  message: () => "format_error",
  validate: url => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(url);
  }
} as ValidationRule);
