<template>
  <div class="section-contactus">
    <div class="footer-title" v-html="getWordings.devSectionContactContactDescription"></div>
    <div class="button-wrapper">
      <LinkButton
        class="link-button"
        :wordingKey="'devSectionContactContactButton'"
        :hrefLink="contactUsDomain"
        :isShowIcon="false"
      />
      <LinkButton
        class="link-button"
        :useLocalWording="true"
        :openNewTab="true"
        :wordingKey="'btn_api_docs'"
        :hrefLink="apiDocsDomain"
        :isShowIcon="false"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionDevContact extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  contactUsDomain = process.env.VUE_APP_XID_CONTACT_URL as string;
  apiDocsDomain = process.env.VUE_APP_API_DOCS_URL as string;
}
</script>

<style lang="scss" scoped>
.section-contactus {
  @include size(100%, auto);
  @include titleFz(m);
  @include fw(medium);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  background: $gs8;

  .footer-title {
    width: 100%;
    margin-bottom: 60px;
  }

  .button-wrapper {
    @include flex(center, center);
    gap: 40px;
    @include mq(l) {
      gap: 20px;
      width: 100%;
    }
  }

  @include mq(m) {
    @include fz(l);
    padding-top: 40px;
    padding-bottom: 40px;

    .footer-title {
      width: 100%;
      margin-bottom: 30px;
    }
  }
}
</style>
