import ContentRepository from "./ContentRepository";

interface Repositories {
  [key: string]: Function;
}

const repositories = {
  contents: ContentRepository
} as Repositories;

const RepositoryFactory = {
  create: (name: string) => repositories[name]()
};

export default RepositoryFactory;
