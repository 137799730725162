<template>
  <div class="header page-header">
    <router-link to="/"><img class="logo" :src="logo"/></router-link>
    <div class="header-menu" :class="{ open: mobMenuIsOpen }">
      <div v-for="menu in menus" :key="menu.i18nName">
        <div @click="onClickMobMenuItem" v-if="menu.i18nName != 'header_xid_solution'">
          <router-link
            :to="{ name: menu.name, params: { timestamp: Date.now().toString() } }"
            v-if="menu.i18nName != 'header_recruit' && menu.i18nName != 'header_blog'"
          >
            {{ $t(menu.i18nName) }}
          </router-link>
          <a :href="menu.link" target="_blank" v-else>{{ $t(menu.i18nName) }}</a>
        </div>

        <div v-else>
          <NavDropdown
            class="nav-dropdown"
            :items="dropdownSolutionItems"
            :navName="$t(menu.i18nName)"
            :isActive="
              $route.name == 'Application' ||
                $route.name == 'SmartPOST' ||
                $route.name == 'Development'
            "
            @selectedChanged="onSelect"
          />

          <div class="nav-dropdown-mob">
            <div class="dropdown-title" @click="mobAppDropdownIsOpen = !mobAppDropdownIsOpen">
              <span
                class="text"
                :class="{
                  active:
                    $route.name == 'Application' ||
                    $route.name == 'SmartPOST' ||
                    $route.name == 'Development'
                }"
              >
                {{ $t(menu.i18nName) }}
              </span>
              <span class="icon">
                <img
                  :class="{ 'expand-icon': mobAppDropdownIsOpen }"
                  :src="require('@/assets/icons/icon-pointer.svg')"
                />
              </span>
            </div>
            <div class="dropdown-selection-area" v-show="mobAppDropdownIsOpen">
              <div
                class="dropdown-item"
                v-for="(item, idx) in dropdownSolutionItems"
                :key="idx"
                @click="onSelect(item)"
              >
                <div class="title" v-if="item.title">{{ item.title }}</div>
                <div class="name" :class="{ active: $route.path == item.link }">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="hamburger mob"
      :class="{ open: mobMenuIsOpen }"
      @click="mobMenuIsOpen = !mobMenuIsOpen"
    >
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Routes from "@/router/routes";
import NavDropdown from "@/components/controls/NavDropdown.vue";
import logo from "@/assets/logos/xid-logo.svg";

@Component({
  components: {
    NavDropdown
  },
  inject: ["routes"]
})
export default class PageHeader extends Vue {
  /// [resources]
  logo = logo;
  mobMenuIsOpen = false;
  mobAppDropdownIsOpen = false;
  mobRrcDropdownIsOpen = false;

  /// [dropdown data]
  dropdownSolutionItems = [
    {
      title: this.$t("header_for_end_user"),
      name: this.$t("header_for_end_user_app"),
      link: Routes.application.url,
      routeName: "Application"
    },
    {
      title: this.$t("header_for_dev"),
      name: this.$t("header_for_dev_api"),
      link: Routes.dev.url,
      routeName: "Development"
    },
    {
      title: this.$t("header_other_services"),
      name: this.$t("header_smartpost"),
      link: Routes.smartpost.url,
      routeName: "SmartPOST"
    }
  ];

  /// [data]
  menus = [
    {
      i18nName: "header_company_home",
      link: Routes.home.url,
      name: "Home"
    },
    {
      i18nName: "header_xid_solution",
      link: "",
      name: ""
    },
    {
      i18nName: "header_gov",
      link: Routes.gov.url,
      name: "Government"
    },
    {
      i18nName: "header_partner",
      link: Routes.partner.url,
      name: "Partner"
    },
    {
      i18nName: "header_about_xid",
      link: Routes.about.url,
      name: "About"
    },
    {
      i18nName: "header_news",
      link: Routes.news.url,
      name: "News"
    },
    {
      i18nName: "header_blog",
      link: "https://blog.xid.inc/",
      isDropdown: false
    },
    {
      i18nName: "header_recruit",
      link: "https://recruit.xid.inc/",
      isDropdown: false
    },
    {
      i18nName: "header_contact_us",
      link: Routes.contactUs.url,
      name: "ContactUs"
    }
  ];

  openMobMenu(value: boolean) {
    if (value) {
      (document.getElementsByTagName("body").item(0) as HTMLBodyElement).style.overflowY = "hidden";
    }
    this.mobMenuIsOpen = value;
  }

  onClickMobMenuItem() {
    this.mobMenuIsOpen = false;
    this.mobAppDropdownIsOpen = false;
    this.mobRrcDropdownIsOpen = false;
  }

  onSelect(item: any) {
    if (item.link.toString().includes("https://")) {
      window.open(item.link.toString(), "_blank");
    } else {
      this.$router.push({ name: item.routeName, params: { timestamp: Date.now().toString() } });
    }
    this.mobMenuIsOpen = false;
  }
}
</script>

<style lang="scss" scoped>
.nav-dropdown {
  height: 20px;
  margin-left: 30px;
  @include mq(l) {
    display: none;
    margin-left: 0px;
  }
}

.nav-dropdown-mob {
  display: none;
  @include mq(l) {
    display: block;
  }

  .dropdown-title {
    @include flex(space-between, center);
    @include fw(bold);
    width: 315px;
    padding-bottom: 4px;

    .text {
      cursor: pointer;
    }

    @include mq(l) {
      width: calc(100vw - (100vw - 715px));
    }

    @include mq(m) {
      width: calc(100vw - 60px);
    }
  }

  .dropdown-selection-area {
    border-top: 1px solid $gs7;
  }

  .dropdown-item {
    text-align: left;
    cursor: pointer;
    margin: 20px 20px 17px;

    .title {
      @include fz(xs);
      @include fw(bold);
      line-height: 20px;
      color: $gs3;
      margin-bottom: 5px;
    }

    .name {
      @include fz(s);
      @include fw(bold);
      line-height: 20px;
      color: $black;
    }

    .active {
      color: $primary1;
    }
  }

  .active {
    color: $primary1;
  }
}
.page-header {
  @include flex(space-between, center);
  @include layoutPadding();
  position: fixed;
  background: $white;
  z-index: 999;
  top: 0;

  .header-menu {
    @include flex(center, center);
    flex-direction: row;

    a {
      margin-left: 30px;
    }
  }

  @include mq(l) {
    .header-menu {
      a {
        margin-left: 30px;
      }
    }
  }

  @include mq(l) {
    .header-menu {
      a {
        margin-left: 0px;
      }
    }
  }

  .hamburger {
    @include size(20px, 12px);
    @extend %centerContent;
    border-radius: 0;
    display: none;
    margin: 11px 0px;
    position: relative;
    z-index: 100;
    cursor: pointer;

    span {
      position: absolute;
      left: 0;
      right: auto;
      top: auto;
      bottom: 0;
      width: 100%;
      height: 4px;
      border-radius: 6px;
      background-color: $primary1;
      transition: all 0.2s;

      &:first-child {
        top: 0px;
      }

      &:last-child {
        bottom: 0;
      }
    }

    &.open {
      span {
        &:nth-of-type(2) {
          transform: rotate(-45deg) translateY(-3px) translateX(3px);
        }

        &:nth-of-type(1) {
          transform: rotate(45deg) translateY(3px) translateX(3px);
        }
      }
    }
  }

  @include mq(l) {
    position: fixed;
    height: 60px;

    > a > img {
      height: 40px;
    }

    .hamburger {
      display: flex;
    }

    .header-menu {
      @include flex(flex-start, flex-start);
      @include fz(l);
      height: calc(100vh - 100%);
      flex-direction: column;
      flex-grow: 1;
      flex-wrap: nowrap;
      overflow-y: scroll;
      background: $white;
      position: absolute;
      z-index: 999;
      top: calc(100% - 1px);
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px calc((100vw - 715px) / 2) 30px;
      opacity: 0;
      transform: scale(0);
      transition: transform 0.25s ease-out, opacity 0.15s ease-out;

      &.open {
        transform: scale(1);
        opacity: 1;
        transition: transform 0.25s ease-out, opacity 0.1s ease-out 0.15s;
      }

      > div {
        margin-top: 20px;
        margin-left: 0px;

        > a {
          margin-left: 0;
        }
      }
    }
  }

  @include mq(m) {
    .header-menu {
      padding: 20px 30px 30px;
    }
  }

  .icon {
    @include flex(center);
    @include size(17px);
    > img {
      transition: transform 0.2s ease-out;
    }
  }

  .expand-icon {
    transform: rotate(90deg);
  }
}
</style>
