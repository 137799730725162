<template>
  <transition name="popup">
    <div class="popup-wrapper" v-if="isDispplay">
      <div class="popup">
        <!-- <slot></slot> -->
        <img class="close-img" :src="require('@/assets/icons/close.svg')" @click="onHide" />
        <div class="info-container">
          <img class="circle-img" :src="img" />
          <div class="info">
            <div class="name">{{ name }}</div>
            <div class="position">{{ position }}</div>
          </div>
        </div>
        <div class="description">
          <p>
            {{ detail }}
          </p>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
  name: "Popup"
})
/**
 * @vuese
 * @group Layout
 * Popup layout
 */
export default class Popup extends Vue {
  // [Prop]
  @Prop({ type: Boolean, default: false, required: true }) isDispplay!: boolean;
  @Prop({ type: String, default: "" }) private img!: string;
  @Prop({ type: String, default: "" }) private name!: string;
  @Prop({ type: String, default: "" }) private position!: string;
  @Prop({ type: String, default: "" }) private detail!: string;

  // [Method]
  onHide() {
    this.$emit("onHide");
  }

  // [lifecycle]
}
</script>

<style lang="scss" scoped>
.popup-enter-active,
.popup-leave-active {
  transition: opacity 0.2s ease-out;
  opacity: 1;
}
.popup-enter, .popup-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.popup-wrapper {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;

  .popup {
    @include center(xy);
    // position: relative;
    background-color: white;
    border-radius: 15px;
    max-height: calc(100vh - 40px);
    max-width: calc(100% - 40px);
    min-height: 400px;
    width: 700px;
    overflow: hidden;
    padding: 50px;

    @include mq(m) {
      // width: calc(100% - 40px);
      padding: 60px 20px 20px;
      width: calc(100% - 60px);
      height: calc(100% - 100px);
    }

    .close-img {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .info-container {
      @include flex(flex-start, center);
      text-align: left;
      .info {
        @include flex(center, flex-start);
        flex-direction: column;
        width: calc(100% - 250px);
        min-height: 200px;
        // height: 100%;
        border-bottom: 1px solid $gs7;
        @include mq(m) {
          width: calc(100% - 150px);
          min-height: 100px;
        }
      }
      .name {
        @include titleFz(xs);
        @include fw(medium);
        height: 29px;
        line-height: 29px;
        @include mq(m) {
          @include fz(l);
          height: 23px;
          line-height: 23px;
        }
      }

      .position {
        @include fz(m);
        @include fw(medium);
        margin: 6px 0 20px;
        height: 20px;
        line-height: 20px;
        color: $gs5;
      }
    }

    .circle-img {
      @include size(200px);
      margin-right: 50px;
      border-radius: 50%;
      top: 0;
      left: 25%;
      z-index: 1;
      object-fit: cover;
      box-sizing: border-box;
      @include mq(m) {
        @include size(100px);
      }
    }

    .description {
      @include fz(m);
      margin-top: 50px;
      text-align: justify;
    }
  }
}
</style>
