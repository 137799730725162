<template>
  <div class="check-box" @click="clickHandler">
    <div class="checked">
      <img class="check-img" v-if="isChecked" :src="require('@/assets/icons/check.svg')" />
    </div>
    <div class="text">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class CheckBox extends Vue {
  /// [Prop]
  /// Button's text
  @Prop({ type: String, required: true }) private text!: string;
  @Prop({ type: Boolean, default: false }) private isCheckedProp!: boolean;

  isChecked = this.isCheckedProp;
  /// [Event handler]
  clickHandler() {
    this.isChecked = !this.isChecked;
    this.$emit("onClick", this.isChecked);
  }

  /// [Local Methods]
}
</script>

<style lang="scss" scoped>
.check-box {
  @include flex(center, center);
  cursor: pointer;
  .checked {
    @include flex(center, center);
    @include size(40px);
    border: 1px solid $gs7;
    border-radius: $radius;
    background-color: $white;
    margin-right: 20px;

    @include mq(m) {
      @include size(25px);
      margin-right: 5px;
      .check-img {
        max-width: 10px;
        max-height: 7px;
      }
    }
  }
}
</style>
