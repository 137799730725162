<template>
  <div class="section-privacy">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.applicationSectionPrivacySubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.applicationSectionPrivacyTitle }}</div>
    </div>
    <div class="intro">
      {{ getWordings.applicationSectionPrivacyDescription }}
    </div>
    <div class="privacy-list">
      <PrivacyCardView
        v-for="idx in 6"
        :key="idx"
        :title="getWordings[`applicationSectionPrivacyF${idx}Title`]"
        :description="getWordings[`applicationSectionPrivacyF${idx}Description`]"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";
import PrivacyCardView from "@/components/parts/PrivacyCardView.vue";

@Component({
  components: {
    LinkButton,
    PrivacyCardView
  },
  inject: ["routes"]
})
export default class SectionPrivacy extends Vue {
  @Getter("text/getWordings") getWordings!: any;

  currentRoute = this.$route;
}
</script>

<style lang="scss" scoped>
.section-privacy {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background-color: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .intro {
    @include fz(l);
    color: $gs4;
    padding: 60px 200px;
    text-align: center;
    @include mq(l) {
      @include fz(m);
      padding: 20px 0px 40px;
    }
  }

  .privacy-list {
    @include flex(space-between, center);
    box-sizing: border-box;
    row-gap: 40px;
    background-color: $gs1;
    border-radius: 15px;
    border: 1px solid $gs7;
    padding: 60px;
    @include mq(l) {
      width: 100%;
    }
    @include mq(m) {
      @include flex(center, center);
      padding: 40px 20px 40px;
      row-gap: 40px;
    }
  }
}
</style>
