<template>
  <div class="section-partner">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionPartnerSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionPartnerTitle }}</div>
    </div>
    <div class="items-wrapper">
      <PartnerView
        class="part-item"
        v-for="(item, idx) in partners"
        :key="idx"
        :image="item.image"
        :name="item.name"
      />
    </div>
    <LinkButton
      class="link-button"
      :wordingKey="'homeSectionPartnerButton'"
      :hrefLink="routes.partner.url"
      :isShowIcon="false"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PartnerView from "@/components/parts/PartnerView.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    PartnerView,
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionPartner extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  partners = [];

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getPartners(contentRepository);
  }

  async getPartners(contentRepository: any) {
    this.partners = (await contentRepository.getPartnersList()).map((item: any): any => {
      const cover = item.attributes.cover.data.attributes;
      return {
        name: item.attributes.name,
        image: cover.url,
        type: (item.attributes.type as string).toLowerCase()
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.section-partner {
  @include size(100%, auto);
  @include layoutPadding();
  background: $white;
  padding-top: 100px;
  padding-bottom: 100px;

  .items-wrapper {
    @include flex(center, center);
    column-gap: 20px;
    margin-top: 60px;
    margin-bottom: 40px;
  }

  .part-item {
    width: 224px;
    height: 150px;
    margin-bottom: 84px;
  }

  .link-button {
    margin: auto;
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;

    .group-title {
      @include fz(m);
      @include fw(medium);
      color: $gs3;

      &.group-1 {
        margin-top: 50px;
      }
      &.group-2 {
        margin-top: 20px;
      }
    }

    .items-wrapper {
      @include flex(space-between, center);
      column-gap: 10px;
      margin-top: 40px;
      margin-bottom: 0px;

      &:empty {
        margin-bottom: 30px;
      }
    }

    .part-item {
      width: calc(33.33% - 10px);
      height: 70px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 55px;
    }
  }
}
</style>
