<template>
  <div class="gov">
    <GovBannersView id="gov" :timestamp="timestamp" />
    <SectionDigitalGovernment />
    <SectionUseCase />
    <SectionWhyXid />
    <SectionClientGovVoice :isGrayBackground="true" />
    <SectionResource />
    <SectionXidContact />
  </div>
</template>

<script lang="ts">
// @ is an alias to /src
import { Component, Prop, Vue } from "vue-property-decorator";
import GovBannersView from "@/components/parts/GovBannersView.vue";
import SectionDigitalGovernment from "@/components/parts/SectionDigitalGovernment.vue";
import SectionUseCase from "@/components/parts/SectionUseCase.vue";
import SectionWhyXid from "@/components/parts/SectionWhyXid.vue";
import SectionClientGovVoice from "@/components/parts/SectionClientGovVoice.vue";
import SectionResource from "@/components/parts/SectionResource.vue";
import SectionXidContact from "@/components/parts/SectionXidContact.vue";
import routes from "@/router/routes";
@Component({
  name: "Gov",
  components: {
    GovBannersView,
    SectionDigitalGovernment,
    SectionUseCase,
    SectionWhyXid,
    SectionClientGovVoice,
    SectionResource,
    SectionXidContact
  },
  metaInfo: routes.gov.meta
})
export default class Gov extends Vue {
  @Prop({ type: String }) private timestamp!: string;
}
</script>
