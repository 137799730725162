<template>
  <div class="gov">
    <DevBannersView id="dev" :timestamp="timestamp" />
    <SectionXidCanDo />
    <SectionGlobalStandard />
    <SectionOtherCooperation />
    <SectionDevContact />
  </div>
</template>

<script lang="ts">
// @ is an alias to /srcimport Vue from "vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import DevBannersView from "@/components/parts/DevBannersView.vue";
import SectionXidCanDo from "@/components/parts/SectionXidCanDo.vue";
import SectionGlobalStandard from "@/components/parts/SectionGlobalStandard.vue";
import SectionOtherCooperation from "@/components/parts/SectionOtherCooperation.vue";
import SectionDevContact from "@/components/parts/SectionDevContact.vue";
import routes from "@/router/routes";
@Component({
  name: "Dev",
  components: {
    DevBannersView,
    SectionXidCanDo,
    SectionGlobalStandard,
    SectionOtherCooperation,
    SectionDevContact
  },
  metaInfo: routes.dev.meta
})
export default class Dev extends Vue {
  @Prop({ type: String }) private timestamp!: string;
}
</script>
