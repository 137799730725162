<template>
  <div class="section-digital-government">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.governmentSectionDigitalSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.governmentSectionDigitalTitle"></div>
    </div>
    <div
      class="government-description"
      v-html="getWordings.governmentSectionDigitalDescription"
    ></div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Getter } from "vuex-class";

@Component({
  components: {}
})
export default class SectionDigitalGovernment extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-digital-government {
  @include layoutPadding;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .government-description {
    margin: auto;
    max-width: 800px;
    margin-top: 60px;
    @include mq(l) {
      margin-top: 40px;
    }
  }
  .description {
    margin: auto;
    max-width: 800px;
    margin-top: 30px;
  }
}
</style>
