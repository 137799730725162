<template>
  <div class="voice">
    <div class="img-wrapper">
      <img class="voice-img" :src="img" />
    </div>
    <div class="voice-content" :class="{ 'voice-content-white': isWhiteCard }">
      <div class="voice-text">
        <img class="quote-left" :src="require('@/assets/icons/quote-right.svg')" />
        <div class="content" v-html="text"></div>
        <img class="quote-right" :src="require('@/assets/icons/quote-left.svg')" />
      </div>
      <div class="ending">
        <div>{{ signature1 }}</div>
        <div>{{ signature2 }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class VoiceCardView extends Vue {
  @Prop({ type: Boolean, default: false }) private isWhiteCard!: boolean;
  @Prop({ type: String, default: "" }) private img!: string;
  @Prop({ type: String, default: "" }) private text!: string;
  @Prop({ type: String, default: "" }) private signature1!: string;
  @Prop({ type: String, default: "" }) private signature2!: string;
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.voice {
  @include flex(space-between, center);
  position: relative;
  width: 100%;
  @include mq(l) {
    @include flex(center, center);
  }

  .voice-content {
    position: absolute;
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 0;
    right: 0;
    border: 1px solid $gs7;
    border-radius: $radius15;
    background-color: $gs1;
    padding: 0px 50px 0px 150px;
    @include mq(l) {
      position: relative;
      padding: 40px 20px 20px;
      min-height: 316px;
      bottom: 0;
      right: unset;
    }
  }

  .voice-content-white {
    background-color: $white;
  }

  .img-wrapper {
    @include flex(center, center);
    position: absolute;
    z-index: 1;
    left: 0;
    width: 300px;
    height: 200px;
    flex: 1 1 auto;
    box-shadow: 0px 0px 10px 0px $boxShadow;
    border-radius: $radius15;
    object-fit: scale-down;
    box-sizing: border-box;
    background: $white;
    overflow: hidden;

    > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: scale-down;
      box-sizing: border-box;
    }
    @include mq(l) {
      position: relative;
      max-width: 235px;
      max-height: 150px;
      // top: 10px;
      margin-bottom: -20px;
      left: unset;
      > img {
        max-height: 150px;
      }
    }
  }

  .voice-text {
    position: relative;

    .content {
      padding: 24px 50px;
      line-height: 29px;
      @include mq(l) {
        @include fz(s);
        line-height: 17px;
        padding: 40px 0px 30px;
        width: 100%;
      }
    }

    .quote-left {
      position: absolute;
      left: 0;
    }

    .quote-right {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }

  .content {
    text-align: left;
    max-width: 700px;
    @include mq(l) {
      text-align: justify;
      width: 275px;
    }
  }

  .ending {
    @include flex(flex-start, center);
    @include fw(medium);
    line-height: 24px;
    margin-top: 20px;
    :first-child {
      margin: 0px 60px 0 50px;
    }
    @include mq(l) {
      @include flex(center, center);
      @include fz(s);
      bottom: 20px;
      line-height: 17px;
      left: 0;
      width: 100%;
      display: block;
      margin-top: 25px;
      :first-child {
        margin: 0 0 10px 0;
      }
    }
  }
}
</style>
