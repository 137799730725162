<template>
  <div class="intro-card-view">
    <div class="intro-title" :class="{ dev: dev, app: app, smartpost: gov }">{{ title }}</div>
    <div class="intro-desc-container">
      <!-- <img class="intro-img" :src="img" alt="xid-logo" /> -->
      <div class="intro-desc">{{ desc }}</div>
      <div class="button-wrapper">
        <LinkButton
          class="link-button"
          :wordingKey="'homeSectionIntroButton'"
          :hrefLink="hrefLink"
          :isLinkButton="true"
          :isShowIcon="true"
          :linkUnderline="false"
        />
        <LinkButton
          class="link-button-mob"
          :wordingKey="'homeSectionIntroButton'"
          :hrefLink="hrefLink"
          :isLinkButton="true"
          :isShowIcon="true"
          :isShowText="false"
          :linkUnderline="false"
        />
      </div>
      <img class="intro-img" :src="img" alt="xid-logo" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class IntroCardView extends Vue {
  @Prop({ type: String, required: false }) private hrefLink!: string;
  @Prop({ type: String, required: false }) private title!: string;
  @Prop({ type: String, required: false }) private desc!: string;
  @Prop({ type: String, required: false }) private img!: string;
  @Prop({ type: Boolean, required: false, default: false }) private dev!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private app!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) private gov!: boolean;
}
</script>

<style lang="scss" scoped>
.intro-card-view {
  width: 386.67px;
  border: 1px solid $gs7;
  border-radius: $radius15;
  text-align: center;
  background-color: $gs1;
  z-index: 1;
  @include mq(m) {
    text-align: left;
    width: 100%;
    border-radius: 10px;
  }

  .intro-desc-container {
    @include flex(center, center);
    flex-direction: column;
    @include mq(m) {
      @include flex(space-between, flex-start);
      padding: 10px 20px 10px 20px;
      flex-direction: row;
      margin: 0;
    }
  }

  .intro-title {
    @include fz(xs);
    @include fw(heavy);
    line-height: 30px;
    color: $white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    @include mq(m) {
      @include fz(xxs);
      padding: 4px 20px 5px;
      border-top-right-radius: 9px;
      border-top-left-radius: 9px;
    }
  }

  .app {
    background-color: $spin-color1;
  }

  .dev {
    background-color: $spin-color2;
  }

  .gov {
    background-color: $spin-color3;
  }

  .smartpost {
    background-color: $sp-color1;
  }

  .intro-desc {
    @include fz(xxl);
    @include fw(bold);
    line-height: 30px;
    padding: 20px 30px 10px;
    @include mq(m) {
      @include fz(m);
      padding: 0px;
      width: calc(100% - 20px);
      margin: 0px;
    }
  }

  .button-wrapper {
    @include flex(center, center);
    @include mq(m) {
      max-width: 20px;
    }
  }

  .link-button {
    height: 20px;
    margin-bottom: 24px;
    @include mq(m) {
      display: none;
    }
  }

  .link-button-mob {
    display: none;
    @include mq(m) {
      display: block;
    }
  }

  .intro-img {
    @include mq(m) {
      max-width: 75px;
      display: none;
    }
  }
}
</style>
