import { ActionTree } from "vuex";
import { ScrollState } from "./types";
import { RootState } from "../types";

const actions: ActionTree<ScrollState, RootState> = {
  setAboutScrollData({ commit }, aboutScrollData) {
    commit("setAboutScrollData", aboutScrollData);
  }
};

export default actions;
