<template>
  <div class="section-news-footer">
    <router-link
      class="previous-news"
      v-if="prevPost"
      :to="{
        name: routes.news.detail.name,
        params: { id: prevPost.id.toString() }
      }"
    >
      <div>
        <LinkButton
          :isShowIcon="true"
          :isLinkButton="true"
          :isNavLeft="true"
          :linkUnderline="false"
          :wordingKey="'newsPreviousButton'"
        />
        <img
          class="news-images"
          :src="
            prevPost && prevPost.cover
              ? assetsDomain + prevPost.cover.hash + prevPost.cover.ext
              : 'https://public.xid.inc/assets/news_default_705af5bb55.svg'
          "
        />
        <div class="bold-text">{{ prevPost ? prevPost.attributes.title : "" }}</div>
      </div>
    </router-link>
    <div class="previous-news" v-else></div>
    <router-link
      class="next-news"
      v-if="nextPost"
      :to="{
        name: routes.news.detail.name,
        params: { id: nextPost.id.toString() }
      }"
    >
      <div>
        <LinkButton
          :isShowIcon="true"
          :isLinkButton="true"
          :linkUnderline="false"
          :wordingKey="'newsNextButton'"
        />
        <img
          class="news-images"
          :src="
            nextPost && nextPost.cover
              ? assetsDomain + nextPost.cover.hash + nextPost.cover.ext
              : 'https://public.xid.inc/assets/news_default_705af5bb55.svg'
          "
        />
        <div class="bold-text">{{ nextPost ? nextPost.attributes.title : "" }}</div>
      </div>
    </router-link>
    <div class="next-news" v-else></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionNewsFooter extends Vue {
  @Prop() prevPost!: any;
  @Prop() nextPost!: any;
  assetsDomain = process.env.VUE_APP_ASSETS_URL as string;
}
</script>

<style lang="scss" scoped>
.section-news-footer {
  @include flex(space-between, center);
  flex-wrap: nowrap;
  border-top: 1px solid $gs2;
  padding: 50px 0 150px;

  .previous-news {
    @include flex(flex-start, flex-start);
    max-width: 300px;
    flex-direction: column;
    flex: 1;
    text-align: left;
    margin-right: 20px;
    cursor: pointer;

    > div {
      @include flex(flex-start, flex-start);
    }
  }

  .next-news {
    @include flex(flex-end, flex-end);
    max-width: 300px;
    flex: 1;
    flex-direction: column;
    text-align: right;
    cursor: pointer;

    > div {
      @include flex(flex-end, flex-end);
    }
  }

  .news-images {
    @include size(100%, 200px);
    border-radius: 5px;
    border: 1px solid $gs2;
    object-fit: scale-down;
    margin-top: 20px;
  }

  .bold-text {
    @include fz(xxl);
    @include fw(heavy);
    color: $black;
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    max-lines: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  // Responsive
  @include mq(m) {
    padding: 50px 0;

    .previous-news {
      margin-right: 20px;
    }

    .news-images {
      @include size(100%, 105px);
      margin-top: 10px;
    }

    .bold-text {
      @include fz(s);
    }
  }
}
</style>
