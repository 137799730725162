<template>
  <div class="section-mission">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionMissionSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionMissionTitle }}</div>
    </div>
    <div class="slogan">
      <div v-html="getWordings.homeSectionMissionSlogan"></div>
    </div>
    <p class="mission_content">{{ getWordings.homeSectionMissionSloganDescription }}</p>
    <LinkButton
      v-if="currentRoute.name == 'Home'"
      class="link-button"
      :wordingKey="'homeSectionMissionButton'"
      :hrefLink="'/about'"
      :openNewTab="true"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionMission extends Vue {
  @Getter("text/getWordings") getWordings!: any;

  currentRoute = this.$route;
}
</script>

<style lang="scss" scoped>
.section-mission {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  // background: $gs1;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .link-button {
    margin-top: 60px;
    @include mq(m) {
      width: 100%;
    }
  }

  .mission_title,
  .mission_content {
    color: $gs4;
  }

  .mission_content {
    text-align: justify;
    max-width: 850px;
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;

    .mission_content {
      text-align: left;
    }

    .separator-line {
      @include size(0px, 3px);
      margin-top: 10px;

      &.animating {
        width: 100px;
      }
    }
  }
}
</style>
