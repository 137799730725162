<template>
  <div class="section-contact">
    <ValidationObserver ref="validationObserver" v-slot="{ handleSubmit }">
      <form id="contact-form" @submit.prevent="verified ? handleSubmit(sendContact) : ''">
        <span class="hint-label required-label before">{{ $t("section_contact_required") }}</span>

        <!-- Company name -->
        <div class="group-input">
          <span class="label required-label after">{{ $t("section_contact_company_name") }}</span>
          <ValidationTextField
            class="input"
            rules="required"
            :placeholder="$t('section_contact_company_name_input')"
            :message="$t('section_contact_company_name_input_error')"
            v-model="companyName"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationTextField>
        </div>

        <!-- Business -->
        <div class="row-input">
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_division") }}</span>
            <ValidationTextField
              class="input"
              rules="required"
              :placeholder="$t('section_contact_division_input')"
              :message="$t('section_contact_division_input_error')"
              v-model="companyDivision"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationTextField>
          </div>
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_job_title") }}</span>
            <ValidationTextField
              class="input"
              rules="required"
              :placeholder="$t('section_contact_job_title_input')"
              :message="$t('section_contact_job_title_input_error')"
              v-model="jobTitle"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationTextField>
          </div>
        </div>

        <!-- Name -->
        <div class="row-input">
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_last_name") }}</span>
            <ValidationTextField
              class="input"
              rules="required"
              :placeholder="$t('section_contact_last_name_input')"
              :message="$t('section_contact_last_name_input_error')"
              v-model="lastName"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationTextField>
          </div>
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_first_name") }}</span>
            <ValidationTextField
              class="input"
              rules="required"
              :placeholder="$t('section_contact_first_name_input')"
              :message="$t('section_contact_first_name_input_error')"
              v-model="firstName"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationTextField>
          </div>
        </div>

        <!-- Mail name -->
        <div class="group-input">
          <span class="label required-label after">{{ $t("section_contact_mail_address") }}</span>
          <ValidationTextField
            class="input"
            rules="required|email"
            :placeholder="$t('section_contact_mail_address_input')"
            :message="{
              required: $t('section_contact_mail_address_input_error'),
              format_error: $t('section_contact_format_error')
            }"
            v-model="email"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationTextField>
        </div>

        <!-- Phone name -->
        <div class="group-input">
          <span class="label required-label after">{{ $t("section_contact_tel") }}</span>
          <ValidationTextField
            class="input"
            :rules="{ required: true, regex: /^\+?[0-9()-]+$/ }"
            type="tel"
            :placeholder="$t('section_contact_tel_input')"
            :message="{
              required: $t('section_contact_tel_input_error'),
              format_error: $t('section_contact_format_error')
            }"
            v-model="phoneNumber"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationTextField>
        </div>

        <!-- About your company -->
        <div class="row-input">
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_organization") }}</span>
            <ValidationDropdown
              class="input dropdown-listing"
              :required="true"
              :placeholder="$t('section_contact_company_size_input')"
              :items="listingDivs"
              :message="$t('section_contact_organization_input_error')"
              v-model="organization"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationDropdown>
          </div>
          <div class="group-input">
            <span class="label required-label after">{{ $t("section_contact_company_size") }}</span>
            <ValidationDropdown
              class="input dropdown-listing"
              :placeholder="$t('section_contact_company_size_input')"
              :message="$t('section_contact_company_size_input_error')"
              :required="true"
              :items="listCompanySize"
              v-model="companySize"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationDropdown>
          </div>
        </div>

        <!-- url -->
        <div class="group-input">
          <span class="required label before">{{ $t("section_contact_url") }}</span>
          <ValidationTextField
            class="input"
            rules="url"
            :placeholder="$t('section_contact_url_input')"
            :message="{
              format_error: $t('section_contact_format_error')
            }"
            :required="false"
            v-model="url"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationTextField>
        </div>

        <!-- Contact division -->
        <div class="group-input">
          <span class="label required-label after">{{ $t("section_contact_inquiry_type") }}</span>
          <ValidationDropdown
            class="input"
            :placeholder="$t('section_contact_inquiry_type_input')"
            :message="$t('section_contact_inquiry_type_error')"
            :required="true"
            :items="contents"
            v-model="inquiryType"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationDropdown>
        </div>

        <div v-if="openApiDocsQuestion">
          <div class="group-input">
            <span class="label required-label after">
              {{ $t("about_api_implementation_time") }}
            </span>
            <ValidationDropdown
              class="input"
              :placeholder="$t('dropbox_placeholder')"
              :message="$t('section_contact_inquiry_type_error')"
              :required="true"
              :items="apiImplementationTimes"
              v-model="apiImplementationTime"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationDropdown>
          </div>

          <div class="group-input">
            <span class="label required-label after">{{ $t("status_of_securing_budget") }}</span>
            <ValidationDropdown
              class="input"
              :placeholder="$t('dropbox_placeholder')"
              :message="$t('section_contact_inquiry_type_error')"
              :required="true"
              :items="statusOfSecuringBudgets"
              v-model="statusOfSecuringBudget"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationDropdown>
          </div>
          <div
            class="group-input other-option"
            v-if="statusOfSecuringBudget == $t('status_of_securing_budget_option4')"
          >
            <ValidationTextField
              class="input"
              rules="required"
              type="text"
              :placeholder="$t('others_option_placeholder')"
              :message="{
                required: $t('section_contact_content_inquiry_type_input_error')
              }"
              v-model="statusOfSecuringBudgetOther"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationTextField>
          </div>

          <div class="group-input">
            <span class="label required-label after">{{ $t("api_usage_areas") }}</span>
            <ValidationDropdown
              class="input"
              :placeholder="$t('dropbox_placeholder')"
              :message="$t('section_contact_inquiry_type_error')"
              :required="true"
              :items="apiUsageAreas"
              v-model="apiUsageArea"
              v-slot="{ errorString }"
            >
              <div class="error-notice">{{ errorString }}</div>
            </ValidationDropdown>
          </div>
        </div>

        <!-- Contact us content -->
        <div class="group-input">
          <span class="label required-label after">
            {{ $t("section_contact_content_inquiry") }}
          </span>
          <ValidationTextField
            class="input input-area"
            rules="required"
            :placeholder="$t('section_contact_content_inquiry_type_input')"
            :message="$t('section_contact_content_inquiry_type_input_error')"
            :isMultiline="true"
            v-model="inquiryContent"
            v-slot="{ errorString }"
          >
            <div class="error-notice">{{ errorString }}</div>
          </ValidationTextField>
        </div>

        <div class="checkbox-container">
          <div class="check-box" :class="{ active: isAcceptPolicy }" @click="clickAcceptPolicy">
            <div class="check-signal"></div>
          </div>
          <p class="check-box-text" v-html="$t('section_contact_check_box_text')"></p>
        </div>

        <div class="group-input">
          <vue-hcaptcha
            ref="hcaptcha"
            sitekey="33db49a3-29bf-4237-9001-18fc36b80231"
            @verify="verify"
            @expired="expired"
          ></vue-hcaptcha>
        </div>

        <div class="contact-button">
          <SubmitButton
            class="submit-button"
            :disabled="!isAcceptPolicy || !verified"
            :text="$t('section_contact')"
          />
        </div>
      </form>
    </ValidationObserver>

    <transition name="fade">
      <div class="successfull" v-if="postSuccessfull">{{ $t("section_contact_post_success") }}</div>
    </transition>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

import ValidationTextField from "@/components/controls/ValidationTextField.vue";
import SubmitButton from "@/components/controls/SubmitButton.vue";
import ValidationDropdown from "@/components/controls/ValidationDropdown.vue";

@Component({
  components: {
    ValidationTextField,
    SubmitButton,
    ValidationDropdown,
    ValidationObserver,
    VueHcaptcha
  }
})
export default class SectionContact extends Vue {
  contents: Array<string> = [];
  listingDivs: Array<string> = [];
  listCompanySize: Array<string> = [];
  apiImplementationTimes: Array<string> = [];
  statusOfSecuringBudgets: Array<string> = [];
  apiUsageAreas: Array<string> = [];

  companyName = "";
  companyDivision = "";
  jobTitle = "";
  lastName = "";
  firstName = "";
  email = "";
  phoneNumber = "";
  organization = "";
  companySize = "";
  url = "";
  inquiryType = "";
  inquiryContent = "";

  apiImplementationTime = "";
  statusOfSecuringBudget = "";
  apiUsageArea = "";
  statusOfSecuringBudgetOther = "";

  hcaptchaToken = "";
  verified = false;
  postSuccessfull = false;
  isAcceptPolicy = false;

  openApiDocsQuestion = false;

  created() {
    this.contents = [
      this.$t("section_contact_inquiry_type_select1").toString(),
      this.$t("section_contact_inquiry_type_select2").toString(),
      this.$t("section_contact_inquiry_type_select3").toString(),
      this.$t("section_contact_inquiry_type_select4").toString(),
      this.$t("section_contact_inquiry_type_select5").toString(),
      this.$t("section_contact_inquiry_type_select6").toString(),
      this.$t("section_contact_inquiry_type_select7").toString()
    ];

    this.listingDivs = [
      this.$t("section_contact_organization_select1").toString(),
      this.$t("section_contact_organization_select2").toString()
    ];

    this.listCompanySize = ["1-50", "50-100", "100-300", "300-1000"];

    this.apiImplementationTimes = [
      this.$t("about_api_implementation_time_option1").toString(),
      this.$t("about_api_implementation_time_option2").toString(),
      this.$t("about_api_implementation_time_option3").toString(),
      this.$t("about_api_implementation_time_option4").toString()
    ];

    this.statusOfSecuringBudgets = [
      this.$t("status_of_securing_budget_option1").toString(),
      this.$t("status_of_securing_budget_option2").toString(),
      this.$t("status_of_securing_budget_option3").toString(),
      this.$t("status_of_securing_budget_option4").toString()
    ];

    this.apiUsageAreas = [
      this.$t("api_usage_areas_option1").toString(),
      this.$t("api_usage_areas_option2").toString()
    ];
  }

  @Watch("inquiryType")
  onChangeInquiryType(inquiryType: string) {
    if (inquiryType == this.$t("section_contact_inquiry_type_select1").toString()) {
      this.openApiDocsQuestion = true;
    } else {
      this.openApiDocsQuestion = false;
    }
  }

  clickAcceptPolicy() {
    this.isAcceptPolicy = !this.isAcceptPolicy;
  }

  verify(val: any) {
    this.verified = true;
    this.hcaptchaToken = val;
  }

  expired() {
    this.verified = false;
    this.hcaptchaToken = "";
  }

  async sendContact() {
    this.verified = false;
    const contactForm = document.getElementById("contact-form") as HTMLFormElement;
    const formData = new FormData(contactForm);
    formData.append("company-name", this.companyName);
    formData.append("company-division", this.companyDivision);
    formData.append("job", this.jobTitle);
    formData.append("last-name", this.lastName);
    formData.append("first-name", this.firstName);
    formData.append("email", this.email);
    formData.append("phone-number", this.phoneNumber);
    formData.append("organization", this.organization);
    formData.append("companySize", this.companySize);
    formData.append("url", this.url);
    formData.append("inquiry-type", this.inquiryType);
    formData.append("inquiry-content", this.inquiryContent);
    formData.delete("g-recaptcha-response");

    if (this.inquiryType == this.$t("section_contact_inquiry_type_select1").toString()) {
      formData.append("about_api_implementation_time", this.apiImplementationTime);
      if (this.statusOfSecuringBudget == this.$t("status_of_securing_budget_option4")) {
        formData.append("status_of_securing_budget", this.statusOfSecuringBudgetOther);
      } else {
        formData.append("status_of_securing_budget", this.statusOfSecuringBudget);
      }
      formData.append("api_usage_areas", this.apiUsageArea);
    }

    await fetch(process.env.VUE_APP_CONTACT_API_URL as string, {
      method: "POST",
      mode: "no-cors",
      credentials: "same-origin",
      referrerPolicy: "no-referrer",
      body: formData
    });

    this.postSuccessfull = true;
    setTimeout(() => {
      this.postSuccessfull = false;
      (this.$refs.hcaptcha as any).reset();
      this.reset();
      contactForm.reset();
    }, 1500);
  }

  reset() {
    this.companyName = "";
    this.companyDivision = "";
    this.jobTitle = "";
    this.lastName = "";
    this.firstName = "";
    this.email = "";
    this.phoneNumber = "";
    this.organization = "";
    this.companySize = "";
    this.url = "";
    this.inquiryType = "";
    this.inquiryContent = "";
    this.apiImplementationTime = "";
    this.statusOfSecuringBudget = "";
    this.apiUsageArea = "";
    this.statusOfSecuringBudgetOther = "";

    this.hcaptchaToken = "";
    (this.$refs.validationObserver as any).reset();
  }
}
</script>

<style lang="scss" scoped>
.section-contact {
  @include size(100%, auto);
  text-align: left;
  padding: 100px 50px 100px;
  box-sizing: border-box;

  .row-input {
    @include flex(space-between, center);
    flex-direction: row;
    flex: 1;

    & > :first-child {
      margin-right: 20px;
    }
  }

  .group-input {
    @include flex(flex-start, flex-start);
    flex-direction: column;
    flex: 1;
    margin-top: 50px;
  }

  .other-option {
    margin-top: 20px;
  }

  .input {
    @include fz(m);
    width: 100%;
    margin-top: 10px;
  }

  .input-area {
    height: 145px;
  }

  .hint-label {
    color: $gs4;
  }

  .label {
    @include fw(medium);
  }

  .required-label {
    position: relative;

    &.before {
      padding-left: 16px;

      &::before {
        content: "";
        @include size(6px);
        position: absolute;
        top: 0;
        left: 0;
        background: $primary1;
        border-radius: 50%;
      }
    }

    &.after {
      padding-right: 16px;

      &::after {
        content: "";
        @include size(6px);
        position: absolute;
        top: 0;
        right: 0;
        background: $primary1;
        border-radius: 50%;
      }
    }
  }

  .error-notice {
    @include flex(flex-start, center);
    @include size(100%, 100%);
    @include fz(s);
    @include fw(medium);
    max-height: 30px;
    padding: 0px 20px;
    color: $white;
    background-color: $secondary3;
    border-radius: $radius;
    box-sizing: border-box;
  }

  .successfull {
    @include flex(center, center);
    @include size(100vw, 50px);
    @include fw(medium);
    @include fz(s);
    position: fixed;
    top: 50%;
    left: 0;
    z-index: 9;
    background: $primary1;
    color: $white;
  }

  .checkbox-container {
    @include flex(flex-start, flex-start);
    flex-wrap: nowrap;
    border: 1px solid $gs2;
    padding: 30px;
    margin-top: 100px;
    border-radius: 5px;
    @include mq(m) {
      padding: 10px;
      margin-top: 50px;
    }
    .check-box-text {
      @include fw(medium);
      @include fz(l);
      color: $black;
      @include mq(m) {
        @include fz(xs);
      }
    }
    .check-box {
      @include flex(center, center);
      @include size(30px);
      min-width: 30px;
      cursor: pointer;
      margin-right: 20px;
      border: 1px solid $primary1;
      border-radius: 5px;
      @include mq(m) {
        @include size(20px);
        min-width: 20px;
        margin-right: 10px;
      }

      &.active {
        background-color: $primary1;
      }

      .check-signal {
        @include size(12px, 6px);
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
        @include mq(m) {
          @include size(8px, 4px);
        }
      }
    }
  }

  .contact-button {
    @extend %centerContent;
    margin-top: 50px;
  }

  // Responsive
  @include mq(m) {
    padding: 40px 0 50px;

    .row-input {
      flex-direction: column;

      & > :first-child {
        margin-right: 0;
      }
    }

    .group-input {
      margin-top: 30px;
      width: 100%;
    }

    .input {
      @include fz(xs);
      margin-top: 10px;
      color: $gs4;
    }

    .error-notice {
      @include fz(xxs);
      max-height: 20px;
      padding: 0px 10px;
    }

    .successfull {
      @include fz(xxs);
    }

    .input-area {
      // height: 36px;
      padding: 0;
    }

    .contact-button {
      @extend %centerContent;
      margin-top: 20px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
}
</style>
