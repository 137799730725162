<template>
  <div class="section-global-standard">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.devSectionStandardsSubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.devSectionStandardsTitle"></div>
    </div>
    <div class="standard-card-container">
      <div class="standard-card">
        <div class="img-wrapper">
          <img class="img" :src="require('@/assets/icons/code_icon.svg')" />
        </div>
        <div class="title">{{ getWordings.devSectionStandardsF1Title }}</div>
        <div class="desc">
          {{ getWordings.devSectionStandardsF1Description }}
        </div>
      </div>
      <div class="standard-card">
        <div class="img-wrapper">
          <img class="img" :src="require('@/assets/icons/api_icon.svg')" />
        </div>
        <div class="title">{{ getWordings.devSectionStandardsF2Title }}</div>
        <div class="desc">{{ getWordings.devSectionStandardsF2Description }}</div>
      </div>
      <div class="standard-card">
        <div class="img-wrapper">
          <img class="img" :src="require('@/assets/icons/support_icon.svg')" />
        </div>
        <div class="title">{{ getWordings.devSectionStandardsF3Title }}</div>
        <div class="desc">
          {{ getWordings.devSectionStandardsF3Description }}
        </div>
      </div>
      <div class="standard-card coming-soon">
        <div class="coming-soon-banners">{{ "COMING SOON" }}</div>
        <div class="img-wrapper">
          <img class="img" :src="require('@/assets/icons/dashboard_icon.svg')" />
        </div>
        <div class="title">{{ getWordings.devSectionStandardsF4Title }}</div>
        <div class="desc">
          {{ getWordings.devSectionStandardsF4Description }}
        </div>
      </div>
    </div>
    <div class="global_standard">
      <div class="title">{{ getWordings.devSectionKeepStandardsTitle }}</div>
      <div class="desc" v-html="getWordings.devSectionKeepStandardsDescription"></div>
      <div class="digital-id-flow">
        <div class="global_standard-item">
          <img :src="require('@/assets/images/global_standard.svg')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  }
})
export default class SectionGlobalStandard extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-global-standard {
  @include layoutPadding;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  background-color: $gs8;

  .standard-card-container {
    @include flex(space-between, center);
    margin-top: 60px;
    margin-bottom: 40px;
    @include mq(l) {
      display: inline-block;
      margin-top: 40px;
      margin-bottom: 40px;
      height: unset;
      width: 100%;
    }

    .standard-card {
      height: 300px;
      border: 1px solid $gs7;
      border-radius: $radius15;
      padding-top: 50px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: $white;
      max-width: 245px;
      @include mq(l) {
        height: auto;
        padding-top: 40px;
        margin-bottom: 20px;
        max-width: 100%;
        &.coming-soon {
          padding-top: 50px;
          margin-bottom: 0px;
        }
      }
      .coming-soon-banners {
        @include flex(center, center);
        @include fw(heavy);
        @include fz(xs);
        height: 30px;
        margin-top: -50px;
        margin-left: -20px;
        margin-right: -20px;
        margin-bottom: 20px;
        background: $primary1;
        border-top-left-radius: $radius15;
        border-top-right-radius: $radius15;
        color: white;
        @include mq(m) {
          height: 20px;
        }
      }
      .img-wrapper {
        @include size(80px);
        @include flex(center);
        border-radius: 10px;
        border: 1px solid $gs7;
        margin: auto;
      }
      .img {
        margin: auto;
      }
      .title {
        margin-top: 30px;
        @include titleFz(xs);
        @include mq(l) {
          margin-top: 20px;
          @include fz(m);
        }
        @include fw(heavy);
        line-height: 30px;
      }
      .desc {
        @include fz(m);
        // max-width: 200px;
        margin-top: 20px;
        margin-bottom: 28px;
        @include mq(l) {
          @include fz(s);
          margin-top: 20px;
          margin-bottom: 40px;
        }
        @include mq(m) {
          @include fz(s);
          margin-top: 5px;
          margin-bottom: 40px;
        }
      }
      .button {
        @include flex(center, center);
      }
    }
  }
  .global_standard {
    background: white;
    @include mq(l) {
      margin-top: 0px;
    }
    border: 1px solid $gs7;
    border-radius: $radius15;
    padding: 60px 60px 30px;
    @include mq(l) {
      padding: 40px 20px 20px;
    }

    .title {
      @include titleFz(s);
      @include mq(l) {
        @include fz(m);
      }
      @include fw(heavy);
      height: 35x;
      line-height: 35px;
    }
    .desc {
      @include fz(m);
      margin-top: 30px;
      margin-bottom: 60px;
      @include mq(l) {
        @include fz(s);
        margin-top: 5px;
        margin-bottom: 30px;
      }
    }
  }

  .digital-id-flow {
    @include flex(center, center);
  }

  .global_standard-item {
    margin-bottom: 30px;
    img {
      @include mq(l) {
        width: 100%;
      }
    }
  }
}
</style>
