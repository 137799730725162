<template>
  <div class="section-xid-contact">
    <div class="title" v-html="getWordings.governmentSectionContactTitle"></div>
    <div class="button-wrapper">
      <LinkButton
        class="link-button"
        :wordingKey="'governmentSectionContactContactButton'"
        :hrefLink="contactUsDomain"
        :isShowIcon="false"
      />
      <LinkButton
        class="link-button download-btn"
        :wordingKey="'governmentSectionContactDownloadButton'"
        :hrefLink="downloadDocsURL"
        :isShowIcon="false"
        :isWhiteButton="true"
        :openNewTab="true"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionXidContact extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  contactUsDomain = process.env.VUE_APP_XID_CONTACT_URL as string;
  downloadDocsURL = process.env.VUE_APP_DOCS_FOR_GOV_DOWNLOAD_URL as string;
}
</script>

<style lang="scss" scoped>
.section-xid-contact {
  @include size(100%, auto);
  @include titleFz(xs);
  @include fw(medium);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  background: $gs8;

  .title {
    width: 100%;
    max-width: 800px;
    margin-bottom: 60px;
  }
  .download-btn {
    margin-left: 30px;
    @include mq(l) {
      margin-left: 0px;
      margin-top: 10px;
    }
  }

  .button-wrapper {
    display: flex;
    @include mq(l) {
      width: 100%;
      display: block;
    }
  }

  @include mq(l) {
    @include fz(m);
    .title {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
