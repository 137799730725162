<template>
  <div class="privacy-card">
    <div class="check-content">
      <span class="content">
        <img :src="require('@/assets/icons/check.svg')" class="check-mark" />
        {{ title }}
      </span>
    </div>
    <div class="desc">
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class PrivacyCardView extends Vue {
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "" }) private description!: string;
}
</script>

<style lang="scss" scoped>
.privacy-card {
  text-align: center;
  max-width: 340px;
  @include mq(l) {
    margin-bottom: 0px;
  }
  @include mq(m) {
    width: 100%;
  }
  .check-content {
    display: flex;
    font-weight: bold;
    margin-bottom: 20px;
    @include mq(m) {
      margin: 0 0 5px;
    }
    @include mq(l) {
      width: 100%;
    }
    .content {
      @include flex(left);
      @include titleFz(xxs);
      @include mq(l) {
        @include fz(s);
        width: 100%;
      }
      line-height: 30px;
    }
    .check-mark {
      margin-right: 10px;
    }
  }
  .img {
    margin: auto;
  }

  .desc {
    text-align: left;
    @include fz(m);
    line-height: 29px;
    @include mq(l) {
      @include fz(s);
      margin-top: 10px;
    }
    @include mq(m) {
      max-width: 100%;
      width: 100%;
      margin-top: 5px;
    }
  }
}
</style>
