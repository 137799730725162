<template>
  <div class="service-card">
    <div class="service-card-content">
      <div class="top-part">
        <img class="service-card-img" :src="img" />
        <div class="service-card-desc">
          <div class="title">{{ title }}</div>
          <div class="desc">{{ name }}</div>
        </div>
      </div>
      <div class="detail-btn" :class="{ disable: disable }" @click="onClickDetail()">
        <div>
          {{ $t("btn_view_website") }}
        </div>
        <img
          v-if="disable"
          class="button-icon"
          :src="require('@/assets/icons/nav-right-grey.svg')"
        />
        <img v-else class="button-icon" :src="require('@/assets/icons/nav-right-white.svg')" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class ServiceCardView extends Vue {
  @Prop({ type: Boolean, default: true }) private disable!: boolean;
  @Prop({ type: String, default: "" }) private title!: string;
  @Prop({ type: String, default: "" }) private name!: string;
  @Prop({ type: String, default: "" }) private img!: string;

  onClickDetail() {
    if (!this.disable) {
      this.$emit("onClickDetail");
    }
  }
}
</script>

<style lang="scss" scoped>
.service-card {
  height: auto;
  width: calc(50% - 10px);
  position: relative;
  @include flex(center, center);
  @include mq(m) {
    height: 130px;
    width: 100%;
  }

  .service-card-img {
    @include size(150px, 100px);
    padding: 0px 10px;
    border-radius: 15px;
    top: 0;
    z-index: 1;
    object-fit: scale-down;
    box-sizing: border-box;
    background-color: $white;
    box-shadow: 0px 0px 10px 0px $boxShadow;
    @include mq(l) {
      @include size(90px, 60px);
      padding: 5px;
      border-radius: 10px;
    }
  }

  .service-card-content {
    background-color: $gs1;
    width: 100%;
    bottom: 0;
    border: 1px solid $gs7;
    border-radius: 15px;
    text-align: left;
    @include mq(l) {
      border-radius: 10px;
    }

    .top-part {
      @include flex(flex-start);
      padding: 30px;
      @include mq(l) {
        flex-wrap: nowrap;
        padding: 20px;
      }
    }

    .service-card-desc {
      margin-left: 20px;
    }

    .title {
      @include titleFz(xs);
      @include fw(bold);
      height: 29px;
      line-height: 29px;
      margin-top: 10px;
      @include mq(l) {
        @include fz(m);
        margin-top: 5px;
        height: 20px;
        line-height: 20px;
      }
    }

    .desc {
      @include fz(m);
      @include fw(normal);
      margin-top: 10px;
      line-height: 20px;
      @include mq(l) {
        @include fz(s);
        line-height: 17px;
      }
      @include mq(m) {
        margin-top: 5px;
      }
    }

    .detail-btn {
      margin-left: -1px;
      margin-bottom: -1px;
      @include flex(center, center);
      @include size(calc(100% + 2px), 40px);
      @include fz(m);
      @include fw(bold);
      cursor: pointer;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      line-height: 24px;
      background-color: $primary1;
      color: $white;
      :first-child {
        margin-right: 10px;
      }
      @include mq(l) {
        @include fz(xs);
        @include size(calc(100% + 2px), 30px);
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .disable {
      cursor: default;
      color: $gs5;
      background-color: $gs0;
    }
  }
}
</style>
