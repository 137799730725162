<template>
  <div class="section-download-docs">
    <div class="footer-title" v-html="getWordings.smartpostSectionDownloadTitle"></div>
    <LinkButton
      class="link-button"
      :wordingKey="'smartpostSectionDownloadButton'"
      :hrefLink="downloadDocsURL"
      :isShowIcon="false"
      :openNewTab="true"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import LinkButton from "@/components/controls/LinkButton.vue";
import { Getter } from "vuex-class";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionSmartPOSTLearnMore extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  downloadDocsURL = process.env.VUE_APP_XID_DOCS_DOWNLOAD_URL as string;
}
</script>

<style lang="scss" scoped>
.section-download-docs {
  @include size(100%, auto);
  @include titleFz(m);
  @include fw(heavy);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .footer-title {
    width: 100%;
    margin-bottom: 60px;
  }

  .button-wrapper {
    @include flex(center, center);
    @include mq(m) {
      width: 100%;
    }
  }

  @include mq(m) {
    @include fz(l);
    padding-top: 40px;
    padding-bottom: 40px;

    .footer-title {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
</style>
