<template>
  <div class="dropdown">
    <div class="dropdown-selected-item" @click="showSelection = !showSelection">
      <span class="text" :class="{ active: isActive }">
        {{ navName }}
      </span>
      <span class="icon">
        <img :src="downIcon" />
      </span>
    </div>
    <div class="dropdown-selection-area" v-show="showSelection">
      <div
        class="dropdown-item"
        v-for="(item, idx) in items"
        :key="idx"
        @click.stop="selectItem(item)"
      >
        <div class="title" v-if="item.title">{{ item.title }}</div>
        <div class="name" :class="{ active: $route.path == item.link }">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Model } from "vue-property-decorator";
import downIcon from "@/assets/icons/down-gray.svg";

@Component({
  name: "NavDropdown",
  inject: ["routes"]
})
/**
 * @vuese
 * @group Atomic/Organisms
 * dropdown
 */
export default class Dropdown extends Vue {
  // [Prop]
  @Prop({ type: Array, default: [], required: true }) readonly items!: string[];
  @Prop({ type: String, default: "", required: true }) readonly navName!: string[];
  @Prop({ type: Boolean, default: false, required: false }) readonly isActive!: boolean;
  @Model("selectedChanged", { type: String, default: "" })
  selectedItem!: string;

  // [Data]
  downIcon: string = downIcon;
  showSelection = false;

  // [Life cycle]
  mounted() {
    const outsideClickEvent = (e: Event) => {
      if (!e.target) return;
      if (!this.$el.contains(e.target as Node) && this.showSelection) {
        this.showSelection = false;
      }
    };

    document.addEventListener("click", outsideClickEvent);
  }

  // [Methods]
  selectItem(item: string) {
    this.showSelection = false;
    this.$emit("selectedChanged", item);
  }
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  cursor: pointer;

  .dropdown-selected-item {
    @include size(100%, 100%);
    background-color: $white;
    padding: 0px 0px 5px;
    box-sizing: border-box;

    @include flex(center, center);

    .text {
      flex: 1 1 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: inline-block;
      @include fw(bold);
      line-height: 20px;

      &.placeholder {
        color: rgba($color: $gs4, $alpha: 0.5);
      }
    }

    .active {
      color: $primary1;
      @include fw(bold);
    }

    .icon {
      margin-left: 5px;
    }
  }

  .dropdown-selection-area {
    width: 200px;
    padding: 20px 20px;
    top: calc(100% + 10px);
    left: calc(50% - 110px);
    position: absolute;
    overflow: hidden;
    background-color: $white;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: 9;
    box-shadow: 0px 10px 10px 0px $boxShadow;

    .dropdown-item {
      @include mq(m) {
        height: 40px;
      }
    }
  }

  .dropdown-item {
    // @include flex(row, center);
    text-align: left;
    // @include size(auto, 60px);
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .title {
      @include fz(xs);
      @include fw(bold);
      line-height: 20px;
      color: $gs3;
      margin-bottom: 5px;
    }

    .name {
      @include fz(l);
      @include fw(bold);
      line-height: 20px;
      color: $black;
    }

    .active {
      color: $primary1;
    }
  }
}
</style>
