<template>
  <div class="section-solution">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionSolutionSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionSolutionTitle }}</div>
      <div class="solution solution-1">
        <img
          class="solution-img solution-img-1"
          :src="require('@/assets/images/xID_feature.svg')"
        />
        <div class="solution-card solution-card-1">
          <div class="solution-card-title">{{ getWordings.homeSectionSolutionAppTitle }}</div>
          <p class="solution-content" v-html="getWordings.homeSectionSolutionAppDescription"></p>
          <LinkButton
            class="link-button"
            :wordingKey="'homeSectionSolutionButtons'"
            :hrefLink="routes.application.url"
            :isShowIcon="false"
          />
        </div>
      </div>
      <div class="solution solution-2">
        <img
          class="solution-img solution-img-2"
          :src="require('@/assets/images/xID_business.svg')"
        />
        <div class="solution-card solution-card-2">
          <div class="solution-card-title">{{ getWordings.homeSectionSolutionAPITitle }}</div>
          <p class="solution-content" v-html="getWordings.homeSectionSolutionAPIDescription"></p>
          <LinkButton
            class="link-button"
            :wordingKey="'homeSectionSolutionButtons'"
            :hrefLink="routes.dev.url"
            :isShowIcon="false"
          />
        </div>
      </div>
      <div class="solution solution-1">
        <img
          class="solution-img solution-img-3"
          :src="require('@/assets/images/smartpost_feature.svg')"
        />
        <div class="solution-card solution-card-1">
          <div class="solution-card-title">
            {{ getWordings.homeSectionSolutionSmartPostTitle }}
          </div>
          <p
            class="solution-content"
            v-html="getWordings.homeSectionSolutionSmartPostDescription"
          ></p>
          <LinkButton
            class="link-button"
            :wordingKey="'homeSectionSolutionButtons'"
            :hrefLink="routes.smartpost.url"
            :isShowIcon="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";

@Component({
  components: {
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionSolution extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-solution {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  background: $gs8;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;

  .solution {
    @include flex(space-between, center);
    position: relative;
    width: 100%;
    height: 400px;
    @include mq(l) {
      @include flex(center, center);
      height: auto;
    }
  }

  .solution-1 {
    margin-top: 60px;
    @include mq(l) {
      margin-top: 40px;
    }
  }

  .solution-2 {
    margin-top: 100px;
    @include mq(l) {
      margin-top: 40px;
    }
  }

  .solution-img-1 {
    padding: 25px 125px;
    left: 0;
    background-image: linear-gradient(#7ae3b3, #33ceaa);
    @include mq(l) {
      padding: 10px 10px;
      top: 0;
    }
  }

  .solution-img-2 {
    padding: 25px 125px;
    right: 0;
    background-image: linear-gradient(#8133ce, #4533ce);
    @include mq(l) {
      padding: 10px 10px;
      top: 0;
    }
  }

  .solution-img-3 {
    padding: 25px 125px;
    left: 0;
    background-image: linear-gradient($sp-color2, $sp-color1);
    @include mq(l) {
      padding: 10px 10px;
      top: 0;
    }
  }

  .solution-img {
    position: absolute;
    border-radius: $radius15;
    z-index: 1;
    @include mq(l) {
      position: relative;
      width: calc(100% - 60px);
      height: 145px;
      border-radius: 10px;
    }
  }

  .solution-card-1 {
    right: 0;
    padding: 50px 100px 59px 200px;
    @include mq(l) {
      bottom: 0;
    }
  }

  .solution-card-2 {
    left: 0;
    padding: 50px 200px 59px 100px;
    @include mq(l) {
      bottom: 0;
    }
  }

  .solution-card {
    position: absolute;
    width: 500px;
    height: 288.35px;
    border: 1px solid $gs7;
    border-radius: $radius15;
    background-color: white;
    @include mq(l) {
      @include flex(center, center);
      position: relative;
      height: auto;
      margin-top: -20px;
      padding: 40px 20px 20px;
      width: 100%;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }

  .solution-card-title {
    @include titleFz(xs);
    @include fw(medium);
    text-align: left;
    line-height: 30px;
    @include mq(l) {
      @include fz(m);
      text-align: center;
      line-height: 20px;
    }
  }

  .solution-content {
    text-align: left;
    @include fz(l);
    line-height: 30px;
    margin: 20px 0 20px;
    @include mq(l) {
      @include fz(s);
      text-align: center;
      line-height: 17px;
    }
    @include mq(m) {
      margin: 5px 0 20px;
    }
  }

  @include mq(m) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
</style>
