<template>
  <div class="section-news">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionNewsSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionNewsTitle }}</div>
    </div>
    <div class="list-items">
      <CardView
        class="cardview"
        v-for="(post, idx) in posts"
        :key="idx"
        :title="post.title"
        :image="post.image"
        :description="post.date"
        :tag="post.tags"
        :id="post.id"
        :index="idx"
      />
    </div>
    <div class="button-wrapper">
      <LinkButton
        class="link-button"
        :wordingKey="'homeSectionNewsButton'"
        :hrefLink="routes.news.url"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CardView from "@/components/parts/NewsCardView.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
import Tag from "@/components/controls/Tag.vue";

@Component({
  components: {
    CardView,
    LinkButton,
    ActionButton,
    Tag
  },
  inject: ["routes"]
})
export default class SectionNews extends Vue {
  // [Getter]
  @Getter("text/getWordings") getWordings!: any;
  // [data]
  assetsDomain = process.env.VUE_APP_ASSETS_URL as string;
  posts = [];
  tagNames: any = {
    news: "ニュース",
    "press-release": "プレスリリース",
    media: "メディア掲載",
    event: "イベント情報",
    information: "インフォメーション（その他の情報）"
  };

  // [Life cycle]
  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getListPosts(contentRepository);
  }

  // [Methods]
  // Get 3 lastest posts to display
  async getListPosts(contentRepository: any): Promise<void> {
    this.posts = (await contentRepository.getPostsWithPaging("1", "3", "")).map(
      (item: any): any => {
        const url = item.attributes.cover.data
          ? item.attributes.cover.data.attributes.url
          : "https://public.xid.inc/assets/news_default_705af5bb55.svg";
        return {
          id: item.id,
          image: url,
          title: item.attributes.title,
          date: item.attributes.date,
          tags: this.tagNames[item.attributes.tags]
        };
      }
    );
  }
}
</script>

<style lang="scss" scoped>
.section-news {
  @include size(100%, auto);
  @include layoutPadding();
  background: $white;
  padding-top: 100px;
  padding-bottom: 100px;

  .list-items {
    margin-top: 60px;
    margin-bottom: 60px;

    .cardview {
      flex: 1 1 0;
    }
  }

  .button-wrapper {
    @include flex(center, center);
  }

  .tags {
    @include flex(center, center);
    column-gap: 20px;
    width: 100%;
    padding: 20px 0;
    margin: 60px 0;
    border-bottom: 1px solid $gs7;
    border-top: 1px solid $gs7;
    @include mq(l) {
      @include flex(flex-start, center);
      flex-wrap: nowrap;
      margin: 40px 0 20px;
      column-gap: 5px;
      padding: 12px 0;
      overflow: hidden;
      overflow-x: scroll;
    }

    .tag {
      @include fz(m);
      cursor: pointer;
      height: 40px;
      border-radius: 100px;
      background-color: $white;
      border: 1px solid $gs7;
      @include mq(m) {
        @include fz(xs);
        height: 30px;
      }
    }

    .tagActive {
      color: $white;
      background-color: $black;
      border: 0px solid $gs7;
    }
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 50px;

    .list-items {
      @include flex(center, center);
      margin-top: 40px;
      margin-bottom: 0;

      .cardview {
        margin: 0 0 20px;
      }
    }

    .button-wrapper {
      margin-top: 0px;
    }
  }
}
</style>
