<template>
  <div class="section-news" id="news">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.newsSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.newsTitle }}</div>
    </div>
    <div class="tags" v-if="tagNames">
      <Tag
        class="tag"
        v-for="(_, idx) in tagNames"
        :class="{ tagActive: tagSelected == idx }"
        :key="idx"
        :tagName="tagNames[idx]"
        @onClick="clickNewsTag(idx)"
      />
    </div>
    <div class="list-items">
      <CardView
        class="cardview"
        v-for="(news, idx) in newsList"
        :key="idx"
        :title="news.name"
        :image="news.image"
        :description="news.date"
        :tag="news.tags"
        :id="news.id"
        :index="idx"
      />
    </div>
    <div class="button-wrapper">
      <ActionButton
        class="action-button"
        v-if="!isLastPost"
        :showIcon="false"
        :disabled="disableLoad"
        :wordingKey="'newsButton'"
        @onClick="loadMoreNews"
      />
      <ActionButton v-if="isLastPost" :disabled="true" :wordingKey="'newsButton'" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import CardView from "@/components/parts/NewsCardView.vue";
import LinkButton from "@/components/controls/LinkButton.vue";
import ActionButton from "@/components/controls/ActionButton.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";
import Tag from "@/components/controls/Tag.vue";

@Component({
  components: {
    CardView,
    LinkButton,
    ActionButton,
    Tag
  },
  inject: ["routes"]
})
export default class SectionListNews extends Vue {
  // [Getter]
  @Getter("text/getWordings") getWordings!: any;
  // [data]
  newsList: Array<any> = [];
  lastOffset = 0;
  limit = 10;
  isLastPost = false;
  disableLoad = false;
  tagSelected = "all";
  tagNames: any = {
    all: "すべて",
    news: "ニュース",
    "press-release": "プレスリリース",
    media: "メディア掲載",
    event: "イベント情報",
    information: "インフォメーション（その他の情報）"
  };

  // [Life cycle]
  created() {
    this.getMoreNews();
  }

  // [Methods]
  async getMoreNews(): Promise<void> {
    const contentRepository = RepositoryFactory.create("contents");
    const tag = this.tagSelected == "all" ? "" : this.tagSelected;
    const newsList = await contentRepository.getPostsWithOffset(this.lastOffset, this.limit, tag);
    if (newsList.length < this.limit && this.lastOffset != 0) {
      this.isLastPost = true;
    }

    this.newsList.push(
      ...newsList.map((item: any): any => {
        const url = item.attributes.cover.data
          ? item.attributes.cover.data.attributes.url
          : "https://public.xid.inc/assets/news_default_705af5bb55.svg";
        return {
          id: item.id,
          image: url,
          name: item.attributes.title,
          date: item.attributes.date,
          tags: this.tagNames[item.attributes.tags]
        };
      })
    );

    this.lastOffset += newsList.length;
    this.disableLoad = false;
  }
  async loadMoreNews(onDone: Function) {
    this.disableLoad = true;
    await this.getMoreNews();
    onDone();
  }

  async getNewsWithFilter(): Promise<void> {
    this.lastOffset = 0;
    const contentRepository = RepositoryFactory.create("contents");
    const tag = this.tagSelected == "all" ? "" : this.tagSelected;
    const newsList = await contentRepository.getPostsWithOffset(this.lastOffset, this.limit, tag);
    this.newsList = newsList.map((item: any): any => {
      const url = item.attributes.cover.data
        ? item.attributes.cover.data.attributes.url
        : "https://public.xid.inc/assets/news_default_705af5bb55.svg";
      return {
        id: item.id,
        image: url,
        name: item.attributes.title,
        date: item.attributes.date,
        tags: this.tagNames[item.attributes.tags]
      };
    });
    this.lastOffset += newsList.length;
    this.disableLoad = false;
  }

  async clickNewsTag(tag: string) {
    this.tagSelected = tag;
    this.disableLoad = true;
    await this.getNewsWithFilter();
  }
}
</script>

<style lang="scss" scoped>
.section-news {
  @include size(100%, auto);
  @include layoutPadding();
  background: $white;
  padding-top: 60px;
  padding-bottom: 100px;

  .list-items {
    margin-top: 50px;

    .cardview {
      flex: 1 1 0;
    }
  }

  .button-wrapper {
    .action-button {
      @include flex(center, center);
      margin: auto;
      width: 100%;
    }
  }

  .tags {
    @include flex(center, center);
    column-gap: 20px;
    width: 100%;
    padding: 20px 0;
    margin: 60px 0;
    border-bottom: 1px solid $gs7;
    border-top: 1px solid $gs7;
    white-space: nowrap;
    @include mq(l) {
      @include flex(flex-start, center);
      flex-wrap: nowrap;
      margin: 40px 0 20px;
      column-gap: 5px;
      padding: 12px 0;
      overflow: hidden;
      overflow-x: scroll;
    }

    @include mq(m) {
      padding-left: 30px;
      padding-right: 30px;
      margin-left: -30px;
      width: calc(100vw - 60px);
    }

    .tag {
      @include fz(m);
      cursor: pointer;
      height: 40px;
      border-radius: 100px;
      background-color: $white;
      border: 1px solid $gs7;
      @include mq(m) {
        @include fz(xs);
        height: 30px;
      }
    }

    .tagActive {
      color: $white;
      background-color: $primary1;
      border: 0px solid $gs7;
    }
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 50px;

    .list-items {
      @include flex(center, center);
      margin-top: 40px;

      .cardview {
        margin: 0 0 20px;
      }
    }

    .button-wrapper {
      margin-top: 0px;
    }
  }
}
</style>
