<template>
  <div class="tag" @click="onClick">{{ tagName }}</div>
</template>

<script lang="ts">
import { Prop, Vue, Component } from "vue-property-decorator";

@Component
export default class Tag extends Vue {
  /// [Prop]
  /// Button's text
  @Prop({ type: String, required: true }) private tagName!: string;

  /// [Data]
  onClick() {
    this.$emit("onClick", this.tagName);
  }
  /// [Local Methods]
}
</script>

<style lang="scss" scoped>
.tag {
  @include size(fit-content, 28px);
  @include fz(xs);
  @include fw(medium);
  @extend %centerContent;
  min-width: 108px;
  border-radius: 13px;
  background: $white;
  border: 1px solid $gs2;
  padding: 0 20px;
  box-sizing: content-box;
  word-wrap: none;
  word-break: keep-all;

  @include mq(m) {
    @include size(auto, 20px);
    @include fz(xs);
    min-width: unset;
  }
}
</style>
