<template>
  <div class="section-introdution-v2">
    <!-- Introdution part -->
    <div class="introdution">
      <div class="introdution-title">
        <div>
          {{ getWordings.homeSectionIntroTitle }}
        </div>
      </div>
      <div class="spin-container">
        <div class="spin-text-list">
          <div
            v-for="(item, idx) in collaborationFields"
            class="spin-text"
            :key="idx"
            :class="'w' + (idx + 1)"
          >
            {{ item.name }}
          </div>
        </div>
        <img
          class="co-creation-img"
          :src="require('@/assets/logos/xid-logo-intro.svg')"
          alt="xid-logo"
        />
        <img
          class="co-creation-img-mob"
          :src="require('@/assets/logos/xid-logo-mob.svg')"
          alt="xid-logo"
        />
      </div>
    </div>
    <div class="introndution-card-container">
      <IntroCardView
        class="card"
        :hrefLink="routes.application.url"
        :title="getWordings.homeSectionIntroAppTitle"
        :desc="getWordings.homeSectionIntroAppDescription"
        :img="require('@/assets/images/intro_app_img.svg')"
        :app="true"
      />
      <IntroCardView
        class="card"
        :hrefLink="routes.dev.url"
        :title="getWordings.homeSectionIntro4DevTitle"
        :desc="getWordings.homeSectionIntro4DevDescription"
        :img="require('@/assets/images/intro_dev_img.svg')"
        :dev="true"
      />
      <IntroCardView
        class="card"
        :hrefLink="routes.smartpost.url"
        :title="getWordings.homeSectionIntro4SmartPostTitle"
        :desc="getWordings.homeSectionIntro4SmartPostDescription"
        :img="require('@/assets/images/intro_smartpost_img.svg')"
        :gov="true"
      />
    </div>
    <div class="home-triangle">
      <img class="desktop" :src="require('@/assets/images/triangle-home.svg')" />
      <img class="mobile" :src="require('@/assets/images/triangle-home-mob.svg')" />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import LinkButton from "@/components/controls/LinkButton.vue";
import IntroCardView from "@/components/parts/IntroCardView.vue";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    LinkButton,
    IntroCardView
  },
  inject: ["routes"]
})
export default class SectionIntro extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  collaborationFields = [];
  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getCollaborationFields(contentRepository);
  }

  async getCollaborationFields(contentRepository: any) {
    this.collaborationFields = (await contentRepository.getCollaborationFieldsList()).map(
      (item: any): any => {
        return {
          name: item.attributes.name
        };
      }
    );
  }
}
</script>

<style lang="scss" scoped>
.section-introdution-v2 {
  @include size(100%, auto);
  @include layoutPadding();
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-bottom: 35px;
    @include flex(center, center);
  }
  position: relative;
  background: $gs8;
  .home-triangle {
    img {
      width: 100%;
      @include mq(m) {
        width: auto;
        margin: auto;
      }
    }

    display: block;
    position: absolute;
    bottom: 0;
    @include mq(l) {
      width: 150vw;
    }
  }
  .introdution {
    @extend %centerContent;
    @extend %layoutVertical;
  }

  .introdution-title {
    @include titleFz(m);
    @include fw(heavy);
    @extend %centerContent;
    flex-direction: column;
  }

  .spin-container {
    @include flex(center, center);
    margin: 0 0 100px;
    @include mq(m) {
      margin: 20px 0 20px;
    }

    .spin-text-list {
      @include size(250px, 90px);
      border: 6px solid rgb(0, 0, 0, 20%);
      background: $primary1;
      animation: changeBg 10s cubic-bezier(0, 1.5, 1, 1.5) infinite;
      animation-delay: 2.5s;
      border-radius: $radius15;
      position: relative;
      @include mq(m) {
        margin-right: 20px;
        @include size(125px, 45px);
      }
    }

    .spin-text {
      @include titleFz(xl);
      @include fw(heavy);
      color: $white;
      width: 100%;
      position: absolute;
      top: 23.5px;
      @include mq(m) {
        top: 14.5px;
        @include titleFz(s);
      }
    }

    .w1 {
      animation: w1anim 10s linear infinite;
    }

    .w2 {
      animation: w2anim 10s linear infinite;
    }

    .w3 {
      animation: w3anim 10s linear infinite;
    }

    .w4 {
      animation: w4anim 10s linear infinite;
    }

    @keyframes changeBg {
      0% {
        background-color: $spin-color1;
      }
      25% {
        background-color: $spin-color2;
      }
      50% {
        background-color: $spin-color3;
      }
      75% {
        background-color: $spin-color4;
      }
      100% {
        background-color: $spin-color1;
      }
    }

    @keyframes w1anim {
      0% {
        opacity: 0;
      }
      2% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      23% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      25% {
        opacity: 0;
        transform: translateY(-1em);
      }
      100% {
        opacity: 0;
        transform: translateY(-1em);
      }
    }

    @keyframes w2anim {
      0% {
        opacity: 0;
        transform: translateY(0em);
      }
      20% {
        opacity: 0;
        transform: translateY(0em);
      }
      25% {
        opacity: 0;
        transform: translateY(0em);
      }
      27% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      48% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      50% {
        opacity: 0;
        transform: translateY(-1em);
      }
      100% {
        opacity: 0;
        transform: translateY(-1em);
      }
    }

    @keyframes w3anim {
      0% {
        opacity: 0;
        transform: translateY(0em);
      }
      45% {
        opacity: 0;
        transform: translateY(0em);
      }
      50% {
        opacity: 0;
        transform: translateY(0em);
      }
      52% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      73% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      75% {
        opacity: 0;
        transform: translateY(-1em);
      }
      100% {
        opacity: 0;
        transform: translateY(-1em);
      }
    }

    @keyframes w4anim {
      0% {
        opacity: 0;
        transform: translateY(0em);
      }
      70% {
        opacity: 0;
        transform: translateY(0em);
      }
      75% {
        opacity: 0;
        transform: translateY(0em);
      }
      77% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      98% {
        opacity: 1;
        transform: translateY(-0.5em);
      }
      100% {
        opacity: 0;
        transform: translateY(-1em);
      }
    }

    @keyframes w5anim {
      80% {
        transform: translate3d(0, 0, 0);
        opacity: 0;
      }
      90% {
        transform: translate3d(0, -25%, 0);
        opacity: 1;
      }
      100% {
        transform: translate3d(0, -50%, 0);
        opacity: 0;
      }
    }
  }
  .co-creation-img-mob {
    display: none;
    @include mq(m) {
      display: block;
      max-height: 40px;
    }
  }
  .co-creation-img {
    @include mq(m) {
      display: none;
    }
  }

  .introndution-card-container {
    @include flex(space-between, center);
    @include mq(xl) {
      @include flex(center, center);
      column-gap: 50px;
      row-gap: 50px;
    }
    @include mq(l) {
      @include flex(space-around, center);
      row-gap: 30px;
    }
    @include mq(m) {
      @include flex(center, center);
      row-gap: 10px;
    }

    // .card:hover {
    //   box-shadow: 0px 0px 10px 0px $boxShadow;
    // }
  }

  .triangle-up {
    margin: auto;
    width: 50%;
    height: 0;
    border-left: 100px solid transparent;
    border-right: 100px solid transparent;
    border-bottom: 100px solid #f0f0f0;
    @include mq(l) {
      display: none;
    }
  }

  .intro-triangle-mobile {
    display: none;
    height: 0;
    border-left: 55vw solid transparent;
    border-right: 55vw solid transparent;
    border-bottom: 200px solid #eaeaea;
    @include mq(l) {
      display: block;
      margin-top: -150px;
    }
  }

  @include mq(m) {
    padding-top: 40px;

    .introdution-title {
      @include fz(xl);
      width: 100%;
      line-height: 2em;
    }
  }
}
</style>
