import { ActionTree } from "vuex";
import { TextState } from "./types";
import { RootState } from "../types";

const actions: ActionTree<TextState, RootState> = {
  setWordingsData({ commit }, wordingsData) {
    commit("setWordingsData", wordingsData);
  }
};

export default actions;
