<template>
  <div class="section-why-partnership">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.partnerSectionWhySubtitle }}
      </div>
      <div class="section-header-text" v-html="getWordings.partnerSectionWhyTitle"></div>
    </div>

    <div class="slogan">
      <div class="text" v-html="getWordings.partnerSectionWhyMessage"></div>
    </div>

    <div class="partnership-description">
      {{ getWordings.partnerSectionWhyDescription }}
    </div>

    <div class="partner-card-container">
      <div class="partner-card">
        <div class="title">{{ getWordings.partnerSectionWhyT1Title }}</div>
        <div class="desc">
          {{ getWordings.partnerSectionWhyT1Description }}
        </div>
      </div>
      <div class="partner-card">
        <div class="title">{{ getWordings.partnerSectionWhyT2Title }}</div>
        <div class="desc">
          {{ getWordings.partnerSectionWhyT2Description }}
        </div>
      </div>
      <div class="partner-card">
        <div class="title">{{ getWordings.partnerSectionWhyT3Title }}</div>
        <div class="desc">
          {{ getWordings.partnerSectionWhyT3Description }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { Getter } from "vuex-class";

@Component({
  components: {}
})
export default class SectionWhyPartnership extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-why-partnership {
  @include layoutPadding;
  @extend %centerContent;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 87px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .partnership-description {
    max-width: 850px;
    margin-bottom: 100px;
    @include mq(l) {
      margin-bottom: 40px;
    }
  }

  .partner-card-container {
    width: 100%;
    @include flex(space-between, center);
    margin-bottom: 60px;
    @include mq(l) {
      @include flex(center, center);
      margin-bottom: unset;
    }

    .partner-card {
      height: 400px;
      border: 1px solid $gs7;
      border-radius: $radius15;
      box-sizing: border-box;
      padding: 60px 30px 0px;
      background: $gs1;
      @include mq(l) {
        height: 257px;
        padding: 40px 20px;
        margin-bottom: 20px;
      }

      .title {
        @include titleFz(xs);
        @include fw(bold);
        line-height: unset;
        @include mq(l) {
          @include fz(m);
        }
      }
      .desc {
        @include fz(m);
        max-width: 327px;
        margin-top: 30px;
        @include mq(l) {
          margin-top: 5px;
          @include fz(s);
        }
      }
    }
  }
}
</style>
