var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header page-header"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logo}})]),_c('div',{staticClass:"header-menu",class:{ open: _vm.mobMenuIsOpen }},_vm._l((_vm.menus),function(menu){return _c('div',{key:menu.i18nName},[(menu.i18nName != 'header_xid_solution')?_c('div',{on:{"click":_vm.onClickMobMenuItem}},[(menu.i18nName != 'header_recruit' && menu.i18nName != 'header_blog')?_c('router-link',{attrs:{"to":{ name: menu.name, params: { timestamp: Date.now().toString() } }}},[_vm._v(" "+_vm._s(_vm.$t(menu.i18nName))+" ")]):_c('a',{attrs:{"href":menu.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t(menu.i18nName)))])],1):_c('div',[_c('NavDropdown',{staticClass:"nav-dropdown",attrs:{"items":_vm.dropdownSolutionItems,"navName":_vm.$t(menu.i18nName),"isActive":_vm.$route.name == 'Application' ||
              _vm.$route.name == 'SmartPOST' ||
              _vm.$route.name == 'Development'},on:{"selectedChanged":_vm.onSelect}}),_c('div',{staticClass:"nav-dropdown-mob"},[_c('div',{staticClass:"dropdown-title",on:{"click":function($event){_vm.mobAppDropdownIsOpen = !_vm.mobAppDropdownIsOpen}}},[_c('span',{staticClass:"text",class:{
                active:
                  _vm.$route.name == 'Application' ||
                  _vm.$route.name == 'SmartPOST' ||
                  _vm.$route.name == 'Development'
              }},[_vm._v(" "+_vm._s(_vm.$t(menu.i18nName))+" ")]),_c('span',{staticClass:"icon"},[_c('img',{class:{ 'expand-icon': _vm.mobAppDropdownIsOpen },attrs:{"src":require('@/assets/icons/icon-pointer.svg')}})])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.mobAppDropdownIsOpen),expression:"mobAppDropdownIsOpen"}],staticClass:"dropdown-selection-area"},_vm._l((_vm.dropdownSolutionItems),function(item,idx){return _c('div',{key:idx,staticClass:"dropdown-item",on:{"click":function($event){return _vm.onSelect(item)}}},[(item.title)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]):_vm._e(),_c('div',{staticClass:"name",class:{ active: _vm.$route.path == item.link }},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)])],1)])}),0),_c('div',{staticClass:"hamburger mob",class:{ open: _vm.mobMenuIsOpen },on:{"click":function($event){_vm.mobMenuIsOpen = !_vm.mobMenuIsOpen}}},[_c('span'),_c('span')])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }