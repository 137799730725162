<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <PageHeader />
    <div class="view-content">
      <keep-alive exclude="Contact">
        <router-view />
      </keep-alive>
      <PageFooter />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Routes from "@/router/routes";
import RepositoryFactory from "@/lib/http/RepositoryFactory";

import PageHeader from "@/components/parts/PageHeader.vue";
import PageFooter from "@/components/parts/PageFooter.vue";

@Component({
  name: "App",
  components: {
    PageHeader,
    PageFooter
  },
  provide: {
    routes: Routes
  }
})
export default class App extends Vue {
  wordings: any = {};
  @Action("text/setWordingsData") setWordingsData!: Function;

  mounted() {
    this.getWordingsList();
  }

  // created() {
  //   this.getWordingsList();
  // }

  getWordingsList(): Promise<void> {
    const contentRepository = RepositoryFactory.create("contents");
    const searchRegExp = /\n/g;
    return contentRepository.getWordingsList().then((data: any) => {
      data.map((item: any) => {
        this.wordings[item.attributes.key] = item.attributes.text.replace(searchRegExp, "</br>");
      });
      this.setWordingsData(this.wordings);
    });
  }
}
</script>

<style lang="scss">
@import "@/scss/parts/_button.scss";
@import "@/scss/parts/_header.scss";
@import "@/scss/parts/_footer.scss";
@import "@/scss/parts/_text.scss";
@import "@/scss/parts/_list.scss";

.view-content {
  overflow: hidden;
  margin-top: 80px;

  @include mq(l) {
    margin-top: 60px;
  }

  // @include mq(m) {
  //   margin-top: 50px;
  // }
}
</style>
