<template>
  <div class="section-vision">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.aboutSectionVisionSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.aboutSectionVisionTitle }}</div>
    </div>

    <div class="content-wrapper">
      <img :src="require('@/assets/images/team-image.svg')" alt="WHO WE ARE" />
      <div class="group-text">
        <p class="vision_content" v-html="getWordings.aboutSectionVisionDescription"></p>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class SectionVision extends Vue {
  @Getter("text/getWordings") getWordings!: any;
}
</script>

<style lang="scss" scoped>
.section-vision {
  @include size(100%, auto);
  @include layoutPadding();
  @extend %centerContent;
  flex-direction: column;
  background: $white;
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(l) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  // .section-header {
  //   margin-top: 100px;
  // }

  .content-wrapper {
    @include flex(space-between, center);
    height: 400px;
    width: 100%;
    margin-top: 60px;
    position: relative;
    @include mq(l) {
      @include flex(center, center);
      margin-top: 40px;
      height: 510px;
    }

    @include mq(m) {
      @include flex(center, flex-start);
      height: auto;
    }

    > img {
      position: absolute;
      z-index: 1;
      left: 0;
      width: 400px;
      max-height: 250px;
      border: 1px solid $gs2;
      border-radius: 18px;
      object-fit: cover;
      box-sizing: border-box;
      @include mq(l) {
        border-radius: 10px;
        max-width: 275px;
        top: 0;
        left: unset;
      }

      @include mq(m) {
        max-width: calc(100% - 40px);
        position: relative;
        margin-bottom: -30px;
      }
    }

    .vision_content {
      height: 280px;
      max-width: 600px;
      @include mq(m) {
        height: auto;
      }
    }
  }

  .group-text {
    padding: 60px 100px 60px 200px;
    border: 1px solid $gs7;
    border-radius: $radius15;
    background-color: $gs1;
    position: absolute;
    right: 0;
    text-align: left;
    @include mq(l) {
      max-height: 317px;
      bottom: 0;
      right: unset;
      padding: 40px 20px 40px;
    }
    @include mq(m) {
      @include fz(s);
      position: unset;
      box-sizing: border-box;
      max-height: unset;
      min-height: 317px;
      border-radius: 10px;
    }
  }
}
</style>
