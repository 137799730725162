<template>
  <div class="section-gov-collab">
    <div class="section-header">
      <div class="section-header-highlight title-letter-spacing">
        {{ getWordings.homeSectionGovCollaborationSubtitle }}
      </div>
      <div class="section-header-text">{{ getWordings.homeSectionGovCollaborationTitle }}</div>
    </div>
    <div class="items-wrapper">
      <PartnerView
        class="part-item"
        v-for="(item, idx) in governments"
        :key="idx"
        :image="item.image"
        :name="item.prefecture + item.city"
      />
    </div>
    <LinkButton
      class="link-button"
      :wordingKey="'homeSectionGovCollaborationButton'"
      :hrefLink="routes.gov.url"
      :isShowIcon="false"
    />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Getter } from "vuex-class";
import PartnerView from "@/components/parts/PartnerView.vue";
import LinkButton from "@/components/controls/LinkButton.vue";

import RepositoryFactory from "@/lib/http/RepositoryFactory";

@Component({
  components: {
    PartnerView,
    LinkButton
  },
  inject: ["routes"]
})
export default class SectionGovCallaboration extends Vue {
  @Getter("text/getWordings") getWordings!: any;
  governments = [];

  created() {
    const contentRepository = RepositoryFactory.create("contents");
    this.getGovernments(contentRepository);
  }

  async getGovernments(contentRepository: any) {
    this.governments = (await contentRepository.getGovernmentsList()).map((item: any): any => {
      const cover = item.attributes.cover.data.attributes;
      return {
        prefecture: item.attributes.prefecture,
        city: item.attributes.city,
        image: cover.url
      };
    });
  }
}
</script>

<style lang="scss" scoped>
.section-gov-collab {
  @include size(100%, auto);
  @include layoutPadding();
  background: $gs8;
  padding-top: 100px;
  padding-bottom: 100px;

  .items-wrapper {
    @include flex(center, flex-start);
    column-gap: 19.9px;
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .part-item {
    width: 154.3px;
    height: 100px;
    margin-bottom: 90.5px;
  }

  ::v-deep .part-item {
    .name {
      @include fz(m);
      line-height: 20px;
    }
    .wrapper {
      min-height: 100px;
    }
  }

  .link-button {
    margin: auto;
  }

  @include mq(l) {
    .part-item {
      width: calc(25% - 15px);
    }
  }

  @include mq(m) {
    padding-top: 40px;
    padding-bottom: 40px;

    .group-title {
      @include fz(m);
      @include fw(medium);
      color: $gs3;

      &.group-1 {
        margin-top: 50px;
      }
      &.group-2 {
        margin-top: 20px;
      }
    }

    .items-wrapper {
      @include flex(space-between, center);
      column-gap: 10px;
      margin-top: 40px;
      margin-bottom: 0;

      &:empty {
        margin-bottom: 30px;
      }
    }

    .part-item {
      width: calc(33.33% - 10px);
      height: 70px;
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 55px;
    }

    ::v-deep .part-item {
      .name {
        @include fz(xs);
        line-height: 20px;
      }
      .wrapper {
        min-height: 70px;
      }
    }
  }
}
</style>
